/* eslint-disable complexity */
/* eslint-disable max-statements */
import React, { useEffect } from "react";
import ArrowIcon from "../../shared/arrow";
import styles from "./styles.css";
import Pending from "./images/pending-icon.svg";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
// import sampleJson from "./sample.json";
import { fastlyUrl, imageTypes } from "../../../utils/helpers/cdn-image-url";
import { getMyBookingCarDetailsURL } from "../../../utils/helpers/get-detail-page-url";
import { tasksRoutes } from "../../../constants/au.mobile/tasks-routes";
import { ENABLE_NEW_POST_BOOK_FLOW, TASK_SOURCES, TASK_SOURCES_ALERT } from "../../../constants/app-constants";
import { validateSplitPay } from "../../../utils/helpers/split-payment";
import { NUMBER } from "../../../constants/app-constants";
import { trackMobileCustomEventsAU } from "../../../tracking";
import { AU_MOBILE_EVENTS } from "../../../tracking/au.mobile-events";
import ClockTimerCircle from "../../shared/clock-timer-circle";
import ZeroDpTimerText from "../zero-dp-timer-text";
import { postPaymentRoutes } from "../../../constants/au.mobile/post-payment-routes";
import { EXPERIMENT_TYPE } from "../../../constants/optimize-constants";
import { getTimeLeftInMins } from "../../../hooks/use-countdown";
import ZeroDpMyBookingAvailableCard from "../zero-dp-my-booking-available-card";
import { ORDER_STATUS } from "../../../constants/checkout-constants";

const BookedTasks = ({
    secureToken,
    bookedTasks = [],
    fetchBookedTasksListConnect,
    getFinancePostPaymentApplicationConnect,
    getVerificationStatusConnect,
    loanApplication,
    isGetLoanApplicationLoading,
    isGetLoanApplicationError,
    applicationFormConfigData,
    documentUploadConfigData,
    setSplitPaymentConnect,
    getOrderDetailConnect,
    getMyBookingsConnect,
    isFetching,
    bookings,
    order
}) => {
    const { FINISH_LOAN_APPLICATION, UPLOAD_DOCUMENTS, DL_VERIFY, SALE, SPLIT_PAYMENT } = TASK_SOURCES_ALERT || {};
    const { IN_HOUSE_FINANCE, DOC_SIGN } = TASK_SOURCES || {};
    const { cars: myBookingsOrder } = bookings || {};
    const myBookingsLastOrder = Array.isArray(myBookingsOrder) && myBookingsOrder[0];
    const {
        booked: myBookingVehicleBooked,
        reserved: myBookingVehicleReserved,
        zeroDp: myBookingZeroDp,
        orderId: myBookingOrderId,
        bookingState
    } = myBookingsLastOrder || {};
    const carAvailable = !myBookingVehicleBooked && !myBookingVehicleReserved && myBookingZeroDp && bookingState === ORDER_STATUS.CANCELED;
    const {
        status: orderStatus,
        splitPayments,
        splitPaymentOpted,
        chargeDetails,
        discountValue,
        totalAmount,
        appointmentId: orderAppointmentId
    } = order || {};
    const history = useHistory();

    const fetchDetails = async () => {
        const response = await fetchBookedTasksListConnect(secureToken);
        const { data } = response || {};
        if (Array.isArray(data) && data.length) {
            const financeTask = (data || []).filter((value) => value.source === IN_HOUSE_FINANCE);
            const isFinanceTask = (financeTask || []).length > 0;
            const { vehicleDetail, orderId: orderIdParam } = data[0];
            if (isFinanceTask) {
                getFinancePostPaymentApplicationConnect(financeTask[0].orderId);
            } else {
                const { appointmentId } = vehicleDetail || {};
                getOrderDetailConnect(appointmentId);
            }
            getVerificationStatusConnect(orderIdParam);
        }
    };

    useEffect(() => {
        if (secureToken) {
            fetchDetails();
            getMyBookingsConnect(secureToken, { page: 0, size: 1 });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [secureToken]);

    const getFinanceProgressBar = (data, progressBarData) => {
        const { applicationStatus, documentUploadStatus } = loanApplication;
        if (data.sourceAlertType === FINISH_LOAN_APPLICATION) {
            if (applicationStatus) {
                const { completedPercentage } = applicationFormConfigData;
                progressBarData.showProgressBar = true;
                progressBarData.progressCompletedPercentage = isNaN(completedPercentage) ?  0 : completedPercentage;
                progressBarData.numberLeft = `${isNaN(completedPercentage) ?  0 : Math.round(completedPercentage)}% completed`;
            }
        } else if (data.sourceAlertType === UPLOAD_DOCUMENTS) {
            if (documentUploadStatus) {
                const { completedPercentage } = documentUploadConfigData;
                progressBarData.showProgressBar = true;
                progressBarData.progressCompletedPercentage = isNaN(completedPercentage) ?  0 : completedPercentage;
                progressBarData.numberLeft = `${isNaN(completedPercentage) ?  0 : Math.round(completedPercentage)}% completed`;
            }
        }
        return progressBarData;
    };

    const getSplitPaymentProgress = (data, progressBarData) => {
        const splitPaymentData = validateSplitPay({ splitPayments, dapOrTradePrice: totalAmount, splitPaymentOpted, orderStatus, discountValue, chargeDetails });
        setSplitPaymentConnect(splitPaymentData);
        const { finalRemainingAmount } = splitPaymentData || {};
        const completedPercentage = (((totalAmount - finalRemainingAmount) / totalAmount) * NUMBER.HUNDRED).toFixed(NUMBER.TWO);
        progressBarData.showProgressBar = true;
        progressBarData.progressCompletedPercentage = isNaN(completedPercentage) ?  0 : completedPercentage;
        progressBarData.numberLeft = `${isNaN(completedPercentage) ?  0 : Math.round(completedPercentage)}% completed`;
        return progressBarData;
    };

    const getProgressBarData = (data) => {
        let progressBarData = {};
        if (data.source === IN_HOUSE_FINANCE && !isGetLoanApplicationLoading && !isGetLoanApplicationError) {
            progressBarData = getFinanceProgressBar(data, progressBarData);
        } else if (data.sourceAlertType === SPLIT_PAYMENT) {
            progressBarData = getSplitPaymentProgress(data, progressBarData);
        }
        return progressBarData;
    };

    const handleVerifyIdClick = () => {
        const {relativeURL} = getMyBookingCarDetailsURL(orderAppointmentId, postPaymentRoutes.driverLicence.route,
            null,
            EXPERIMENT_TYPE.VARIANT_B);
        history.push(relativeURL);
    };

    const pageRoute = (vehicleDetail, sourceAlertType) => {
        let route = {};
        let eventAction = "";
        switch (sourceAlertType) {
        case FINISH_LOAN_APPLICATION: {
            eventAction = "Loan_application_form";
            route = getMyBookingCarDetailsURL(vehicleDetail.appointmentId, tasksRoutes.loanApplication.route);
            break;
        }
        case UPLOAD_DOCUMENTS: {
            eventAction = "Documents_upload";
            route = getMyBookingCarDetailsURL(vehicleDetail.appointmentId, tasksRoutes.uploadDocuments.route);
            break;
        }
        case DL_VERIFY: {
            eventAction = "DL_verify";
            const url = handleVerifyIdClick();
            route = {
                relativeURL: url
            };
            break;
        }
        case SALE: {
            eventAction = "Contract";
            if (ENABLE_NEW_POST_BOOK_FLOW[sourceAlertType]) {
                route = getMyBookingCarDetailsURL(vehicleDetail.appointmentId, postPaymentRoutes.addOns.route, null, EXPERIMENT_TYPE.VARIANT_B);
            } else {
                route = getMyBookingCarDetailsURL(vehicleDetail.appointmentId, tasksRoutes.addOns.route);
            }
            break;
        }
        case SPLIT_PAYMENT: {
            eventAction = "split_payment";
            route = getMyBookingCarDetailsURL(vehicleDetail.appointmentId, tasksRoutes.orderPaymentOverview.route);
            break;
        }
        default: {
            route =  getMyBookingCarDetailsURL(vehicleDetail.appointmentId, tasksRoutes.addOns.route);
        }
        }
        trackMobileCustomEventsAU(AU_MOBILE_EVENTS.HOME_PAGE_NUDGES, {
            eventAction,
            eventLabel: "{{Nudge_click}}"
        });
        const { relativeURL } = route;
        history.push(relativeURL);
    };

    const renderTask = (value, key) => {
        const {showProgressBar, progressCompletedPercentage, numberLeft } = getProgressBarData(value) || {};
        return (
            <div styleName={"styles.tabWrapper"} key={key} onClick={() => pageRoute(value.vehicleDetail, value.sourceAlertType)}>
                <div>
                    <p styleName={"styles.tabText"}>{key + 1}. {value.header}</p>
                    {showProgressBar && <div styleName={"styles.progressWrap"}>
                        <div styleName={"styles.progressTrack"}>
                            <div styleName={"styles.progressRail"} style={{width: `${progressCompletedPercentage}%`}} />
                        </div>
                        <label>{numberLeft}</label>
                    </div>}
                </div>
                <ArrowIcon blue={"BlueArrow"} />
            </div>
        );
    };

    const renderFullCard = () => {
        const { vehicleDetail = {}, timeoutDetails, zeroDp, vehicleBooked, vehicleReserved, orderId: taskOrderId  } = (bookedTasks || [])[0];
        const { year, make, model, config, mainImage} = vehicleDetail || {};
        const hasMainImage = (mainImage || {}).path;
        const carImageUrl = hasMainImage
            ? fastlyUrl(config.imageHost, mainImage.path, imageTypes.auNudgesSmall)
            : null;
        const financeTask = (bookedTasks || []).filter((value) => value.source === IN_HOUSE_FINANCE) || [];
        const showZeroDpVariantScreen = zeroDp && financeTask.length > 0;
        const isDocSignAvailable = (bookedTasks || []).filter((value) => value.source === DOC_SIGN) || [];
        const pendingTasksLength = (showZeroDpVariantScreen && (isDocSignAvailable || []).length) ? (bookedTasks || []).length - 1 : (bookedTasks || []).length;
        const showFinanceExpiredScreen = (zeroDp && getTimeLeftInMins((timeoutDetails || {}).expiryTime) <= 0 && !vehicleBooked && !vehicleReserved);
        if (zeroDp && getTimeLeftInMins((timeoutDetails || {}).expiryTime) <= 0 && vehicleReserved) {
            return null;
        } else if (showFinanceExpiredScreen) {
            return (
                <div>
                    <div styleName={`styles.sliderWrapper`}>
                        <div styleName={"styles.bookTaskContainers"}>
                            <div styleName={"styles.outer"}>
                                <ZeroDpMyBookingAvailableCard vehicleDetail={vehicleDetail} orderId={taskOrderId} />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <div>
                <div styleName={`styles.sliderWrapper styles.margin`}>
                    <div styleName={"styles.outer"}>
                        <div className="media" styleName={"styles.topSection"}>
                            <div className="media-body">
                                {showZeroDpVariantScreen ? <ZeroDpTimerText timeoutDetails={timeoutDetails} origin={"homepage"} /> : <p styleName={"styles.heading"}>We are getting your car ready</p>}
                                <p styleName={"styles.carName"}>{year} {make} {model}</p>
                            </div>
                            {showZeroDpVariantScreen ?
                                <ClockTimerCircle
                                    {...timeoutDetails}
                                    timerSize = {71}
                                    timeFontSize = {14}
                                    textFontSize = {10}
                                />
                                :
                                <div styleName={"styles.carImageWrap"}>
                                    <img src={carImageUrl} />
                                </div>
                            }
                        </div>
                        <div styleName={"styles.pendingWrapper"}>
                            <img src={Pending} />
                            <p styleName={"styles.pendingText"}>{pendingTasksLength} Pending tasks</p>
                        </div>
                        {bookedTasks.map((task, taskKey) =>
                            showZeroDpVariantScreen && task.source === DOC_SIGN ? null : renderTask(task, taskKey)
                        )}
                    </div>
                </div>
            </div>
        );
    };

    if (isFetching) {
        return null;
    } else if (carAvailable) {
        return (
            <div>
                <div styleName={`styles.sliderWrapper`}>
                    <div styleName={"styles.bookTaskContainers"}>
                        <div styleName={"styles.outer"}>
                            <ZeroDpMyBookingAvailableCard vehicleDetail={myBookingsLastOrder} orderId={myBookingOrderId} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    return (
        <React.Fragment>
            {!!(bookedTasks || []).length && <React.Fragment>
                {renderFullCard()}
            </React.Fragment>}
        </React.Fragment>
    );
};

BookedTasks.propTypes = {
    secureToken: PropTypes.string,
    fetchBookedTasksListConnect: PropTypes.func,
    getVerificationStatusConnect: PropTypes.func,
    getMyBookingsConnect: PropTypes.func,
    isFetching: PropTypes.bool,
    saleLive: PropTypes.bool,
    getFinancePostPaymentApplicationConnect: PropTypes.func,
    bookedTasks: PropTypes.object,
    loanApplication: PropTypes.object,
    isGetLoanApplicationLoading: PropTypes.bool,
    isGetLoanApplicationError: PropTypes.bool,
    isUserZeroDpVariant: PropTypes.bool,
    timeoutDetails: PropTypes.object,
    applicationFormConfigData: PropTypes.object,
    bookings: PropTypes.object,
    setSplitPaymentConnect: PropTypes.func,
    getOrderDetailConnect: PropTypes.func,
    order: PropTypes.object,
    documentUploadConfigData: PropTypes.object,
    verificationData: PropTypes.object,
    verificationDataError: PropTypes.object
};

export default BookedTasks;
