import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getInhouseEligibility, setPreApprovedOrigin } from "../pre-approval-financing-flow/pre-approval-loan-wrapper/actions";
import { fetchCmsConfig, fetchCarList as fetchHomeCarList } from "../home/actions";
import { getIsStraightThroughEligble } from "../checkout-info/actions";

import Home from "./component";
import { getTestDriveVariant } from "../test-drive-v2/actions";
import { setNudgeTimerId, updateNotificationCounts, updateNudgeCounts, updateSupportWidgetConfig } from "../support-widget/actions";

const mapStateToProps = ({
    user: {
        isLoggedIn,
        isUserZeroDpVariant,
        secureToken,
        userGeoData
    },
    preApprovalLoan: {
        preApproved
    },
    nudgeAlertList: {nudgeList, isLoading: nudgeListLoading, error: nudgeFetchError},
    homeCarList: {
        config,
        isSSR,
        buyWithConfidence,
        cmsConfig
    },
    filters: {searchPageResult},
    checkout: {
        stEligibleData
    },
    supportWidget: {
        categoryNudgeCounts,
        nudgeCountSynced
    },
    carListing: {
        showFinanceCentricListing
    }
}) => ({
    isLoggedIn,
    isUserZeroDpVariant,
    preApproved,
    secureToken,
    nudgeList,
    nudgeFetchError,
    nudgeListLoading,
    config,
    searchPageResult,
    isSSR,
    buyWithConfidence,
    stEligibleData,
    userGeoData,
    categoryNudgeCounts,
    nudgeCountSynced,
    showFinanceCentricListing,
    cmsConfig
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getInhouseEligibilityConnect: getInhouseEligibility,
    fetchHomeCarListConnect: fetchHomeCarList,
    getTestDriveVariantConnect: getTestDriveVariant,
    getIsStraightThroughEligbleConnect: getIsStraightThroughEligble,
    setPreApprovedOriginConnect: setPreApprovedOrigin,
    updateSupportWidgetConfigConnect: updateSupportWidgetConfig,
    updateNudgeCountsConnect: updateNudgeCounts,
    updateNotificationCountsConnect: updateNotificationCounts,
    setNudgeTimerIdConnect: setNudgeTimerId,
    fetchCmsConfigConnect: fetchCmsConfig
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Home);
