import React from "react";
import styles from "./skeleton.css";

const InventoryCarCardSmallSkeleton = () => {
    return (
        <div styleName={"styles.wrapper"}>
            <div styleName={"styles.outer"}>
                <div className="shimmer" styleName={"styles.imageWrapper"}>&nbsp;</div>
                <div styleName={"styles.contentWrapper"}>
                    <p className="shimmer" styleName={"styles.carName"}>&nbsp;</p>
                    <div styleName={"styles.priceWrapper"}>
                        <p className="shimmer" styleName={"styles.price"}>&nbsp;</p>
                        <p className="shimmer" styleName={"styles.perWeek"}>&nbsp;</p>
                    </div>
                    <p className="shimmer" styleName={"styles.exclusive"}>&nbsp;</p>
                </div>
            </div>
        </div>
    );
};

export default InventoryCarCardSmallSkeleton;
