import React, {useState, useEffect} from "react";
import styles from "./styles.css";
import InputText from "../../shared/input-text";
import PropTypes from "prop-types";
import { CAR_LIST_EVENT_NAMES, NUMBER } from "../../../constants/app-constants";
import { trackC2bMobileCustomEvents } from "../../../au-c2b/tracking";
import { C2B_AU_MOBILE_EVENTS } from "../../../au-c2b/tracking/c2b-mobile-events";
import Button from "../../shared/button";
import { useHistory } from "react-router";
import { trackMobileCustomEventsAU } from "../../../tracking";
import { SELL_TRADE_GA_EVENTS } from "./tracking";
import { yieldToMain } from "../../../utils/helpers/yield-to-main";

const TABS = {
    registration: {
        heading: "Rego",
        inputPlaceHolder: "ENTER REGO",
        // searchPlaceholder: "Which state is your car registered in",
        searchPlaceholder: "State",
        inputKey: "regNo",
        searchKey: "state",
        searchData: [],
        key: "registration"
    }
};
const SellOrTradeInBannerRevamp = ({
    checkVinActive,
    tradeInData,
    isLoggedIn,
    isGuestLogin,
    getTradeInStateConnect,
    getTradeInDetailsConnect,
    tradeInStates,
    regoInputRef = null,
    setInputRegoNumberConnect,
    screenName = CAR_LIST_EVENT_NAMES.HOME_PAGE
}) => {
    const history = useHistory();
    const [isVinActive, setIsVinActive] = React.useState(false);
    TABS.registration.searchData = tradeInStates;
    const currentTab =  TABS.registration;
    const {tradeInServiceProviderId} = tradeInData || {};
    const [data, setData] = useState(tradeInData);
    useEffect(() => {
        getTradeInStateConnect();
        if (isLoggedIn || isGuestLogin) {
            getTradeInDetailsConnect()
                .then((response) => {
                    if (response.vin || response.regNo) {
                        setIsVinActive(response.vin ? true : false);
                        setData(response);
                    }
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn, isGuestLogin]);

    useEffect(() => {
        if (screenName === CAR_LIST_EVENT_NAMES.LISTING_PAGE) {
            trackMobileCustomEventsAU(SELL_TRADE_GA_EVENTS.CARD_VIEWED, {
                ...SELL_TRADE_GA_EVENTS.DETAILS,
                eventLabel: `{{viewed_test}}`
            });

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClick = async () => {
        await yieldToMain();
        setInputRegoNumberConnect(data.regNo);
        if (screenName === CAR_LIST_EVENT_NAMES.PAGE_HOME) {

            trackC2bMobileCustomEvents(C2B_AU_MOBILE_EVENTS.C2B_LANDING_REGO_NUMBER_ENTERED_BANNER_CLICKED, {
            });
        } else if (screenName === CAR_LIST_EVENT_NAMES.LISTING_PAGE) {
            trackMobileCustomEventsAU(SELL_TRADE_GA_EVENTS.GET_VALUATION_CLICK, {
                ...SELL_TRADE_GA_EVENTS.DETAILS,
                eventLabel: `{{get_valuation_clicked_test}}`
            });

        }
        history.push("/sell-your-car");
        window.scrollTo(0, 0);
    };
    const fontHeightStyles = () => {
        if (!checkVinActive) {
            return "";
        } else if (isVinActive) {
            return "styles.atfForm styles.autoHeight";
        } else {
            return "styles.atfForm";
        }
    };
    const formTypeStyle = () => {
        if (!checkVinActive) {
            return "styles.bannerForms";
        } else if (isVinActive) {
            return "styles.vinForms";
        } else {
            return "styles.regoForms";
        }
    };
    const handleOnBlur = (value, questionKey) => {
        if (screenName === CAR_LIST_EVENT_NAMES.PAGE_HOME) {

            if (questionKey === TABS.registration.inputKey) {
                trackC2bMobileCustomEvents(C2B_AU_MOBILE_EVENTS.C2B_LANDING_BANNER_REGO_NUMBER_ENTERED, {
                });
            } else if (questionKey === TABS.vehicle.inputKey) {
                trackC2bMobileCustomEvents(C2B_AU_MOBILE_EVENTS.C2B_LANDING_VIN_ENTERED, {
                    eventLabel: tradeInServiceProviderId
                });
            }
        } else if (screenName === CAR_LIST_EVENT_NAMES.LISTING_PAGE && value.length > 0) {
            trackMobileCustomEventsAU(SELL_TRADE_GA_EVENTS.REGO_ENTERED, {
                ...SELL_TRADE_GA_EVENTS.DETAILS,
                eventLabel: `{{rego_entered_test}}`
            });

        }

    };
    const handleOnChange = (value, questionKey) => {
        setData({ ...data, [questionKey]: value });
    };
    return (
        <div styleName={"styles.outer"}>
            <p styleName={"styles.heading"}>Got a car to sell? Get a <span>free valuation instantly.</span></p>
            <div styleName={"styles.formWrapper"}>
                <div styleName={fontHeightStyles()}>
                    <div styleName={checkVinActive ? "styles.formContainer" : ""}>
                        <div styleName={formTypeStyle()}>
                            <InputText
                                value={data[currentTab.inputKey] ? data[currentTab.inputKey] : ""}
                                placeholder={currentTab.inputPlaceHolder}
                                onBlurCallback={(value) => {
                                    handleOnBlur(value, currentTab.inputKey);
                                }}

                                inputRef={regoInputRef}
                                onChange={
                                    (e) => {
                                        if (e.target.value.length > NUMBER.SIXTEEN + 1) return;
                                        handleOnChange(e.target.value,  currentTab.inputKey);
                                    }
                                }
                            />
                            {Array(NUMBER.FOUR).fill({}).map(() => (
                                <span styleName={"styles.circle"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
                                        <circle cx="2" cy="2" r="2" fill="#D9D9D9"/>
                                        <path d="M3.20078 1.77407H2.25019V0.799951H1.75137V1.77407H0.800781V2.22583H1.75137V3.19995H2.25019V2.22583H3.20078V1.77407Z" fill="#5B5B5B"/>
                                    </svg>
                                </span>
                            ))}
                        </div>
                    </div>
                </div>
                <div styleName={"styles.buttonContainer"}>
                    <Button text="START"  onClick={handleClick} />
                </div>
            </div>
            <p styleName={"styles.bottomText"}>100% online | Free car valuation | Doorstep pickup</p>
        </div>
    );
};

SellOrTradeInBannerRevamp.propTypes = {
    checkVinActive: PropTypes.bool,
    tradeInData: PropTypes.object,
    isLoggedIn: PropTypes.bool,
    isGuestLogin: PropTypes.bool,
    getTradeInStateConnect: PropTypes.func,
    getTradeInDetailsConnect: PropTypes.func,
    tradeInStates: PropTypes.object,
    regoInputRef: PropTypes.object,
    setInputRegoNumberConnect: PropTypes.func,
    screenName: PropTypes.string
};

export default SellOrTradeInBannerRevamp;
