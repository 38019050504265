/* eslint-disable max-params */
/* eslint-disable max-statements */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateCustomBudgetDropdownOption, updateSelectedSuggestions } from "../filters/actions";
import styles from "./styles.css";
import Search from "../../shared/search";
import { FILTER_TYPES } from "../../../constants/app-constants";
import isEmptyObject from "../../../utils/helpers/is-empty-object";

const DropdownFilter = ({
    options,
    filter,
    defaultInputValue,
    onOptionSelection,
    onCustomSelect
}) => {
    const {filterType = ""} = filter || {};
    const filterSuggestion =  (
        options || [])?.map(item => {
        const isLogoAvailable = !isEmptyObject(item?.logo || {});
        return {
            ...item,
            ...(!item?.label && { label: filterType === FILTER_TYPES.RANGE_FILTER ? item?.displayText : item?.displayName }),
            value: item?.name,
            logo: isLogoAvailable ? item?.logo : null
        };
    });
    const selectOption = (itemFilterKey, itemOptionKey, itemIsSelected, itemDisplayText, data) => {
        const {min = 0, max = 0} = data || {};
        const eventLabel = `${itemOptionKey} ${itemIsSelected ? "ON" : "OFF"}`;
        if (itemOptionKey === "Custom") {
            onCustomSelect();
        } else {
            onOptionSelection({
                filterKey: itemFilterKey,
                optionKey: itemOptionKey,
                isSelected: itemIsSelected,
                displayText: itemDisplayText,
                isRangeFilter: filterType === FILTER_TYPES.RANGE_FILTER,
                isSingleEntity: true,
                ...filterType === FILTER_TYPES.RANGE_FILTER && {
                    rangeValues: {
                        min,
                        max
                    }
                }
            }, eventLabel);
        }
    };
    const defaultSelectedOption = filterSuggestion?.find(item => item.value === defaultInputValue);
    return (
        <div styleName={"styles.selectOneWrap"}>
            <Search
                placeholder={defaultInputValue || "Select one"}
                data={filterSuggestion}
                showLogoOptions
                isSearchable={false}
                onChange={(e) => selectOption(filter?.key, e?.key, true, e?.displayText, e)}
                defaultValue={defaultSelectedOption}
            />
        </div>
    );
};

DropdownFilter.propTypes = {

    options: PropTypes.array,
    filter: PropTypes.object,
    defaultInputValue: PropTypes.string,
    onOptionSelection: PropTypes.func,
    onCustomSelect: PropTypes.func
};

const mapStateToProps = ({
    filters: {
        selectedSuggestions,
        allFilters
    },
    preApprovalLoan: {
        maxLoanAmount,
        quotesAvailable,
        financeData
    }
}) => ({
    selectedSuggestions,
    maxLoanAmount,
    quotesAvailable,
    financeData,
    allFilters
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateSelectedSuggestionsConnect: updateSelectedSuggestions,
    updateCustomBudgetDropdownOptionConnect: updateCustomBudgetDropdownOption
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DropdownFilter);
