import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { checkoutRoutes } from "../../../constants/checkout-routes";
import { getCheckoutPageURL } from "../../../utils/helpers/get-detail-page-url";
import PropTypes from "prop-types";
import Button from "../../shared/button";
import styles from "./styles.css";
import { trackMobileCustomEventsAU } from "../../../tracking";
import { AU_MOBILE_EVENTS } from "../../../tracking/au.mobile-events";

const ZeroDpReBookCar = ({
    content,
    postReBookZeroDpOrderConnect,
    softResetCheckoutDataConnect,
    fetchCarDetailsConnect,
    getZeroDpConfigConnect,
    secureToken,
    styleNameScreen = "bookButton",
    oldOrderId
}) => {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        return () => {
            softResetCheckoutDataConnect();
        };
    }, [softResetCheckoutDataConnect]);

    const handleBookThisCar = async () => {
        try {
            setIsLoading(true);
            const response = await fetchCarDetailsConnect((content || {}).appointmentId);
            const  { detail } = response || {};
            const  { booked, reserved } = detail || {};
            trackMobileCustomEventsAU(AU_MOBILE_EVENTS.ZERO_DP_STAGE2, {
                eventAction: "Timer runout",
                eventLabel: "Book_this_car"
            });
            if (!booked && !reserved) {
                await postReBookZeroDpOrderConnect(oldOrderId);
                const {relativeURL} = getCheckoutPageURL(content, checkoutRoutes.selectDeposit.route);
                history.push(relativeURL);
            }
        } catch (e) {
            getZeroDpConfigConnect(secureToken);
            setIsLoading(false);
        }
    };

    return  (
        <div
            styleName={`styles.${styleNameScreen}`}>
            <Button text={"Book this car"} onClick={handleBookThisCar} isLoading={isLoading} />
        </div>
    );
};

ZeroDpReBookCar.propTypes = {
    content: PropTypes.object,
    postReBookZeroDpOrderConnect: PropTypes.func,
    getZeroDpConfigConnect: PropTypes.func,
    oldOrderId: PropTypes.string,
    handlePollingCallback: PropTypes.func,
    showToastConnect: PropTypes.func,
    styleNameScreen: PropTypes.string,
    softResetCheckoutDataConnect: PropTypes.func,
    fetchCarDetailsConnect: PropTypes.func,
    drivaPollingStatus: PropTypes.string,
    secureToken: PropTypes.string
};

export default ZeroDpReBookCar;

