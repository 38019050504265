import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import SuccessIcon from "./images/success-illustration.gif";
import Logo from "./images/logo.svg";

const ConfirmBookingReserved = ({firstName, successText, showCars24Logo = true, showFirstName = true}) => {
    return (
        <div styleName={"styles.wrapper"}>
            <img styleName={"styles.successGif"} src={SuccessIcon} alt={"Congratulations gif"} />
            {showFirstName && <span styleName={"styles.congratulation"}>Congratulations {firstName}!</span>}
            <strong styleName={"styles.successful"}>{successText || "Congratulations"}</strong>
            {showCars24Logo && <img styleName={"styles.logo"} src={Logo} alt="cars24" />}
        </div>
    );
};

ConfirmBookingReserved.propTypes = {
    source: PropTypes.string,
    successText: PropTypes.string,
    firstName: PropTypes.string,
    showCars24Logo: PropTypes.bool,
    showFirstName: PropTypes.bool
};

export default ConfirmBookingReserved;
