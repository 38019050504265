/* eslint-disable complexity */
import React, { useRef, useEffect } from "react";
import { Field, getIn } from "formik";
import PropTypes from "prop-types";
import Callout from "../../shared/callout";
import styles from "./styles.css";
import { forwardRef } from "react";
import { NUMBER } from "../../../constants/app-constants";
import InfoIcon from "./images/info-icon.svg";
import Tooltip from "../../shared/tooltip";
import { generateDynamicStyles } from "../../../utils/helpers/generate-styles";
import snarkdown from "snarkdown";

const DynamicFormSingleField = forwardRef(({
    errors,
    touched,
    errorOnSubmitCount,
    fieldObj,
    fieldKey
}, ref) => {
    const { helperText, apiKey,  isRequired, title, fieldType, style, errorKey, hideError = false } = fieldObj || {};
    const errorText = getIn(errors, errorKey || apiKey);
    const touchedInput = getIn(touched, apiKey);
    const displayError = !hideError && errorText && (touchedInput || errorOnSubmitCount);

    const styleName = generateDynamicStyles(styles, style);

    return (
        <div key={fieldKey} className={styleName}>
            {title && <p styleName={"styles.title"}>{title}{!!isRequired && <span>*</span>}</p>}
            <Field
                isFormikForm={true}
                key={fieldKey}
                {...fieldObj}
                {...(fieldObj.props || {})}
                component={fieldType}
                name={apiKey}
                id={apiKey}
                showError={!!displayError}
            />
            {!!(displayError) &&
                <div styleName={"styles.error"} ref={ref}>{errorText}</div>
            }
            {helperText && <Callout text={helperText} />}
        </div>
    );
});

DynamicFormSingleField.propTypes = {
    fieldObj: PropTypes.object,
    fieldKey: PropTypes.number,
    errorOnSubmitCount: PropTypes.number,
    errors: PropTypes.object,
    touched: PropTypes.object,
    tooltipText: PropTypes.string,
    fadedText: PropTypes.string,
    onClickTooltip: PropTypes.func
};

const DynamicFormFields = ({
    form,
    errors,
    touched,
    errorOnSubmitCount,
    values,
    updateFormData
}) => {
    const questionRef = useRef([]);
    const { questions } = form || {};
    let count = 0;

    useEffect(() => {
        if ((questionRef || {}).current && errorOnSubmitCount) {
            window.setTimeout(() => {
                const firstValidRef = ((questionRef || {}).current || []).filter((value) => value !== null);
                if (firstValidRef[0]) firstValidRef[0].scrollIntoView({block: "center"});
            }, NUMBER.ZERO);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorOnSubmitCount]);

    useEffect(() => {
        updateFormData(values);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values]);

    const displaySection = (question, questionKey) => {
        //const { questionLabel, fields, questionHelperLabel, helperText, fieldStyle, isRequired, questionStyle, setHtml, fadedText, tooltipText, onClickTooltip } = question || {};
        const { question: preApprovalFormQuestionLabel, questionLabel: stage2FormQuestionLabel, fields, questionHelperText: preApprovalFormHelperLabel, questionHelperLabel: stage2FormHelperLabel, helperText, fieldStyle, isRequired, questionStyle, setHtml, fadedText, tooltipText, onClickTooltip, customHelperStyle, helperStyle } = question || {};
        const questionLabel = preApprovalFormQuestionLabel || stage2FormQuestionLabel;
        const questionHelperLabel = preApprovalFormHelperLabel || stage2FormHelperLabel;
        const questionLabelStyle = `${(questionStyle || "styles.questionLabel")} ${questionHelperLabel ? "styles.mb-4" : ""} `;
        const customQuestionHelperStyle = customHelperStyle && generateDynamicStyles(styles, customHelperStyle);
        const questionHelperStyle = `${helperStyle || "styles.questionHelperLabel"}`;
        const styleName = fieldStyle ? generateDynamicStyles(styles, fieldStyle) : styles.singleColumnandInput;

        return (
            <div styleName={"styles.sectionWrapper"} key={questionKey}>
                {setHtml ?
                    <p styleName={questionLabelStyle} dangerouslySetInnerHTML={{__html: snarkdown(questionLabel)}} /> :
                    <p styleName={questionLabelStyle}>
                        {questionLabel}
                        {!!isRequired && <span>*</span>}
                        {!!tooltipText && <Tooltip
                            onClickCallback={onClickTooltip}
                            body={[
                                tooltipText
                            ]}
                        >
                            <span><img styleName={"styles.tooltipImg"} src={InfoIcon} alt="tooltip" /></span>
                        </Tooltip>}
                        {!!fadedText && <span styleName={"styles.fadedText"}>{fadedText}</span>}
                    </p>
                }
                {questionHelperLabel && <p
                    dangerouslySetInnerHTML={{
                        __html: snarkdown(questionHelperLabel)
                    }} className={customQuestionHelperStyle || ""} styleName={customQuestionHelperStyle ? ""  : questionHelperStyle} />}
                <div className={styleName}>
                    {fields.map((fieldObj, fieldKey) =>
                        (!!(fieldObj.isVisible) && <DynamicFormSingleField
                            key={fieldKey}
                            ref={(el) => {return (questionRef.current[count++] = el);}}
                            fieldObj={fieldObj}
                            fieldKey={fieldKey}
                            errors={errors}
                            touched={touched}
                            errorOnSubmitCount={errorOnSubmitCount}
                        />)
                    )}
                </div>
                {helperText && <Callout text={helperText} />}
            </div>
        );
    };

    return (
        <div>
            {
                questions.map((question, questionKey) =>
                    !!(question.isVisible) && displaySection(question, questionKey)
                )
            }
        </div>
    );
};

DynamicFormFields.propTypes = {
    form: PropTypes.object,
    setFieldTouched: PropTypes.object,
    errorOnSubmitCount: PropTypes.number,
    errors: PropTypes.object,
    touched: PropTypes.object,
    values: PropTypes.object,
    updateFormData: PropTypes.func
};

export default DynamicFormFields;
