export const SELL_TRADE_GA_EVENTS = {
    CARD_VIEWED: "CARD_VIEWED",
    REGO_ENTERED: "REGO_ENTERED",
    GET_VALUATION_CLICK: "GET_VALUATION_CLICK",
    DETAILS: {
        event: "custom_event",
        eventAction: "trade_in_card",
        eventCategory: "Cars24_listing_page"
    }
};

export const SELL_TRADE_AMPLITUDE_EVENTS = {
    CARD_VIEWED: "trade in CLP rego card viewed",
    REGO_ENTERED: "trade in CLP card rego entered",
    CTA_CLICKED: "trade in CLP rego card CTA clicked"
};
