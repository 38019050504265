import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getOrderDetail, getVerificationStatus, setSplitPayment } from "../checkout-info/actions";
import { getFinancePostPaymentApplication } from "../finance-post-payment-loan-application/actions";
import { fetchBookedTasksList } from "./actions";
import BookedTasks from "./component";
import {getMyBookings} from "../my-bookings/actions";

const mapStateToProps = ({
    user: {secureToken},
    bookedTasksList: {bookedTasks},
    financePostPayment: {
        loanApplication,
        isGetLoanApplicationLoading,
        isGetLoanApplicationError,
        applicationFormConfigData,
        documentUploadConfigData
    },
    checkout: {
        verificationData,
        verifyIdCompleted,
        verificationDataError,
        order
    },
    myBookings: {
        bookings,
        isFetching
    }
}) => ({
    secureToken,
    bookedTasks,
    loanApplication,
    isGetLoanApplicationLoading,
    isGetLoanApplicationError,
    applicationFormConfigData,
    documentUploadConfigData,
    verificationData,
    verifyIdCompleted,
    verificationDataError,
    order,
    isFetching,
    bookings
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchBookedTasksListConnect: fetchBookedTasksList,
    getMyBookingsConnect: getMyBookings,
    getFinancePostPaymentApplicationConnect: getFinancePostPaymentApplication,
    getVerificationStatusConnect: getVerificationStatus,
    setSplitPaymentConnect: setSplitPayment,
    getOrderDetailConnect: getOrderDetail
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BookedTasks);
