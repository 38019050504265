import React, { useRef, useState, useEffect } from "react";
import styles from "./styles.css";
import { PropTypes } from "prop-types";

const PlaceholderAnimation = ({ animationDetail: {
    animationText,
    animationInterval
} }) => {
    const [placeholderAnimationValue, setPlaceholderAnimationValue] = useState("");
    const [currentAnimationText, setCurrentAnimationText] = useState(animationText[0]);

    const intervalRef = useRef();

    const changeCurrentAnimationText = () => {
        const currentIndex = animationText.indexOf(currentAnimationText);
        if (currentIndex + 1 === animationText.length) {setCurrentAnimationText(animationText[0]);} else setCurrentAnimationText(animationText[currentIndex + 1]);
    };

    const startAnimation = () => {
        intervalRef.current = window.setInterval(() => {
            setPlaceholderAnimationValue((val) => {
                if (val !== currentAnimationText) {
                    val = currentAnimationText.slice(0, val?.length + 1);
                    return val;
                } else {
                    window.clearInterval(intervalRef.current);
                    intervalRef.current = null;
                    changeCurrentAnimationText();
                    return "";
                }
            });
        }, animationInterval);
    };

    useEffect(() => {
        const timeoutRef = null;
        if (!intervalRef.current) {
            startAnimation();
        }
        return () => {
            window.clearInterval(intervalRef.current);
            window.clearTimeout(timeoutRef);
            intervalRef.current = null;
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentAnimationText]);

    return (
        <div styleName={"styles.placeholderAnimationContainer"}>
            <div>{placeholderAnimationValue}</div>
            <span styleName={"styles.placeholderBlinker"}>|</span>
        </div>
    );
};

PlaceholderAnimation.propTypes = {
    animationDetail: PropTypes.object
};

export default PlaceholderAnimation;
