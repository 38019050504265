/* eslint-disable max-statements */
/* eslint-disable complexity */
import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import getCurrentPage from "../utils/helpers/get-current-page";
//todo: move this to backend
const PRE_BI_ASSISTANCE_CATEGORIES  = {
    BUYER: "buyer",
    GENERIC: "generic",
    FINANCE: "finance",
    SELL: "sell"
};
const PRE_BI_ASSISTANCE_NUDGE_TIMEOUT = 5000;
const BUYER_ASSISTANT_NAMES = {
    BUYER_ASSISTANT: "Thomas",
    FINANCE_ASSISTANT: "Swaps"
};

export const useFireNudge = ({
    updateSupportWidgetConfig,
    updateNudgeCounts,
    updateNotificationCounts,
    setNudgeTimerId,
    make,
    model
}) => {
    const location = useLocation();
    const { pathname } = location || {};
    const [timerId, setTimerId] = useState(null);
    const {
        supportWidget = {},
        config = {}
    } = useSelector((state) => state || {});
    const {showNudge = false, isModalContentSeen = false, firedNudgeIds = []} = supportWidget || {};
    const {buyerNudgeConfig = {}, financeNudgeConfig = {}, sellNudgeConfig = {}} = config || {};

    const NUDGE_CONFIG_MAP = {
        [PRE_BI_ASSISTANCE_CATEGORIES.BUYER]: buyerNudgeConfig,
        [PRE_BI_ASSISTANCE_CATEGORIES.FINANCE]: financeNudgeConfig,
        [PRE_BI_ASSISTANCE_CATEGORIES.SELL]: sellNudgeConfig
    };

    const getCurrentNudgeConfig = useCallback((category) => {
        return NUDGE_CONFIG_MAP[category] || {};
    }, [NUDGE_CONFIG_MAP]);

    const isFiredGenericNudge = (nudgeConfig, idArray) => {
        return (idArray || []).find(id => id === nudgeConfig.nudgeId);
    };

    const handleNudgeUpdate = React.useCallback(() => {
        if (showNudge) {
            updateSupportWidgetConfig({
                showAnimation: false,
                showNudge: false
            });
            updateNotificationCounts();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    useEffect(() => {
        handleNudgeUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    useEffect(() => {
        return () => {
            if (timerId) {
                window.clearTimeout(timerId);
            }
        };
    }, [showNudge, timerId]);

    const fireNudge = useCallback((nudgeConfig) => {
        const {isCheckoutPage} = getCurrentPage() || {};
        if (isModalContentSeen || isCheckoutPage || isFiredGenericNudge(nudgeConfig, firedNudgeIds)) {
            return;
        }
        if (make && model && nudgeConfig.heading.includes("dynamic_makeModel")) {
            nudgeConfig.heading = nudgeConfig.heading.replace("dynamic_makeModel", `${make} ${model}`);
        }

        if (nudgeConfig.bodyText.includes("dynamic_name")) {
            const currentConfig = getCurrentNudgeConfig(nudgeConfig?.category);
            if (Object.keys(currentConfig || {}).length) {
                const {people = []} = currentConfig || {};
                const {name = "", imageUrl = "" } = people?.length ? people[0] : {};
                nudgeConfig.bodyText = nudgeConfig.bodyText.replace("dynamic_name", name);
                nudgeConfig.imagePath = imageUrl;
            } else {
                const name = nudgeConfig?.category === PRE_BI_ASSISTANCE_CATEGORIES.BUYER ? BUYER_ASSISTANT_NAMES.BUYER_ASSISTANT : BUYER_ASSISTANT_NAMES.FINANCE_ASSISTANT;
                nudgeConfig.bodyText = nudgeConfig.bodyText.replace("dynamic_name", name);
            }
        }

        updateSupportWidgetConfig({
            showAnimation: true,
            nudgeDetails: {
                heading: nudgeConfig.heading,
                bodyText: nudgeConfig.bodyText,
                imagePath: nudgeConfig.imagePath,
                category: nudgeConfig.category,
                emojiCode: nudgeConfig.emojiCode
            },
            showNudge: true,
            ...(nudgeConfig.category === PRE_BI_ASSISTANCE_CATEGORIES.SELL && {firedNudgeIds: [...firedNudgeIds, nudgeConfig.nudgeId ]})
        });

        updateNudgeCounts(nudgeConfig.category);
        const newTimerId = window.setTimeout(() => {
            updateSupportWidgetConfig({
                showAnimation: false,
                showNudge: false
            });
            updateNotificationCounts();
        }, PRE_BI_ASSISTANCE_NUDGE_TIMEOUT);
        setNudgeTimerId(newTimerId);
        setTimerId(newTimerId);

    }, [isModalContentSeen, make, model, updateSupportWidgetConfig, firedNudgeIds, updateNudgeCounts, setNudgeTimerId, getCurrentNudgeConfig, updateNotificationCounts]);
    return {fireNudge};
};
