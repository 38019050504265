import React, { useState } from "react";
import styles from "./styles.css";
import GooglePlay from "./images/google-play.png";
import AppStore from "./images/app-store.png";
import Button from "../../shared/button";
import PropTypes from "prop-types";
import Slider from "react-slick";
import FirstCarImage from "./images/first.png";
import SecondCarImage from "./images/second.png";
import ThirdCarImage from "./images/third.png";
import LazyImage from "../../shared/lazy-image";

const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    speed: 1000,
    arrow: false,
    dots: false
};

const WebToAppBanner = ({ webToAppUrl = "" }) => {
    const [nav1, setNav1] = useState();
    return (
        <div styleName={"styles.outer"}>
            <div styleName={"styles.animationWrap"}>
                <Slider asNavFor={nav1} ref={(slider1) => setNav1(slider1)} {...settings}>
                    <LazyImage src={FirstCarImage} width="235px" height="96px" alt="Image 1" />
                    <LazyImage src={SecondCarImage} width="230px" height="106px" alt="Image 2" />
                    <LazyImage src={ThirdCarImage} width="230px" height="106px" alt="Image 3" />
                </Slider>
            </div>
            <p styleName={"styles.bannerText"}>Find your dream car<br />seamlessly with Cars24 App</p>
            <div styleName={"styles.iconWrapper"}>
                <LazyImage src={GooglePlay} />
                <LazyImage src={AppStore} />
            </div>
            <a href={webToAppUrl}>
                <Button text="Download app" />
            </a>
        </div>
    );
};

WebToAppBanner.propTypes = {
    webToAppUrl: PropTypes.string
};
export default WebToAppBanner;
