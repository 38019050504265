import React from "react";
import {withRouter} from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import FilterDropdownRevamp from "./component";
import { getTradeInToggleVisible, setViewCarsButtonTriggered } from "../trade-in-your-car-wrapper/actions";

const mapStateToProps = ({
    user: { isLoggedIn },
    filters: {
        urlOptions
    },
    carListing: {
        carComparision,
        isLoading
    },
    abExperiment: {
        carCompareExperiment,
        showSearchFreeText
    },
    tradeIn: {
        tradeInToggleSelected,
        userTradeInData,
        isViewCarsButtonTriggered
    }
}) => ({
    urlOptions,
    carComparision,
    carCompareExperiment,
    tradeInToggleSelected,
    userTradeInData,
    isLoggedIn,
    isLoading,
    isViewCarsButtonTriggered,
    showSearchFreeText
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getTradeInToggleVisibleConnect: getTradeInToggleVisible,
    setViewCarsButtonTriggeredConnect: setViewCarsButtonTriggered
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FilterDropdownRevamp));
