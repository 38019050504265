import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import VerifyUserLicence from "./component";
import { getPaymentSummary } from "../../my-bookings/actions";
import { setLicenceUploadScreenType } from "../../checkout-info/actions";

const mapStateToProps = (
    {checkout: {
        order,
        signContractData,
        drivingLicenceData
    }, myBookings: {
        paymentSummaryData: {
            reconcilePending
        }
    }}) => ({
    order,
    signContractData,
    reconcilePending,
    drivingLicenceData
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getPaymentSummaryConnect: getPaymentSummary,
    setLicenceUploadScreenTypeConnect: setLicenceUploadScreenType
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(VerifyUserLicence);
