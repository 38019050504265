/* eslint-disable max-statements */
/* eslint-disable complexity */
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getAbExpirementVariant } from "../utils/helpers/get-ab-expirement-variant";
import { EXPERIMENT_TYPE } from "../constants/optimize-constants";
import { FILTER_ENTITIES } from "../constants/app-constants";

const useModifiedFilters = (desktopCityNameFilterAllowed = false) => {

    const HIDDEN_FILTERS = [FILTER_ENTITIES.MAKECITYNAME, FILTER_ENTITIES.MODELCITYNAME];
    const [validFilters, setValidFilters] = useState(HIDDEN_FILTERS);

    const {
        abExperiment
    } = useSelector((state) => state || {});

    const {
        webLocationHighlighting = {}
    } = abExperiment || {};

    const showWebLocationHighlightingVariantA = getAbExpirementVariant(webLocationHighlighting, EXPERIMENT_TYPE.VARIANT_A);

    useEffect(() => {
        if (showWebLocationHighlightingVariantA) {
            if (!desktopCityNameFilterAllowed) {
                const updatedFilters = [
                    ...validFilters,
                    FILTER_ENTITIES.CITYNAME
                ];
                setValidFilters(updatedFilters);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getModifiedFilters = (selectedFilters) => {
        const filters = selectedFilters.filter((item) => validFilters.indexOf(item.filterKey) === -1);
        return filters;
    };

    const isSeoFilterPresent = (selectedFilters) => {
        return selectedFilters.find(item => validFilters.indexOf(item.filterKey) > -1);
    };

    return {validFilters, getModifiedFilters, isSeoFilterPresent};
};

export default useModifiedFilters;
