/* eslint-disable complexity */
/* eslint-disable max-statements */
import React, { useRef, useState, useEffect } from "react";
import styles from "./styles.css";
import InputText from "../input-text";
import { useHistory } from "react-router";

import Button from "../button";
import PropTypes from "prop-types";
import Filters from "../../../utils/filters-v2";
import { getCity } from "../../../utils/helpers/get-city-from-listing";
import { DEFAULT_CITY, FILTER_ENTITIES, MAXIMUM_REPAYMENT, PAGE_SOURCE, WEBSITE_SECTIONS } from "../../../constants/app-constants";
import BodyTypeFilter from "./body-type-filter";
import { clevertapUserProperties, trackMobileCustomEventsAU } from "../../../tracking";
import { GA_EVENT_NAMES } from "./tracking";
import currentPathObject from "../../../utils/helpers/get-current-page";
import { getAppliedFilterValues, parsedAppliedFilters } from "../../../utils/helpers/filter-values";
import { numberRegex } from "../../../utils/helpers/regex";
import { BOOL_STATE } from "../../../constants/checkout-constants";
import  PlaceholderAnimation from "./PlaceholderAnimation";
import { storeSectionUsedTimestamp } from "../../../utils/helpers/website-section-used-timestamp";
import DepositFilter from "./deposit-filter";
import useModifiedFilters from "../../../hooks/use-modified-filters";
import { yieldToMain } from "../../../utils/helpers/yield-to-main";

const DiscoverYourDreamCarSharedForm = ({
    allFilters = {},
    config,
    showWeekPlaceholderAnimation = false,
    weekPlaceholderAnimationDetials,
    showWeekLeftAnimation = false,
    showIntegratedFormLabel = false,
    defaultVehicleTypeOpen = false,
    fetchListingCountConnect,
    selectedFilters = [],
    selectedCityName,
    metaContent,
    updateFilterOptionDataConnect,
    reloadCarListConnect,
    onClose: onClpModalClose,
    appliedFilters,
    listingCount,
    isListingCountLoading,
    updateSelectedSuggestionsConnect,
    updateFinanceCentricAnimationConnect,
    isModal,
    updateCustomBudgetDropdownOptionConnect,
    // isCustomBudgetSelected,
    updateFinanceCentricListingConnect,
    urlOptions = {},
    totalListedCar
}) => {

    const [repayInputFocused, setRepayInputFocused] = useState(false);
    const emiPerWeekFilter = selectedFilters.find(filter => filter.filterKey === FILTER_ENTITIES.EMI_PER_WEEK);
    const emiValue = emiPerWeekFilter?.max || "";
    const [repayValue, setRepayValue] = useState(emiValue || "");
    const [showVehicleType, setShowVehicleType] = useState(defaultVehicleTypeOpen);
    const [repayInputBlurred, setRepayInputBlurred] = useState(false);
    const [firedFlowEnteredEvent, setFiredFlowEnteredEvent] = useState(false);
    const {isListingPage} = currentPathObject() || {};
    const repayInputRef = useRef(null);
    const weekRef = useRef(null);
    const hiddenValueRef = useRef(null);
    const history = useHistory();
    const maxEmi = MAXIMUM_REPAYMENT;
    const { getModifiedFilters } = useModifiedFilters();

    const returnGaEvent = (key) => {
        switch (key) {
        case FILTER_ENTITIES.EMI_PER_WEEK: {
            if (isListingPage) {
                return GA_EVENT_NAMES.repayment_selected_modal;
            } else {
                return GA_EVENT_NAMES.repayment_selected_hp;
            }
        }
        case FILTER_ENTITIES.DEPOSIT: {
            if (isListingPage) {
                return GA_EVENT_NAMES.deposit_selected_modal;
            } else {
                return GA_EVENT_NAMES.deposit_selected_hp;
            }
        }
        case FILTER_ENTITIES.PRICE: {
            if (isListingPage) {
                return GA_EVENT_NAMES.budget_selected_modal_custom;
            } else {
                return GA_EVENT_NAMES.budget_selected_hp;
            }
        }
        case FILTER_ENTITIES.BODY_TYPE: {
            if (isListingPage) {
                return GA_EVENT_NAMES.bodytype_selected_modal;
            } else {
                return GA_EVENT_NAMES.bodytype_selected_hp;
            }
        }
        default:
            return null;
        }
    };

    const onOptionSelection = async (data) => {
        const {filterKey = "", optionKey = ""} = data || {};
        await yieldToMain();
        if (data) {
            updateFilterOptionDataConnect(data);
            if (!showVehicleType) setShowVehicleType(true);
        }
        if (filterKey === FILTER_ENTITIES.PRICE) {
            updateSelectedSuggestionsConnect({
                ...data,
                isSuggestion: true
            });
            if (!isModal) {
                updateCustomBudgetDropdownOptionConnect(false);
            }
        }

        trackMobileCustomEventsAU(returnGaEvent(filterKey), {
            ...returnGaEvent(filterKey),
            eventLabel: optionKey
        });
    };

    const emiPriceUpdateCallback = (input = "") => {
        const emiFilter = allFilters[FILTER_ENTITIES.EMI_PER_WEEK];
        const {key} = emiFilter;
        onOptionSelection({
            filterKey: key,
            isSelected: true,
            isRangeFilter: true,
            optionKey: input,
            rangeValues: {
                min: 0,
                max: input
            }
        });
    };

    const handleWeekAnimation = () => {
        if (showWeekLeftAnimation) {
            const hiddenElementWidth = hiddenValueRef.current.clientWidth;
            const hiddenElementLeftOffset = hiddenValueRef.current.offsetLeft;
            if (repayInputRef.current.scrollWidth <= repayInputRef.current.clientWidth) {
                weekRef.current.style = `left: ${hiddenElementLeftOffset + hiddenElementWidth}px;`;
            }
        }
    };

    const handleRepayInputBlur = (input) => {

        const currentRepayValue = input > maxEmi ? maxEmi : input;
        setRepayValue(() => currentRepayValue);
        if (!repayValue) {
            setRepayInputFocused(false);
        }
        setRepayInputBlurred(true);
        emiPriceUpdateCallback(currentRepayValue);
    };
    const handleRepayInputFocus = () => {
        setRepayInputFocused(true);
        setRepayInputBlurred(false);
        repayInputRef.current.focus();
        weekRef.current.style = `transform: translateY(-50%)`;
        if (!isModal && !firedFlowEnteredEvent) {
            setFiredFlowEnteredEvent(true);

            trackMobileCustomEventsAU(GA_EVENT_NAMES.flow_entered, {
                ...GA_EVENT_NAMES.flow_entered,
                eventLabel: PAGE_SOURCE.HOME_PAGE
            });
        }

    };

    useEffect(() => {
        if (repayInputFocused && repayValue && repayInputBlurred) {
            handleWeekAnimation();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [repayInputBlurred]);
    useEffect(() => {
        const filters = new Filters({ cityCode: getCity(metaContent).code, cityName: selectedCityName });
        const modifiedFilter = getModifiedFilters(selectedFilters);

        if (Object.keys(allFilters).length) {
            const { payload } = filters.getListingPayload({
                selectedFilters: modifiedFilter,
                filters: allFilters,
                ...(urlOptions && { options: { ...urlOptions } })
            });
            fetchListingCountConnect({
                size: 0,
                page: 0
            }, payload);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFilters, urlOptions]);

    const appliedFilterValues = getAppliedFilterValues(selectedFilters || []) || {};
    const commaSeparatedAppliedFilters = parsedAppliedFilters(selectedFilters || []);
    const handleFinanceCentricRedirect = async () => {
        await yieldToMain();
        const newFilters = new Filters({cityCode: DEFAULT_CITY.AU.code});
        const {relativeURL} = newFilters.getListingPayload({selectedFilters,
            filters: allFilters,
            ...(urlOptions && { options: { ...urlOptions } }),
            isFinanceCentricLayout: true});
        if (!isModal) {
            updateFinanceCentricAnimationConnect(true);
        }
        reloadCarListConnect(true);
        updateFinanceCentricListingConnect(true);
        history.push(relativeURL);
        storeSectionUsedTimestamp(WEBSITE_SECTIONS.FINANCE_FIRST_DISCOVERY);
        if (isModal) {
            onClpModalClose();
        }

        if (isListingPage) {
            trackMobileCustomEventsAU(GA_EVENT_NAMES.view_cars_clicked_modal, {
                ...GA_EVENT_NAMES.view_cars_clicked_modal,
                eventLabel: `${listingCount} | ${appliedFilterValues[FILTER_ENTITIES.EMI_PER_WEEK]} | ${appliedFilterValues[FILTER_ENTITIES.PRICE]} | ${appliedFilterValues[FILTER_ENTITIES.DEPOSIT]} | ${commaSeparatedAppliedFilters[FILTER_ENTITIES.BODY_TYPE]}`
            });
            trackMobileCustomEventsAU(GA_EVENT_NAMES.flow_entered, {
                ...GA_EVENT_NAMES.flow_entered,
                eventLabel: PAGE_SOURCE.CLP
            });
        } else {
            trackMobileCustomEventsAU(GA_EVENT_NAMES.view_cars_clicked_hp, {
                ...GA_EVENT_NAMES.view_cars_clicked_hp,
                eventLabel: `${listingCount} | ${appliedFilterValues[FILTER_ENTITIES.EMI_PER_WEEK]} | ${appliedFilterValues[FILTER_ENTITIES.PRICE]} | ${appliedFilterValues[FILTER_ENTITIES.DEPOSIT]} | ${commaSeparatedAppliedFilters[FILTER_ENTITIES.BODY_TYPE]}`
            });
            trackMobileCustomEventsAU(GA_EVENT_NAMES.flow_entered, {
                ...GA_EVENT_NAMES.flow_entered,
                eventLabel: PAGE_SOURCE.HOME_PAGE
            });
        }

        const cleverTapData = {"finance_focused_customer": BOOL_STATE.YES };
        clevertapUserProperties(cleverTapData);

    };

    useEffect(() => {
        const isFilterKeyPresent = selectedFilters.find(filter => filter.filterKey === FILTER_ENTITIES.PRICE || filter.filterKey === FILTER_ENTITIES.DEPOSIT);
        if (repayValue || isFilterKeyPresent) setShowVehicleType(true);
        if (repayValue) handleWeekAnimation();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isButtonDisabled = () => {
        if (!listingCount || !repayValue || (!appliedFilterValues[FILTER_ENTITIES.DEPOSIT])) {return true;}
        return false;
    };
    const getButtonStyles = () => {
        if (isListingCountLoading) {
            return "styles.buttonLoadingWrapper";
        } else if (isButtonDisabled()) {
            return "styles.disabledButtonWrapper";
        }
        return "styles.buttonWrapper";
    };

    const getButtonText = () => {
        if (selectedFilters.length === 0) {
            return `${totalListedCar} cars for you`;
        } else if (listingCount === 0) {
            return `No cars found`;
        } else {
            return `${listingCount} cars for you`;
        }
    };
    return (
        <div styleName={`styles.outer  ${showVehicleType ? "styles.outerIncHeight" : ""}`}>
            <div>
                <div styleName={"styles.firstBoxWrapper"} onClick={handleRepayInputFocus}>
                    <p styleName={showIntegratedFormLabel ? "styles.label" : "styles.separateLabel"}>
                        How much can you repay per week?
                    </p>
                    <InputText
                        value={repayValue}
                        inputRef={repayInputRef}
                        onChange={
                            (e) => {
                                let inputVal = e.target.value;
                                if (inputVal && !numberRegex.test(inputVal)) return;
                                if (inputVal.length > 0) {
                                    inputVal = parseInt(inputVal);
                                }
                                setRepayValue(inputVal);
                            }
                        }
                        onBlurCallback={handleRepayInputBlur}
                        onFocusCallback={handleRepayInputFocus}
                        type="tel"
                    />
                    <div styleName={"styles.repayElement"} style={showIntegratedFormLabel ? { top: "0" } : null}>
                        {!repayInputFocused && showWeekPlaceholderAnimation && !emiValue ? (
                            <div  onClick={handleRepayInputFocus} >
                                <PlaceholderAnimation animationDetail={weekPlaceholderAnimationDetials}/>
                            </div>
                        ) : (
                            <div styleName={"styles.repayContent"} ref={hiddenValueRef}>
                                {repayValue}
                            </div>
                        )}
                        <p styleName={"styles.prefix"}>$</p>
                        <p styleName={"styles.perWeek"} ref={weekRef}>
                            /week
                        </p>
                    </div>
                </div>
                <DepositFilter
                    allFilters={allFilters}
                    showIntegratedFormLabel={showIntegratedFormLabel}
                    appliedFilters={appliedFilters}
                    onOptionSelection={onOptionSelection}/>
            </div>
            {showVehicleType ? (
                <BodyTypeFilter
                    allFilters={allFilters}
                    config={config}
                    selectedFilters={selectedFilters}
                    updateFilterOptionDataConnect={updateFilterOptionDataConnect}
                    onOptionSelection={onOptionSelection}
                    isModal={isModal}
                />
            ) : null}
            <div styleName={`${getButtonStyles()}`}>
                {isListingCountLoading ? (
                    <button disabled>
                        Loading cars for you
                        <div styleName={"styles.spinner"}>
                            <span styleName={"styles.loading"}>Loading...</span>
                        </div>
                    </button>

                ) : (
                    <Button text={getButtonText()} disabled={isButtonDisabled()} onClick={handleFinanceCentricRedirect} />
                )}
            </div>
        </div>
    );
};

DiscoverYourDreamCarSharedForm.propTypes = {
    allFilters: PropTypes.object,
    config: PropTypes.object,
    showWeekPlaceholderAnimation: PropTypes.bool,
    weekPlaceholderAnimationDetials: PropTypes.object,
    showWeekLeftAnimation: PropTypes.bool,
    showIntegratedFormLabel: PropTypes.bool,
    showCustomBudgetRange: PropTypes.bool,
    defaultVehicleTypeOpen: PropTypes.bool,
    fetchListingCountConnect: PropTypes.func,
    selectedFilters: PropTypes.array,
    updateFilterOptionDataConnect: PropTypes.func,
    selectedSuggestions: PropTypes.array,
    selectedCityCode: PropTypes.string,
    selectedCityName: PropTypes.string,
    metaContent: PropTypes.object,
    appliedFilters: PropTypes.array,
    reloadCarListConnect: PropTypes.func,
    onClose: PropTypes.func,
    isModal: PropTypes.bool,
    listingCount: PropTypes.number,
    isListingCountLoading: PropTypes.bool,
    updateSelectedSuggestionsConnect: PropTypes.func,
    updateFinanceCentricAnimationConnect: PropTypes.func,
    updateCustomBudgetDropdownOptionConnect: PropTypes.func,
    // isCustomBudgetSelected: PropTypes.bool,
    updateFinanceCentricListingConnect: PropTypes.func,
    urlOptions: PropTypes.object,
    totalListedCar: PropTypes.number
};

export default DiscoverYourDreamCarSharedForm;
