import { LOCAL_STORAGE_KEYS } from "../../constants/app-constants";
import { getItem, setItem } from "./storage-crud";

export const storeSectionUsedTimestamp = (section) => {
    const sectionUsedTimestamp = getItem(LOCAL_STORAGE_KEYS.sectionUsedTimestamp);
    if (!sectionUsedTimestamp || !(sectionUsedTimestamp || {}).hasOwnProperty(section)) {
        setItem({...sectionUsedTimestamp, [section]: Date.now()}, LOCAL_STORAGE_KEYS.sectionUsedTimestamp);
    }
};

export const getSectionUsedTimestamp = (section) => {
    const sectionUsedTimestamp = getItem(LOCAL_STORAGE_KEYS.sectionUsedTimestamp);
    return sectionUsedTimestamp[section] || null;
};
