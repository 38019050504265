import React, { useState } from "react";
import styles from "./styles.css";
import BACKARROW from "./images/back.svg";
import InputRadioButton from "../../shared/input-radio-button";
import InputTextarea from "../../shared/input-textarea";
import Button from "../../shared/button";
import PropTypes from "prop-types";

const ZeroDpFormOnTime = ({
    onSubmit,
    onBack,
    updateZeroDpExpiryTimeConnect
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [reason, setReason] = useState();
    const [notes, setNotes] = useState();
    const reasons = [
        {text: "Need more clarity on a few questions", showTextArea: false},
        {text: "Time was insufficient", showTextArea: false},
        {text: "Technical issues at my side", showTextArea: false},
        {text: "Don't have all documents handy", showTextArea: false},
        {text: "CARS24 team is not reachable", showTextArea: false},
        {text: "Other reasons", showTextArea: true}
    ];

    const onClickReason = (value) => {
        setReason(value);
    };

    const onChange = (e) => {
        const value = e.target.value;
        setNotes(value);
    };

    const handleNeedMoreTimeSubmit = async () => {
        try {
            setIsLoading(true);
            const payload = {
                reason: reason.text,
                remarks: notes
            };
            await updateZeroDpExpiryTimeConnect(payload);

            onSubmit();
        } catch (err) {
            setIsLoading(false);
            //err
        }
    };

    return (
        <div styleName={"styles.outer"}>
            <img src={BACKARROW} onClick={onBack} />
            <h2>Please specify why you couldn’t fill the form on time?</h2>
            {
                reasons.map((value, key) =>
                    (<div styleName={"styles.radioSection"} key={key}>
                        <InputRadioButton onClick={() => onClickReason(value)} id={key} value={value.text} />
                        <p>{value.text}</p>
                    </div>)
                )
            }
            {(reason || {}).showTextArea && <InputTextarea onChange={onChange}/>}
            <div styleName={"styles.buttonWrapper"}>
                <Button text="SUBMIT" onClick={handleNeedMoreTimeSubmit} isLoading={isLoading}  disabled={!reason} />
            </div>
        </div>
    );
};

ZeroDpFormOnTime.propTypes = {
    onSubmit: PropTypes.func,
    updateZeroDpExpiryTimeConnect: PropTypes.func,
    onBack: PropTypes.func
};

export default ZeroDpFormOnTime;
