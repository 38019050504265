/* eslint-disable complexity */
import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.css";
import SearchMeta from "../../shared/meta-constants/search-meta";
import Button from "../../shared/button";
import { useLocation } from "react-router";
import InputFilterSearch from "../input-filter-search";
import { appUrl } from "../../../constants/url-constants";
import { trackHomePageEvent } from "../../../handlers/mobile/event-tracker";
import { trackMobileCustomEventsAU } from "../../../tracking";
import {yieldToMain} from "../../../utils/helpers/yield-to-main";
import { AU_MOBILE_EVENTS } from "../../../tracking/au.mobile-events";
import capitalize from "../../../utils/helpers/capitalize";

import FestivalBannerImage from "./images/festival-banner-msite.webp";
import { Helmet } from "react-helmet";
import { DEFAULT_PATHNAME } from "../../../utils/filters-v2/constants";
import { PAGE_SOURCE } from "../../../constants/app-constants";
import bannerText from "./images/text.png";

// eslint-disable-next-line max-statements
const FestivalBanner = ({
    onClickShowCars,
    totalListedCar,
    scrollPosition,
    viewCarsButtonClicked,
    stickySearchThreshold
}) => {
    const location = useLocation();
    const sellCarURL = `${appUrl()}/sell-your-car?utm_source=home-banner&utm_medium=Msite&utm_campaign=Homepage-banner-redirection&utm_id=Main-CTA-Redirect`;
    const handleSearchClickCallback = async () => {
        await yieldToMain();

        trackMobileCustomEventsAU(AU_MOBILE_EVENTS.SEARCH_BAR_CLICKED, {
            eventLabel: capitalize(PAGE_SOURCE.HOME_PAGE),
            eventCategory: "Cars24_home_page"
        });
        trackHomePageEvent("search");
    };

    const handleSellCar = async() => {
        await yieldToMain();
        trackHomePageEvent("c2b_click");
    };
    return (
        <React.Fragment>
            <Helmet>
                <link rel="preload" href={FestivalBannerImage} as={"image"} type="image/jpg" />
            </Helmet>
            <div styleName={"styles.saleWrapper"}>
                <img styleName={"styles.saleBannerImage"} src={FestivalBannerImage} alt="Black Friday sale banner" />
                <div styleName={"styles.saleFormContainer"}>
                    <div styleName={"styles.textWrap"}>
                        <img src={bannerText} />
                    </div>
                    {scrollPosition >= stickySearchThreshold && <div styleName={`styles.saleSearchPlaceholder`} />}
                    <div styleName={` ${scrollPosition >= stickySearchThreshold ? "styles.searchContainerFixed" : "styles.searchContainer" } ${ scrollPosition < stickySearchThreshold ? "styles.saleContainer" : ""}`}>
                        <SearchMeta location={location} />
                        <InputFilterSearch isStickyHomeSearch={scrollPosition > stickySearchThreshold} handleSearchClickCallback={handleSearchClickCallback} showPlaceholderAnimate={true} souce={PAGE_SOURCE.HOME_PAGE} />
                    </div>
                    <a href={`${appUrl()}${DEFAULT_PATHNAME}`} styleName={"styles.anchorStyling"}>
                        <Button
                            isLoading={viewCarsButtonClicked}
                            styleName="styles.listingButtonblackFriday" text={`VIEW ALL ${totalListedCar} CARS`}
                            onClick={onClickShowCars} />
                    </a>
                    <p styleName={`styles.notLookingToBuy styles.saleNotLookingToBuyText`}>Not looking to buy? <a href={sellCarURL} onClick={handleSellCar}>Click here to sell your car</a></p>
                </div>
            </div>
        </React.Fragment>
    );
};

FestivalBanner.propTypes = {
    onClickShowCars: PropTypes.func,
    isLoggedIn: PropTypes.bool,
    totalListedCar: PropTypes.number,
    scrollPosition: PropTypes.number,
    viewCarsButtonClicked: PropTypes.bool,
    stickySearchThreshold: PropTypes.number
};

export default FestivalBanner;
