/* eslint-disable max-statements */
import React from "react";
import styles from "./styles.css";
import OverflowContainer from "../container-overflow";
import PropTypes from "prop-types";
import LazyImage from "../lazy-image";

import {  FILTER_ENTITIES } from "../../../constants/app-constants";
import getAppliedFilter from "../../../utils/filters-v2/get-applied-filter";

const BodyTypeFilter = ({
    allFilters,
    config,
    selectedFilters,
    onOptionSelection,
    isModal
}) => {
    const suggestions = allFilters?.bodyType?.suggestions || [];

    const handleBodyTypeSelect = (type, isSelected) => {
        const {key = "", displayText = ""} = type || {};
        onOptionSelection({
            filterKey: FILTER_ENTITIES.BODY_TYPE,
            optionKey: key,
            isSelected,
            displayText
        });
    };
    return (
        <div styleName={`${isModal ? "styles.vehicleContainerModal" : "styles.vehicleContainer"}`}>
            <p styleName={`styles.vehicleType ${isModal ? "styles.vehicleTypeModal" : ""}`}>What type of vehicle are you looking for ?</p>
            <div styleName={"styles.cardContainer"}>
                <OverflowContainer>
                    {suggestions.map((suggestion) => {
                        const isSelected = !!getAppliedFilter({filterKey: FILTER_ENTITIES.BODY_TYPE, optionKey: suggestion.key}, selectedFilters);
                        return (
                            <div
                                styleName={`styles.tabWrapper ${
                                    isSelected ? "styles.activeTab" : ""
                                }`}
                                key={suggestion.key}
                                onClick={() => handleBodyTypeSelect(suggestion, !isSelected)}
                            >
                                <LazyImage
                                    width="74"
                                    height="35"
                                    alt={suggestion.displayText}
                                    src={config.imageHost + suggestion.image}
                                />
                                <p styleName={"styles.tabName"}>{suggestion.displayText}</p>
                            </div>
                        );
                    })}
                </OverflowContainer>
            </div>
        </div>
    );
};

BodyTypeFilter.propTypes = {
    allFilters: PropTypes.object,
    config: PropTypes.object,
    selectedFilters: PropTypes.array,
    onOptionSelection: PropTypes.func,
    isModal: PropTypes.bool
};

export default BodyTypeFilter;
