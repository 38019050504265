import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import NotificationCard from "../../shared/notification-card/component";
import NotificationModalBottom from "../notification-modal-bottom/component";
// import ZeroIcon from "./images/zero-icon.png";

const NotificationBottom = ({
    img,
    heading = <p>Opted-in for <span>zero</span> deposit</p>,
    subHeader = "Car can be reserved at $0 now",
    onClickSubheader = () => {},
    onClose
}) => {
    return (
        <NotificationModalBottom close={onClose}>
            <NotificationCard
                img={img}
                heading={heading}
                subHeader={subHeader}
                onClickSubheader={onClickSubheader}
            />
        </NotificationModalBottom>
    );
};

NotificationBottom.propTypes = {
    img: PropTypes.object,
    heading: PropTypes.string,
    onClose: PropTypes.func,
    subHeader: PropTypes.string,
    onClickSubheader: PropTypes.func
};

export default NotificationBottom;
