/* eslint-disable complexity */
/* eslint-disable max-statements */
import React, { useState, useRef, useEffect } from "react";
import { NUMBER } from "../../../constants/app-constants";
// import DynamicFormFields from "../dynamic-form-fields";
import PropTypes from "prop-types";
import styles from "./styles.css";
import { useHistory } from "react-router-dom";
// import FormikForm from "../../shared/formik-form";
// import FormFooter from "../form-footer";

const FormLayoutV1 = ({
    formConfig,
    enableGoToNext = true,
    preApprovalEditMode = false,
    setFormLayoutProgress = () => { },
    updateFormData = () => { },
    handleOnNext = () => { },
    isNextLoading,
    children,
    handleOnBack,
    topComponent,
    topComponentProps,
    middleComponentProps,
    middleComponent,
    ...restProps
}) => {
    const history = useHistory();
    const formRef = useRef();
    const [errorOnSubmitCount, setFormErrorCount] = useState(0);
    const { disableResumeJourney } = formConfig || {};
    const [stageNo, setStageNo] = useState((disableResumeJourney || !restProps.goToStageNo) ? NUMBER.ONE : restProps.goToStageNo);
    const [pageNo, setPageNo] = useState((disableResumeJourney || !restProps.goToPageNo) ? NUMBER.ONE : restProps.goToPageNo);
    const stages = formConfig.stages.filter(Boolean) || [];
    const currentStage = stages[stageNo - 1] || {};
    const currentStageForms = currentStage.forms.filter(Boolean) || [];
    const currentFormData = currentStageForms[pageNo - 1] || {};
    const totalPages = currentStageForms.length;
    const totalStages = stages.length;

    const goToRoute = (newStageNo) => {
        const newStage = stages[newStageNo - 1] || {};
        if (newStage.route) {
            history.push(newStage.route);
        }
    };

    useEffect(() => {
        goToRoute(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setFormLayoutProgress({
            stageNo,
            pageNo
        });
    }, [pageNo, setFormLayoutProgress, stageNo]);

    const handlPillEdit = (step) => {
        const { setCompletedStages = () => { } } = topComponentProps || {};
        let { completedStages = [] } = topComponentProps || {};
        completedStages = completedStages || [];

        if (completedStages.indexOf(step) !== -1) {
            const updatedStages = completedStages.slice(0, completedStages.indexOf(step));
            setCompletedStages(updatedStages);
        }

    };

    const handlePillClick = (goToStepNo) => {
        const { onPillClickCustom = () => {} } = topComponentProps || {};
        handlPillEdit(goToStepNo);

        setPageNo(1);
        setStageNo(goToStepNo);
        goToRoute(goToStepNo);

        onPillClickCustom();
    };

    const onClickBack = (e) => {
        e.preventDefault();
        const jumpToPage = currentFormData.jumpToPrevPageNo && currentFormData.jumpToPrevPageNo();
        const jumpToStage = currentStage.jumpToPrevStageNo && currentStage.jumpToPrevStageNo();
        /* if not first page */
        if (pageNo !== 1) {
            setPageNo(jumpToPage || pageNo - 1);
        } else if (stageNo !== 1) {   /* if not first stage */
            const updatedStageNo = jumpToStage ? jumpToStage : stageNo - 1;
            goToRoute(updatedStageNo);
            setStageNo(updatedStageNo);
            /* go to last page logic for prev stage*/
            const stageData = stages[(jumpToStage || updatedStageNo) - 1] || [];
            const stageDataTotalPages = stageData.forms.filter(Boolean).length;
            //const stageDataTotalPages = stageData.forms.length;
            setPageNo(stageDataTotalPages);
            if (preApprovalEditMode) handlPillEdit(stageNo);
        }
        const isFirstPage = pageNo === 1 && stageNo === 1;
        handleOnBack(isFirstPage);
        if (currentFormData.onClickBack) {
            currentFormData.onClickBack();
        }
    };
    const onClickNext = () => {
        setFormErrorCount(errorOnSubmitCount + 1);
    };

    useEffect(() => {
        if (pageNo) {
            formRef.current.scrollTo(0, 0);
        }
    }, [pageNo]);

    const goToNext = () => {
        const jumpToPage = currentFormData.jumpToNextPageNo && currentFormData.jumpToNextPageNo();
        const jumpToStage = currentStage.jumpToNextStageNo && currentStage.jumpToNextStageNo();
        /* if not last page */
        if (pageNo < totalPages) {
            setPageNo(jumpToPage || pageNo + 1);
        } else if (stageNo < totalStages) { /* if not last stage */
            goToRoute(stageNo + 1);
            setPageNo(NUMBER.ONE);
            setStageNo(jumpToStage || stageNo + 1);
        }
        setFormErrorCount(0);
        if (currentFormData.onClickNext) {
            currentFormData.onClickNext();
        }
    };

    const onClickSubmit = async (values) => {
        const isLastPage = pageNo === totalPages && totalStages === stageNo;
        const pageStagesNum = {
            pageNo,
            totalPages,
            totalStages,
            stageNo
        };
        handleOnNext({
            values,
            isLastPage,
            currentStage,
            pageStagesNum
        }).then(() => {
            if (enableGoToNext) goToNext();
        }).catch(() => {
            //err
        });
    };

    const TopComponent = topComponent;
    const MiddleComponent = middleComponent;
    return (
        <React.Fragment>
            {topComponent && <TopComponent
                onPillClick={handlePillClick}
                steps={stages}
                currentStep={stageNo}
                completedPercentage={restProps.completedPercentage}
                maxCompletedSteps={restProps.goToStageNo}
                isError={errorOnSubmitCount && !isNextLoading}
                color={restProps.color}
                {...topComponentProps}
            />}
            <div styleName={"styles.formContainer"} ref={formRef} >
                <MiddleComponent
                    onClickSubmit={onClickSubmit}
                    updateFormData={updateFormData}
                    currentFormData={currentFormData}
                    errorOnSubmitCount={errorOnSubmitCount}
                    children={children}
                    onClickBack={onClickBack}
                    onClickNext={onClickNext}
                    isNextLoading={isNextLoading}
                    currentStage={currentStage}
                    {...middleComponentProps}
                    {...restProps}
                >
                    {children}
                </MiddleComponent>
            </div>
        </React.Fragment>
    );
};

FormLayoutV1.propTypes = {
    formConfig: PropTypes.object,
    enableGoToNext: PropTypes.bool,
    preApprovalEditMode: PropTypes.bool,
    handleOnNext: PropTypes.func,
    handleOnBack: PropTypes.func,
    setFormLayoutProgress: PropTypes.func,
    children: PropTypes.func,
    completedPercentage: PropTypes.number,
    isNextLoading: PropTypes.bool,
    updateFormData: PropTypes.func,
    topComponent: PropTypes.func,
    topComponentProps: PropTypes.object,
    middleComponentProps: PropTypes.object,
    middleComponent: PropTypes.func
};

export default FormLayoutV1;
