import React, {useState, useEffect, useCallback} from "react";

const useScrollPosition = () => {
    const [scrollPosition, setScrollPosition] = useState(0);

    const updatePosition = () => {
        setScrollPosition(window.pageYOffset);
    };
    const updatePositionCall =  useCallback(() => updatePosition(scrollPosition), [scrollPosition]);

    useEffect(() => {
        window.addEventListener("scroll", updatePositionCall);
        return () => window.removeEventListener("scroll", updatePositionCall);
    }, [scrollPosition, updatePositionCall]);

    return scrollPosition;
};

export default useScrollPosition;
