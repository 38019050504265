/* eslint-disable new-cap */
/* eslint-disable no-unused-vars */
import Types from "./types";
import { CheckoutService, CheckoutDrivaService, UserAuthService, C2bTradeService, ListingServiceAU, B2cConsumerFinanceService, CheckoutTradeInService, BffDataAU} from "../../../../service";
// import { AUTH_ADDRESS, X_VEHICLE_TYPE } from "../../../constants/api-constants";
// import { formatDeliveryAddressToDrivaAddress } from "../../../utils/helpers/format-address-object";
// import { getPreApprovedPayload } from "../../../utils/helpers/checkout-util";
import { ORDER_STATUS, VEHICLE_STATE } from "../../../constants/checkout-constants";
import { API_SOURCE, NUMBER, CITY_WISE_CONTENT, resetReducerKeys } from "../../../constants/app-constants.js";
import { formatMobileNumber } from "../../../utils/helpers/mobile-number-validator.js";
// import { getCarPrice } from "../../../utils/helpers/calculate-egc-dap";

const getCarPrice = () => {};

const AUTH_ADDRESS = {
    GOOGLE: "GOOGLE",
    EMAIL: "EMAIL",
    PHONE: "PHONE",
    FACEBOOK: "FACEBOOK",
    APPLE: "APPLE"
};

const X_VEHICLE_TYPE = {
    CAR: "CAR",
    car: "car"
};

const X_COUNTRY_CODE = {
    "AU": "AU"
};

const populateCheckoutPersonalData = (userData) => ({
    type: Types.POPULATE_CHECKOUT_PERSONAL_DATA,
    userData
});

const setUserPersonalDetails = (personalDetails) => ({
    type: Types.SET_USER_PERSONAL_DETAILS,
    personalDetails
});

const setFinanceData = (data) => {
    return {
        type: Types.SET_FINANCE_DATA,
        data
    };
};

const setLoanDetails = (data) => {
    return {
        type: Types.SET_LOAN_DETAILS,
        newData: data
    };
};

const setLoanType = (data) => {
    return {
        type: Types.SET_LOAN_TYPE,
        newData: data
    };
};

const setPersonalDetailSuccessMessage = ({ successMessage }) => {
    return {
        type: Types.SET_PERSONAL_DETAIL_MESSAGE_FORM_ACTIVE,
        successMessage
    };
};

const setLoanTenureValue = ({ loanTenure }) => {
    return {
        type: Types.SET_LOAN_TENURE_VALUE,
        loanTenure
    };
};

const setCreditScoreValue = ({ creditScoreValue }) => {
    return {
        type: Types.SET_CREDIT_SCORE_VALUE,
        creditScoreValue
    };
};

const setDownPaymentValue = ({ downPaymentValue }) => {
    return {
        type: Types.SET_DOWNPAYMENT_VALUE,
        downPaymentValue
    };
};

const setMonthlyEmiValue = ({ monthlyEmiValue }) => {
    return {
        type: Types.SET_MONTHLY_EMI_VALUE,
        monthlyEmiValue
    };
};

const setFinanceSelectedValue = ({ financeSelected }) => {
    return {
        type: Types.SET_FINANCE_SELECTED,
        financeSelected
    };
};

const setMinMaxDownPaymentValue = ({ minDownpaymentValue, maxDownpaymentValue }) => {
    return {
        type: Types.SET_MIN_MAX_DOWNPAYMENT_VALUE,
        minDownpaymentValue,
        maxDownpaymentValue
    };
};

const setMinMaxEmiValue = ({ minEmiValue, maxEmiValue }) => {
    return {
        type: Types.SET_MIN_MAX_EMI_VALUE,
        minEmiValue,
        maxEmiValue
    };
};

const getOrderDetailInit = (data) => ({
    type: Types.GET_ORDER_DETAIL_INIT,
    isLoading: data
});

const getOrderDetailSuccess = (data) => ({
    type: Types.GET_ORDER_DETAIL_SUCCESS,
    data
});

const getOrderDetailFailure = (error) => ({
    type: Types.GET_ORDER_DETAIL_FAILURE,
    error
});

const getOrderDetail = (appointmentId, withLoader = true) => (dispatch, getState) => {
    dispatch(getOrderDetailInit(withLoader));
    const { user: { secureToken } } = getState();
    return new Promise((resolve, reject) => {
        CheckoutService.getOrderSummary(appointmentId, secureToken)
            .then(response => {
                dispatch(getOrderDetailSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                dispatch(getOrderDetailFailure(error));
                reject(error);
            });
        // setTimeout(() => {
        //     resolve(ORDER_MOCK);
        // });
    });
};

const createOrderRequest = () => ({
    type: Types.CREATE_ORDER_REQUEST
});

const createOrderSuccess = (data) => ({
    type: Types.CREATE_ORDER_SUCCESS,
    data
});

const createOrderFailure = (error) => ({
    type: Types.CREATE_ORDER_FAILURE,
    error
});

const createOrder = (params) => (dispatch, getState) => {
    dispatch(createOrderRequest());
    const { user: { secureToken, userGeoData: {postalCode = ""} } } = getState();
    const customHeader = {pincode: postalCode};
    return new Promise((resolve, reject) => {
        CheckoutService.createOrder(params, secureToken, API_SOURCE.M_SITE, customHeader)
            .then(response => {
                dispatch(createOrderSuccess(response.data));
                resolve(response.data);
                // resolve(ORDER_MOCK_1);
                // dispatch(createOrderSuccess(ORDER_MOCK_1));
            })
            .catch(error => {
                dispatch(createOrderFailure(error));
                reject(error);
            });
        // setTimeout(() => {
        //     resolve(ORDER_MOCK);
        // });
    });
};

const setFinanceUpdatedData = ({
    creditScore,
    downPayment,
    emi,
    tenure,
    chargeList
}) => ({
    type: Types.SET_FINANCE_UPDATED_DATA,
    creditScore,
    downPayment,
    emi,
    tenure,
    chargeList
});

const fetchFinance = (vehiclePrice) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CheckoutService.fetchFinanceDetail(vehiclePrice)
            .then(response => {
                const { data } = response;
                dispatch(setFinanceData(data));
                resolve(response.data);
            })
            .catch(err => reject(err));
    });
};

const updateFinancePersonalDetail = (params, orderId, token) => () => {
    return new Promise((resolve, reject) => {
        CheckoutService.updateFinancePersonalDetail(params, orderId, token)
            .then(response => resolve(response.data))
            .catch(error => reject(error));
    });
};

const updateFinanceDetailRequest = () => ({
    type: Types.UPDATE_FINANCE_DETAILS
});
const updateFinanceDetailSuccess = (data) => ({
    type: Types.UPDATE_FINANCE_DETAILS_SUCCESS,
    data
});
const updateFinanceDetailFailure = (error) => ({
    type: Types.UPDATE_FINANCE_DETAILS_FAILURE,
    error
});

const updateFinanceDetail = (params = {}) => (dispatch, getState) => {
    dispatch(updateFinanceDetailRequest());
    const {
        user: { secureToken },
        checkout: {
            order,
            order: { orderId }
        }
    } = getState();
    return new Promise((resolve, reject) => {
        CheckoutService.updateFinanceDetail({ ...order, ...params}, orderId, secureToken)
            .then(response => {
                dispatch(updateFinanceDetailSuccess(response.data));
                resolve(response.data);
            })
            .catch(error => {
                dispatch(updateFinanceDetailFailure(error));
                reject(error);
            });
    });
};

const updateDeliveryModeSuccess = (data) => ({
    type: Types.UPDATE_DELIVERY_MODE_SUCCESS,
    data
});

const updateDeliveryModeFailure = (error) => ({
    type: Types.UPDATE_DELIVERY_MODE_FAILURE,
    error
});

const updateDeliveryMode = (order, orderId, token) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CheckoutService.updateDeliveryMode(order, orderId, token).then((response) => {
            dispatch(updateDeliveryModeSuccess(response.data));
            resolve();
        }).catch(error => {
            dispatch(updateDeliveryModeFailure(error));
            reject(error);
        });
    });
};

const initiateOrderPaymentRequest = () => ({
    type: Types.INITIATE_ORDER_PAYMENT_REQUEST
});

const initiateOrderPayment = (orderId, payload) => (dispatch, getState) => {
    dispatch(initiateOrderPaymentRequest());
    const {
        user: {
            secureToken
        }
    } = getState();
    return new Promise((resolve, reject) => {
        CheckoutService.initiateOrderPayment(orderId, payload, secureToken).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const getPaymentStatusRequest = () => ({
    type: Types.GET_PAYMENT_STATUS
});

const getPaymentStatus = (orderId) => (dispatch, getState) => {
    dispatch(getPaymentStatusRequest());
    const { user: { secureToken } } = getState();
    return new Promise((resolve, reject) => {
        CheckoutService.getPaymentStatus(orderId, secureToken).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const updateOrderBooking = (orderId, token) => () => {
    return new Promise((resolve, reject) => {
        CheckoutService.updateOrderBooking(orderId, token).then((data) => {
            resolve(data);
        });
    });
};

const setDeliveryMode = ({ deliveryMode }) => ({
    type: Types.SET_DELIVERY_MODE,
    deliveryMode
});

const setFinanceChargeList = ({ financeChargeList }) => ({
    type: Types.SET_FINANCE_CHARGE_LIST,
    financeChargeList
});

const setFinancingTab = ({ financingTab }) => ({
    type: Types.SET_FINANCING_TAB,
    financingTab
});

const setFinancingScreen = ({ activeScreen }) => ({
    type: Types.SET_FINANCE_SCREEN,
    activeScreen
});

const setFinancePersonalDetails = (data) => ({
    type: Types.SET_FINANCE_PERSONAL_DETAILS,
    data
});

const cancelOrder = (orderId, token) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CheckoutService.cancelOrder(orderId, token)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch(createOrderFailure(error));
                reject(error);
            });
    });
};

const softResetCheckoutData = () => dispatch => {
    dispatch({ type: Types.SOFT_RESET_CHECKOUT });
};

const resetCheckoutData = () => dispatch => {
    dispatch({ type: resetReducerKeys.RESET_CHECKOUT });
};

// const updateVisitedSteps = (step) => ({
//     type: Types.UPDATE_VISITED_STEPS,
//     step
// });

const setMaxStep = (maxStep) => ({type: Types.SET_MAX_STEP, maxStep});

const sendMobileOTP = () => (dispatch, getState) => {
    const {
        checkout: {
            personalDetails: { phone }
        }
    } = getState();

    return new Promise((resolve, reject) => {
        UserAuthService.sendOTP(phone, AUTH_ADDRESS.PHONE)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

// BYOF Actions start
const getByoLoanInit = () => ({
    type: Types.GET_BYO_LOAN_INIT
});

const getByoLoanSuccess = (data) => ({
    type: Types.GET_BYO_LOAN_SUCCESS,
    data
});

const getByoLoanFailure = (error) => ({
    type: Types.GET_BYO_LOAN_FAILURE,
    error
});

const getByoLoan = (params = {}) => (dispatch, getState) => {
    dispatch(getByoLoanInit());
    const {
        user: { secureToken },
        checkout: { order }
    } = getState();
    const {orderId} = order || {};
    return new Promise((resolve, reject) => {
        B2cConsumerFinanceService.getBYODetails(secureToken, orderId).then(response => {
            dispatch(getByoLoanSuccess(response.data));
            resolve(response.data);
        }).catch(error => {
            dispatch(getByoLoanFailure(error));
            reject(error);
        });
    });
};

const updateByoLoanInit = () => ({
    type: Types.UPDATE_BYO_LOAN_INIT
});

const updateByoLoanSuccess = (data) => ({
    type: Types.UPDATE_BYO_LOAN_SUCCESS,
    data
});

const updateByoLoanFailure = (error) => ({
    type: Types.UPDATE_BYO_LOAN_FAILURE,
    error
});

const updateByoLoan = (params = {}) => async (dispatch, getState) => {
    dispatch(updateByoLoanInit());
    const {
        user: { secureToken },
        checkout: { order }
    } = getState();
    const {orderId, appointmentId} = order || {};
    try {
        const response = await B2cConsumerFinanceService.updateBYODetails(secureToken, {...params, orderId, appointmentId});
        dispatch(updateByoLoanSuccess(response.data));
        Promise.resolve(response.data);
    } catch (error) {
        // eslint-disable-next-line no-magic-numbers
        if (error.errorCode === NUMBER.THOUSAND_THREE) {
            const response =  await B2cConsumerFinanceService.addBYODetails(secureToken, {...params, orderId, appointmentId});
            dispatch(updateByoLoanSuccess(response.data));
            Promise.resolve(response.data);
        } else {
            dispatch(updateByoLoanFailure(error));
            Promise.reject(error);
        }

    }
};

const getLoanOfferDetailsRequest = () => ({
    type: Types.GET_LOAN_OFFER_REQUEST
});

const getLoanOfferDetailsSuccess = (data) => ({
    type: Types.GET_LOAN_OFFER_SUCCESS,
    data
});

const getLoanOfferDetailsFailure = (error) => ({
    type: Types.GET_LOAN_OFFER_FAILURE,
    error
});

const getLoanOfferDetails = () =>
    async (dispatch, getState) => {
        dispatch(getLoanOfferDetailsRequest());
        const {
            user: { secureToken },
            checkout: {
                order: { orderId, status}
            }
        } = getState();
        try {
            let response;
            if (status === ORDER_STATUS.CREATED) {
                response = await CheckoutDrivaService.getLoanOfferDetails(orderId, secureToken);
            } else {
                response =  await CheckoutDrivaService.getCurrentOrderLoan(secureToken, orderId);
            }
            dispatch(getLoanOfferDetailsSuccess(response.data));
            return Promise.resolve(response.data);
        } catch (error) {
            dispatch(getLoanOfferDetailsFailure(error));
            return  Promise.reject(error);
        }
    };

const createLoanOfferRequest = () => ({
    type: Types.CREATE_LOAN_OFFER_REQUEST
});

const createLoanOfferSuccess = (data) => ({
    type: Types.CREATE_LOAN_OFFER_SUCCESS,
    data
});

const createLoanOfferFailure = (error) => ({
    type: Types.CREATE_LOAN_OFFER_FAILURE,
    error
});

const createLoanOffer = (params = { submitted: false }) =>
    (dispatch, getState) => {
        dispatch(createLoanOfferRequest());
        const {
            user: {
                secureToken,
                firstName,
                middleName,
                lastName,
                mobile,
                email = ""
            },
            checkout: {
                financeData,
                egcData,
                tradeinOpted,
                tradeInData: {
                    offeredPrice,
                    expired,
                    accepted
                },
                order: {
                    orderId,
                    deliveryAddress
                }
            },
            carDetails: {
                content: {
                    appointmentId,
                    make,
                    model,
                    year,
                    price
                }
            }
        } = getState();
        // const address = formatDeliveryAddressToDrivaAddress(deliveryAddress);
        const { totalDap, tradeInPrice } = getCarPrice({ egcData, offeredPrice }) || {};
        const financeDataUpdated = {
            ...financeData,
            submitted: true,
            driveAwayPrice: (tradeinOpted && !expired && accepted) ? Math.round(tradeInPrice.value) : Math.round(totalDap.value || price),
            user: {
                ...financeData.user,
                email,
                firstName,
                middleName,
                lastName,
                mobile,
                address: {
                    ...financeData.user.address
                    // ...address
                }
            },
            extra: {
                ...financeData.extra,
                vehicleMake: make,
                vehicleModel: model,
                vehicleState: "used",
                vehicleYear: year
            }
        };
        const payload = { ...financeDataUpdated, ...params, appointmentId };
        return new Promise((resolve, reject) => {
            CheckoutDrivaService.createLoanOffer(payload, orderId, secureToken)
                .then((response) => {
                    dispatch(createLoanOfferSuccess(response.data));
                    resolve(response.data);
                })
                .catch((error) => {
                    dispatch(createLoanOfferFailure(error));
                    reject(error);
                });
        });
    };

const updateLoanOfferRequest = () => ({
    type: Types.UPDATE_LOAN_OFFER_REQUEST
});

const updateLoanOfferSuccess = (data) => {
    return {
        type: Types.UPDATE_LOAN_OFFER_SUCCESS,
        data
    };
};

const updateLoanOfferFailure = (error) => ({
    type: Types.UPDATE_LOAN_OFFER_FAILURE,
    error
});

const updateLoanOffer = (params = { submitted: false }) =>
    (dispatch, getState) => {
        dispatch(updateLoanOfferRequest());
        const {
            user: { secureToken, email,
                firstName,
                middleName,
                lastName,
                mobile },
            checkout: {
                financeData,
                egcData,
                tradeinOpted,
                tradeInData: {
                    offeredPrice,
                    expired,
                    accepted
                },
                order: { orderId }
            },
            carDetails: {
                content: {
                    appointmentId,
                    make,
                    model,
                    year,
                    price
                }
            }
        } = getState();
        const { totalDap, tradeInPrice } = getCarPrice({ egcData, offeredPrice }) || {};
        const financeDataUpdated = {
            ...financeData,
            driveAwayPrice: (tradeinOpted && !expired && accepted) ? Math.round(tradeInPrice.value) : Math.round(totalDap.value || price),
            user: {
                ...financeData.user,
                email,
                mobile
            },
            extra: {
                ...financeData.extra,
                vehicleMake: make,
                vehicleModel: model,
                vehicleState: VEHICLE_STATE.USED,
                vehicleYear: year
            }
        };
        const payload = { ...financeDataUpdated, appointmentId, ...params };
        return new Promise((resolve, reject) => {
            CheckoutDrivaService.updateLoanOffer(payload, orderId, secureToken)
                .then((response) => {
                    dispatch(updateLoanOfferSuccess(response.data));
                    resolve(response.data);
                })
                .catch((error) => {
                    dispatch(updateLoanOfferFailure(error));
                    reject(error);
                });
        });
    };

const setRecalculateTriggered = (recalculateTriggered) => ({
    type: Types.SET_RECALCULATE_TRIGGERED,
    recalculateTriggered
});

const requestCallback = (params = {}) => (dispatch, getState) => {
    const {
        user: { secureToken }
    } = getState();
    return new Promise((resolve, reject) => {
        C2bTradeService.c2bRequestCallBack(secureToken, params)
            .then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error);
            });
    });
};

const fetchEgcPriceInit = () => ({
    type: Types.FETCH_EGC_PRICE
});

const fetchEgcPriceSuccess = (data) => ({
    type: Types.FETCH_EGC_PRICE_SUCCESS,
    data
});

const fetchEgcPriceFailure = (error) => ({
    type: Types.FETCH_EGC_PRICE_FAILURE,
    error
});

const fetchEgcPrice = (carId, state) => (dispatch) => {
    dispatch(fetchEgcPriceInit());
    const postalCode = state ? `state=${state}` : "";
    return new Promise((resolve, reject) => {
        ListingServiceAU.fetchCarPricing(carId, postalCode)
            .then(response => {
                dispatch(fetchEgcPriceSuccess(response.data));
                resolve(response.data);
            })
            .catch(error => {
                dispatch(fetchEgcPriceFailure([]));
                reject([]);
            });
    });
};

//Trade-in
const getTradeInDetailsRequest = () => ({
    type: Types.GET_TRADE_IN_REQUEST
});

const getTradeInDetailsSuccess = (data) => ({
    type: Types.GET_TRADE_IN_SUCCESS,
    data
});

const getTradeInDetailsFailure = (error) => ({
    type: Types.GET_TRADE_IN_FAILURE,
    error
});

const getSellOrderDetails = (params = {}, token) => {
    return async (dispatch, getState) => {
        const {user: { secureToken }} = getState();
        try {
            const  response = await C2bTradeService.getSellOrderDetails(secureToken || token, params);
            const {data} = response.data || {};
            return Promise.resolve(data);
        } catch (error) {
            return Promise.reject(error);
        }
    };
};

const getOrderDetailsRequest = () => ({
    type: Types.GET_ORDER_REQUEST
});

const getOrderDetailsSuccess = (data, evaluatedData) => ({
    type: Types.GET_ORDER_SUCCESS,
    data,
    evaluatedData
});

const getOrderDetailsFailure = (error) => ({
    type: Types.GET_ORDER_FAILURE,
    error
});
const getOrderDetails = (orderId, token) =>
    (dispatch, getState) => {
        dispatch(getOrderDetailsRequest());
        const {
            user: {
                secureToken
            }
        } = getState();
        return new Promise((resolve, reject) => {
            C2bTradeService.getOrderDetails(token || secureToken, orderId)
                .then((response) => {
                    const {data, status, cars24Email, priceComparison, valuationTags} = response.data || {};
                    if (status === NUMBER.ONE) {
                        const evaluatedData = {cars24Email, priceComparison, valuationTags};
                        dispatch(getOrderDetailsSuccess(data, evaluatedData));
                    }
                    resolve(data);
                })
                .catch((error) => {
                    dispatch(getOrderDetailsFailure(error));
                    reject(error);
                });
        });
    };

const getColdLeadRequest = () => ({
    type: Types.GET_COLD_LEAD_REQUEST
});

const getColdLeadSuccess = (data, evaluatedData) => ({
    type: Types.GET_COLD_LEAD_SUCCESS,
    data,
    evaluatedData
});

const getColdLeadFailure = (error) => ({
    type: Types.GET_COLD_LEAD_FAILURE,
    error
});

const getColdLeadDetails = (orderId) =>
    (dispatch) => {
        dispatch(getColdLeadRequest());
        return new Promise((resolve, reject) => {
            C2bTradeService.getColdLeadDetails(orderId)
                .then((response) => {
                    const {data, status, cars24Email, priceComparison, valuationTags} = response.data || {};
                    if (status === NUMBER.ONE) {
                        const evaluatedData = {cars24Email, priceComparison, valuationTags};
                        dispatch(getColdLeadSuccess(data, evaluatedData));
                    }
                    resolve(data);
                })
                .catch((error) => {
                    dispatch(getColdLeadFailure(error));
                    reject(error);
                });
        });
    };

const getTradeInDetails = (params = {}) =>
    async (dispatch, getState) => {
        dispatch(getTradeInDetailsRequest());
        const {user: { secureToken }} = getState();
        try {
            const {isBooked, token} = params;
            let response;
            if (isBooked) {
                response = await C2bTradeService.getBookedC2bTrade(token || secureToken);
            } else {
                response = await C2bTradeService.getC2bTrade(token || secureToken);
            }
            const {data} = response.data || {};
            dispatch(getTradeInDetailsSuccess(data || {}));
            return Promise.resolve(data);
        } catch (error) {
            dispatch(getTradeInDetailsFailure(error));
            return Promise.reject(error);
        }
    };

const createTradeInRequest = () => ({
    type: Types.CREATE_TRADE_IN_REQUEST
});

const createTradeInSuccess = (data) => ({
    type: Types.CREATE_TRADE_IN_SUCCESS,
    data
});

const createTradeInFailure = (error) => ({
    type: Types.CREATE_TRADE_IN_FAILURE,
    error
});

const createTradeIn = (params = { submitted: false }) =>
    (dispatch, getState) => {
        dispatch(createTradeInRequest());
        const {
            user: {
                secureToken,
                email,
                mobile
            }
        } = getState();
        const payload = {
            userEmail: email,
            mobileNo: formatMobileNumber(mobile),
            // currency,
            ...params,
            vehicleType: X_VEHICLE_TYPE.CAR
        };
        return new Promise((resolve, reject) => {
            C2bTradeService.createC2bTrade(secureToken, payload)
                .then((response) => {
                    const {data} = response.data || {};
                    if (response.data.status === NUMBER.TWO_HUNDRED) {
                        dispatch(createTradeInSuccess({...data, status: response.data.status}));
                    }
                    resolve({...data, status: response.data.status});
                })
                .catch((error) => {
                    dispatch(createTradeInFailure(error));
                    reject(error);
                });
        });
    };

const createTradeInRequestNoKbb = () => ({
    type: Types.UPDATE_CAR_VARIANT
});

const createTradeInSuccessNoKbb = (data) => ({
    type: Types.UPDATE_CAR_VARIANT_SUCCESS,
    data
});

const createTradeInFailureNoKbb = (error) => ({
    type: Types. UPDATE_CAR_VARIANT_FAILURE,
    error
});

const createTradeInNoKbb = (params = { submitted: false }) =>
    (dispatch, getState) => {
        dispatch(createTradeInRequestNoKbb());
        const {
            user: {
                secureToken,
                email,
                mobile
            }
        } = getState();
        const payload = {
            userEmail: email,
            mobileNo: mobile,
            // currency,
            ...params,
            vehicleType: X_VEHICLE_TYPE.CAR
        };
        return new Promise((resolve, reject) => {
            C2bTradeService.createC2bTradeNoKbb(secureToken, payload)
                .then((response) => {
                    const {data: {responseStatus, data}} = response || {};
                    if (responseStatus.status !== NUMBER.ONE) {
                        dispatch(createTradeInFailureNoKbb({...data, status: responseStatus.status}));
                        reject({...data, status: responseStatus.status});
                    } else {
                        dispatch(createTradeInSuccessNoKbb({...data, status: responseStatus.status}));
                        resolve({...data, status: responseStatus.status});
                    }
                })
                .catch((error) => {
                    dispatch(createTradeInFailureNoKbb(error));
                    reject(error);
                });
        });
    };

const updateTradeInRequest = () => ({
    type: Types.UPDATE_TRADE_IN_REQUEST
});

const updateTradeInSuccess = (data, evaluatedData) => {
    return {
        type: Types.UPDATE_TRADE_IN_SUCCESS,
        data,
        evaluatedData
    };
};

const updateTradeInFailure = (error) => ({
    type: Types.UPDATE_TRADE_IN_FAILURE,
    error
});

const updateTradeIn = (orderData) =>
    (dispatch, getState) => {
        dispatch(updateTradeInRequest());
        const {
            user: {
                secureToken
            }
        } = getState();
        return new Promise((resolve, reject) => {
            C2bTradeService.updateC2bTrade(secureToken, orderData, orderData.c2bOrderId)
                .then((response) => {
                    if (response.data && (response.data.status === NUMBER.TWO_HUNDRED || response.data.status === NUMBER.ONE)) {
                        const { data, cars24Email, priceComparison, valuationTags } = response.data || {};
                        const evaluatedData = {cars24Email, priceComparison, valuationTags};
                        dispatch(updateTradeInSuccess(data, evaluatedData));
                    } else {
                        const error = response.data && response.data.errorMessage;
                        dispatch(updateTradeInFailure(error));
                    }
                    resolve(response.data);
                })
                .catch((error) => {
                    dispatch(updateTradeInFailure(error));
                    reject(error);
                });
        });
    };

const submitTradeIn = (orderData) =>
    (dispatch, getState) => {
        dispatch(updateTradeInRequest());
        const {
            user: {
                secureToken
            }
        } = getState();

        return new Promise((resolve, reject) => {
            BffDataAU.submitC2bTrade(secureToken, orderData, orderData.c2bOrderId)
                .then((response) => {
                    if (response.data && (response.data.status === NUMBER.TWO_HUNDRED || response.data.status === NUMBER.ONE)) {
                        const { data, cars24Email, priceComparison, valuationTags } = response.data || {};
                        const evaluatedData = {cars24Email, priceComparison, valuationTags};
                        dispatch(updateTradeInSuccess(data, evaluatedData));
                    } else {
                        const error = response.data && response.data.errorMessage;
                        dispatch(updateTradeInFailure(error));
                    }
                    resolve(response.data);
                })
                .catch((error) => {
                    dispatch(updateTradeInFailure(error));
                    reject(error);
                });
        });
    };

const acceptTradeIn = (orderID, payload) =>
    (dispatch, getState) => {
        dispatch(updateTradeInRequest());
        const {
            user: {
                secureToken
            }
        } = getState();
        return new Promise((resolve, reject) => {
            C2bTradeService.acceptC2bTrade(secureToken, orderID, payload)
                .then((response) => {
                    const { data } = response.data || {};
                    dispatch(updateTradeInSuccess(data));
                    resolve(response.data);
                })
                .catch((error) => {
                    dispatch(updateTradeInFailure(error));
                    reject(error);
                });
        });
    };

const updateBasicDetails = (orderData) =>
    (dispatch, getState) => {
        dispatch(updateTradeInRequest());
        const {
            user: {
                secureToken
            }
        } = getState();
        return new Promise((resolve, reject) => {
            C2bTradeService.updateBasicDetails(secureToken, orderData, orderData.c2bOrderId)
                .then((response) => {
                    const { data } = response.data || {};
                    dispatch(updateTradeInSuccess(data));
                    resolve(response.data);
                })
                .catch((error) => {
                    dispatch(updateTradeInFailure(error));
                    reject(error);
                });
        });
    };

const customerOptedTrade = (tradeinOpted) => ({
    type: Types.SET_TRADE_OPTION,
    tradeinOpted
});

const fetchTradeInStateRequest = () => ({
    type: Types.GET_TRADE_IN_STATE_REQUEST
});

const fetchTradeInStateSuccess = (tradeInStates) => ({
    type: Types.GET_TRADE_IN_STATE_SUCCESS,
    tradeInStates
});

const fetchTradeInStateFailure = (error) => ({
    type: Types.GET_TRADE_IN_STATE_FAILURE,
    error
});

const getTradeInState = () => async (dispatch, getState) => {
    dispatch(fetchTradeInStateRequest());
    const { user: { secureToken }} = getState();
    try {
        const response = await CheckoutTradeInService.getTradeInStates(secureToken);
        const stateValue = response.data.reduce((acc, data) => {
            acc.push({ ...data, label: data.stateName, value: data.stateName});
            return acc;
        }, []);
        dispatch(fetchTradeInStateSuccess(stateValue));
        return response;
    } catch (error) {
        dispatch(fetchTradeInStateFailure(error));
        return error;
    }
};

const saveCheckoutSourcePathname = (data) => ({
    type: Types.SAVE_CHECKOUT_SOURCE_PATHNAME,
    data
});

const setSplitPayment = (data = {}) => ({
    type: Types.SET_SPLIT_PAYMENT,
    data
});

const updateSplitPaymentRequest = () => ({
    type: Types.UPDATE_SPLIT_PAYMENT
});

const updateSplitPaymentSuccess = (data) => ({
    type: Types.UPDATE_SPLIT_PAYMENT_SUCCESS,
    data
});

const updateSplitPaymentFailure = (error) => ({
    type: Types.UPDATE_SPLIT_PAYMENT_FAILURE,
    error
});

const updateSplitPayment = (params = {}) => (dispatch, getState) => {
    dispatch(updateSplitPaymentRequest());
    const {
        user: { secureToken },
        checkout: {
            order,
            order: { orderId }
        }
    } = getState();
    return new Promise((resolve, reject) => {
        CheckoutService.updateSplitPayment({...params}, orderId, secureToken)
            .then(response => {
                dispatch(updateSplitPaymentSuccess(response.data));
                dispatch(getOrderDetailSuccess(response.data));
                resolve(response.data);
            })
            .catch(error => {
                dispatch(updateSplitPaymentFailure(error));
                reject(error);
            });
    });
};

const setSplitPaymentUserState = (data = {}) => ({
    type: Types.SET_SPLIT_PAYMENT_USER_STATE,
    data
});

const getPaymentHistoryInit  = (data) => ({
    type: Types.GET_PAYMENT_HISTORY_INIT,
    data
});

const getPaymentHistorySuccess = (data) => ({
    type: Types.GET_PAYMENT_HISTORY_SUCCESS,
    data
});

const getPaymentHistoryFailure = (error) => ({
    type: Types.GET_PAYMENT_HISTORY_FAILURE,
    error
});

const getPaymentHistory = () => (dispatch, getState) => {
    dispatch(getPaymentHistoryInit());
    const {
        user: { secureToken },
        checkout: {
            order: { orderId }
        }
    } = getState();
    return new Promise((resolve, reject) => {
        CheckoutService.getPaymentHistory(orderId, secureToken)
            .then(response => {
                dispatch(getPaymentHistorySuccess(response.data));
                resolve(response.data);
            })
            .catch(error => {
                dispatch(getPaymentHistoryFailure(error));
                reject(error);
            });
    });
};

const saveTradeInLocal  = (data) => ({
    type: Types.SAVE_TRADE_IN_LOCALSTATE,
    data
});

const getDeliveryInfoSuccess = (data, pincode) => ({
    type: Types.GET_DELIVERY_INFO_SUCCESS,
    data,
    pincode
});

const getDeliveryInfoFailure = (error, pincode) => ({
    type: Types.GET_DELIVERY_INFO_FAILURE,
    error,
    pincode
});

const setDeliveryInfoFetch = (data) => ({
    type: Types.GET_DELIVERY_INFO_INIT,
    deliveryInfoFetched: data
});

const getDeliveryInfo = (appointmentId, pincode, origincity) => (dispatch, getState) => {
    const {carDetails = {}, user: { secureToken }} = getState();
    const {cityCode} = carDetails.content || {};
    dispatch(setDeliveryInfoFetch(false));
    return new Promise((resolve, reject) => {
        BffDataAU.getDeliveryAvailability(secureToken, appointmentId, pincode, origincity || cityCode, false).then((response) => {
            dispatch(getDeliveryInfoSuccess(response.data, pincode));
            resolve(response.data);
        }).catch((error) => {
            dispatch(getDeliveryInfoFailure(error, pincode));
            reject(error);
        });
    });
};

const getC2bDelivery = (pincode, origincity) => (dispatch, getState) => {
    const {carDetails = {}} = getState();
    const {cityCode} = carDetails.content || {};
    dispatch(setDeliveryInfoFetch(false));
    return new Promise((resolve, reject) => {
        ListingServiceAU.getC2bAvailability(pincode, origincity || cityCode, false).then((response) => {
            dispatch(getDeliveryInfoSuccess(response.data, pincode));
            resolve(response.data);
        }).catch((error) => {
            dispatch(getDeliveryInfoFailure(error, pincode));
            reject(error);
        });
    });
};

const resetDeliveryInfo = () => dispatch => {
    dispatch({ type: resetReducerKeys.RESET_DELIVERY_INFO });
};

const setUserUpdating = (data) => dispatch => {
    dispatch({ type: Types.SET_USER_UPDATING, data });
};

const handleRejectModal = (modalType) => dispatch => dispatch({type: Types.HANDLE_REJECT_MODAL, modalType});
const closeHandleRejectModal = () => dispatch => dispatch({type: Types. CLOSE_REJECT_MODAL});

const getTradeVehicleDetailsRequest = () => ({
    type: Types.GET_TRADE_IN_VEHICLE_REQUEST
});

const getTradeVehicleDetailsSuccess = ({data}) => ({
    type: Types.GET_TRADE_IN_VEHICLE_SUCCESS,
    data
});

const getTradeVehicleDetailsFailure = (error) => ({
    type: Types.GET_TRADE_IN_VEHICLE_FAILURE,
    error
});

const getTradeVehicleDetails = (params = {}, token) =>
    async (dispatch, getState) => {
        dispatch(getTradeVehicleDetailsRequest());
        const { user: { secureToken }} = getState();
        try {
            const response =  await C2bTradeService.getC2BTradeInVehicleDetails(token || secureToken, params);
            dispatch(getTradeVehicleDetailsSuccess(response.data));
            return Promise.resolve(response.data);
        } catch (error) {
            dispatch(getTradeVehicleDetailsFailure(error));
            return Promise.reject(error);
        }
    };

const getSeoContentRequest = () => ({
    type: Types. FETCH_SEO_CONTENT
});

const getSeoContentSuccess = (data) => ({
    type: Types.FETCH_SEO_CONTENT_SUCCESS,
    data
});

const getSeoContentFailure = (error) => ({
    type: Types.FETCH_SEO_CONTENT_FAILURE,
    error
});

const getSeoContent = (params = {}) =>
    async (dispatch, getState) => {
        dispatch(getSeoContentRequest());
        try {
            const response =  await C2bTradeService.getSeoContent(params);
            const { city }  =  response.data.seoMeta;
            const seoSectionsData =  CITY_WISE_CONTENT[city] || {};
            const responseObj = {
                ...response.data,
                seoSectionsData
            };
            dispatch(getSeoContentSuccess(responseObj));
            return Promise.resolve(response.data.seoMeta);
        } catch (error) {
            dispatch(getSeoContentFailure(error));
            return Promise.reject(error);
        }
    };

const saveSelectedCity = (data) => ({
    type: Types.SAVE_SELECTED_CITY,
    data
});

// const saveTradeInRegoandVin = (data) => ({
//     type: Types.SAVE_CAR_REGNO_VIN,
//     data
// });

const saveTradeInCarMakeModelId = (data) => ({
    type: Types.SAVE_TRADE_IN_ID,
    data
});

const resetTradeInDetails = () => dispatch => dispatch({ type: Types.RESET_CAR_TRADE_IN_DETAILS });

const fetchTradeInCarDetails = () => ({
    type: Types.GET_TRADE_IN_CAR_DETAILS
});

const fetchTradeInCarDetailsSuccess = (tradeInCarDetails) => ({
    type: Types.GET_TRADE_IN_CAR_DETAILS_SUCCESS,
    tradeInCarDetails
});

const fetchTradeInCarDetailsFailure = (error) => ({
    type: Types.GET_TRADE_IN_CAR_DETAILS_FAILURE,
    error
});
const getTradeInCarMakeModel = () => async (dispatch, getState) => {
    dispatch(fetchTradeInCarDetails());
    const { user: { secureToken }} = getState();
    try {
        const response = await C2bTradeService.getCarMakeModel(secureToken);
        if (response.data && response.data.data) {
            dispatch(fetchTradeInCarDetailsSuccess(response.data.data));
        } else {
            dispatch(fetchTradeInCarDetailsFailure("makeModelError"));
        }

        return response;
    } catch (error) {
        dispatch(fetchTradeInCarDetailsFailure("makeModelError"));
        return error;
    }
};

const fetchTradeInCarVariants = () => ({
    type: Types.GET_TRADE_IN_CAR_VARIANT
});

const fetchTradeInCarVariantsSuccess = (tradeInCarVariants) => ({
    type: Types.GET_TRADE_IN_CAR_VARIANT_SUCCESS,
    tradeInCarVariants
});

const fetchTradeInCarVariantsFailure = (error) => ({
    type: Types.GET_TRADE_IN_CAR_VARIANT_FAILURE,
    error
});

const getTradeInCarVariants = (params = {}) => async (dispatch, getState) => {
    dispatch(fetchTradeInCarVariants());
    const { user: { secureToken }} = getState();
    try {
        const response = await C2bTradeService.getVariants(secureToken, params);
        if (response.data && response.data.data && response.data.data.length) {
            dispatch(fetchTradeInCarVariantsSuccess(response.data.data));
        } else {
            dispatch(fetchTradeInCarVariantsFailure("makeModelError"));
        }

        return response.data.data;
    } catch (error) {
        dispatch(fetchTradeInCarVariantsFailure("makeModelError"));
        return error;
    }
};

// make model flow actions

const setMakeFlowMaxStep = (maxStep) => ({type: Types.SET__MAKE_MODEL_MAX_STEP, maxStep});
const setMakeFlowCurrentStep = (currentStep) => ({type: Types.SET__MAKE_MODEL_CURRENT_STEP, currentStep});
const setMakeFlowHardRejection = (rejectionReasonInfo) => ({type: Types.SET__MAKE_MODEL_HARD_REJECTION, rejectionReasonInfo});
const setMakeFlowRecheduleflag = (rescheduleFlag) => ({type: Types.SET__MAKE_MODEL_RECHEDULE_FLAG, rescheduleFlag});
const setMakeModelYear = (makeModelYear) => ({type: Types.SET_MAKE_MODEL_YEAR, makeModelYear});
const setMmvyUserInputs = (mmvyUserInputs) => ({type: Types.SET_MMVY_USER_INPUTS, mmvyUserInputs});

const updateMakeModelFlowRequest = () => ({
    type: Types.UPDATE_MAKE_MODEL_REQUEST
});

const updateMakeModelFlow = (orderData, orderId) =>
    (dispatch, getState) => {
        dispatch(updateMakeModelFlowRequest());
        const {
            user: {
                secureToken
            }
        } = getState();

        return new Promise((resolve, reject) => {
            C2bTradeService.updateMakeModelFlow(secureToken, orderData, orderId)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

const updateMakeModelFlowForMarketplace = (orderData) =>
    (dispatch, getState) => {
        dispatch(updateMakeModelFlowRequest());
        const {
            user: {
                secureToken
            }
        } = getState();

        return new Promise((resolve, reject) => {
            C2bTradeService.updateMakeModelFlowForMarketplace(secureToken, orderData)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

const openGuestLogin = (guestPopupType, isMobileMandatory) => ({type: Types.OPEN_GUEST_LOGIN, guestPopupType, isMobileMandatory});
const closeGuestLogin = () => ({type: Types.CLOSE_GUEST_LOGIN });

const setInputRegoNumber = (data) => ({
    type: Types.REGO_NUMBER_UPDATE,
    data
});

const setRedirectionData = (data) => ({
    type: Types.SET_REDIRECTION_DATA,
    data
});

const fetchCmsConfigInit = () => ({
    type: Types.FETCH_CMS_CONFIG_INIT
});

const fetchCmsConfigSuccess = (data) => ({
    type: Types.FETCH_CMS_CONFIG_SUCCESS,
    data
});

const fetchCmsConfigFailure = (error) => ({
    type: Types.FETCH_CMS_CONFIG_FAILURE,
    error
});

const fetchCmsConfig = (params = {}) => async (dispatch, getState) => {
    dispatch(fetchCmsConfigInit());
    try {
        const response = await BffDataAU.getCmsContent(params);
        dispatch(fetchCmsConfigSuccess(response.data));
    } catch (error) {
        dispatch(fetchCmsConfigFailure(error));
    }
};

export {
    populateCheckoutPersonalData,
    setUserPersonalDetails,
    setPersonalDetailSuccessMessage,
    fetchFinance,
    setFinanceData,
    setLoanDetails,
    setLoanType,
    setLoanTenureValue,
    setCreditScoreValue,
    setDownPaymentValue,
    setMonthlyEmiValue,
    getOrderDetail,
    updateFinancePersonalDetail,
    setDeliveryMode,
    setFinancingTab,
    setMinMaxDownPaymentValue,
    setMinMaxEmiValue,
    setFinanceSelectedValue,
    setFinancePersonalDetails,
    createOrder,
    updateFinanceDetail,
    updateDeliveryMode,
    updateOrderBooking,
    initiateOrderPayment,
    setFinanceUpdatedData,
    setFinancingScreen,
    cancelOrder,
    setFinanceChargeList,
    softResetCheckoutData,
    resetCheckoutData,
    getPaymentStatus,
    setMaxStep,
    sendMobileOTP,
    updateByoLoan,
    // updatePreApprovedLoan,
    getLoanOfferDetails,
    createLoanOffer,
    updateLoanOffer,
    // updateVisitedSteps
    setRecalculateTriggered,
    requestCallback,
    fetchEgcPrice,
    getTradeInDetails,
    createTradeIn,
    updateTradeIn,
    acceptTradeIn,
    updateBasicDetails,
    customerOptedTrade,
    getTradeInState,
    saveCheckoutSourcePathname,
    getByoLoan,
    setSplitPayment,
    updateSplitPayment,
    setSplitPaymentUserState,
    getPaymentHistory,
    getPaymentHistoryInit,
    saveTradeInLocal,
    getDeliveryInfo,
    resetDeliveryInfo,
    setUserUpdating,
    handleRejectModal,
    getC2bDelivery,
    getTradeVehicleDetails,
    closeHandleRejectModal,
    getSeoContent,
    saveSelectedCity,
    getSellOrderDetails,
    saveTradeInCarMakeModelId,
    // saveTradeInRegoandVin,
    resetTradeInDetails,
    getTradeInCarMakeModel,
    getTradeInCarVariants,
    createTradeInNoKbb,
    getOrderDetails,
    getSeoContentSuccess,
    submitTradeIn,

    // make model flow actions
    setMakeFlowMaxStep,
    setMakeFlowCurrentStep,
    setMakeFlowHardRejection,
    setMakeFlowRecheduleflag,
    setMakeModelYear,
    updateMakeModelFlow,
    setMmvyUserInputs,
    openGuestLogin,
    closeGuestLogin,
    getColdLeadDetails,
    setInputRegoNumber,
    setRedirectionData,
    updateMakeModelFlowForMarketplace,
    fetchCmsConfig
};
