import React from "react";
import styles from "./styles.css";
import Modal from "../../shared/modal";
import PropTypes from "prop-types";

const NotificationModalBottom = ({
    close,
    children
}) => {
    return (
        <Modal isOpen={true} close={close}>
            <div styleName={"styles.bottomButtonWrap"}>
                {children}
            </div>
        </Modal>
    );
};

NotificationModalBottom.propTypes = {
    children: PropTypes.object,
    close: PropTypes.func
};

export default NotificationModalBottom;

