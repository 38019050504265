/* eslint-disable max-statements */
import React from "react";
import styles from "./styles.css";
import { Helmet } from "react-helmet";
import BestRates from "./images/best-rate.webp";
import { ANIMATION_INTERVAL, ANIMATION_TEXT } from "./constant";
import DiscoverDreamCarForms from "../../shared/discover-dream-car-forms";
import backgroundStrip from "./images/bg.webp";

const DiscoverYourDreamCar = () => {
    return (
        <React.Fragment>
            <Helmet>
                <link rel="preload" href={backgroundStrip} as={"image"} type="image/webp" fetchPriority="high" />
            </Helmet>
            <div styleName={`styles.outer`}>
                <div styleName={"styles.bgWrapper"}>
                    <img src={backgroundStrip} alt="Background strip image" fetchPriority="high" />
                </div>
                <div styleName={"styles.stickerWrapper"}>
                    <img src={BestRates} alt="Best Rates Image" />
                </div>
                <p styleName={"styles.heading"}>
                    Discover your dream car.<br /><span>CARS24 Finance</span>
                </p>
                <DiscoverDreamCarForms
                    showWeekPlaceholderAnimation
                    weekPlaceholderAnimationDetials={{
                        animationText: ANIMATION_TEXT,
                        animationInterval: ANIMATION_INTERVAL
                    }}
                    showWeekLeftAnimation
                    showIntegratedFormLabel
                />
            </div>
        </React.Fragment>
    );
};

export default DiscoverYourDreamCar;
