import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import InventoryCarCardSmall from "./component";
import { setGsGalleryData } from "../car-details/actions";

const mapStateToProps = (
    {
        user: {
            email,
            secureToken,
            isLoggedIn
        },
        filters: {
            allFilters,
            appliedFilters,
            selectedSort,
            listingType
        },
        abExperiment: {
            webLocationHighlighting
        }
    }
) => ({
    allFilters,
    appliedFilters,
    selectedSort,
    email,
    listingType,
    secureToken,
    isLoggedIn,
    webLocationHighlighting
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    setGsGalleryDataConnect: setGsGalleryData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(InventoryCarCardSmall);
