
import React, {useEffect} from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import {  trackCustomReMarketingEvents, trackMobileCustomEventsAU } from "../../../tracking";
import { MARKETING_EVENTS_CONSTANTS } from "../../../tracking/marketing-page-events";
import { AU_MOBILE_EVENTS } from "../../../tracking/au.mobile-events";
import HomePageRevamp from "./home-component-revamp";
import getCookie from "../../../utils/helpers/get-cookie";
import { isExperimentNull } from "../../../utils/helpers/get-ab-expirement-variant";

const Home = (props) => {
    const {
        isUserZeroDpVariant,
        getInhouseEligibilityConnect,
        preApproved,
        getIsStraightThroughEligbleConnect,
        stEligibleData,
        secureToken
    } = props;

    const {isStEligible} = stEligibleData || {};

    useEffect(() => {
        trackCustomReMarketingEvents(MARKETING_EVENTS_CONSTANTS.HOME);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (secureToken) {
            getInhouseEligibilityConnect();
        }
    }, [getInhouseEligibilityConnect, secureToken]);

    useEffect(() => {
        getInhouseEligibilityConnect();
    }, [getInhouseEligibilityConnect]);

    useEffect(() => {
        if (preApproved || isStEligible) {
            getIsStraightThroughEligbleConnect();
        }
    }, [getIsStraightThroughEligbleConnect, isStEligible, preApproved]);

    useEffect(() => {
        trackMobileCustomEventsAU(AU_MOBILE_EVENTS.ZERO_DP_HOMEPAGE, {
            eventLabel: isUserZeroDpVariant ? "Variant B" : "Variant A"
        });
    }, [isUserZeroDpVariant]);

    useEffect(() => {
        const testDriveVariant =  getCookie(AU_MOBILE_EVENTS.TEST_DRIVE_LOCATION_EXPOSURE);
        let customStateVariant =  getCookie(AU_MOBILE_EVENTS.WEB_CUSTOM_STATE_EXPERIMENT);
        customStateVariant = isExperimentNull(customStateVariant) ? null : customStateVariant;
        trackMobileCustomEventsAU(AU_MOBILE_EVENTS.TEST_DRIVE_LOCATION_EXPOSURE, {
            eventAction: AU_MOBILE_EVENTS.TEST_DRIVE_LOCATION_EXPOSURE,
            eventLabel: testDriveVariant,
            eventCategory: "Cars24_home_page",
            event: "custom_event"
        });
        trackMobileCustomEventsAU(AU_MOBILE_EVENTS.WEB_CUSTOM_STATE_EXPERIMENT, {
            eventAction: AU_MOBILE_EVENTS.WEB_CUSTOM_STATE_EXPERIMENT,
            eventLabel: `${customStateVariant}_${JSON.stringify(props.userGeoData)}`,
            eventCategory: "Cars24_home_page",
            event: "custom_event"
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div styleName={"styles.wrapper"}>
            <HomePageRevamp {...props} />
        </div>
    );
};

Home.propTypes = {
    isLoggedIn: PropTypes.bool,
    isUserZeroDpVariant: PropTypes.bool,
    getInhouseEligibilityConnect: PropTypes.func,
    preApproved: PropTypes.bool,
    getIsStraightThroughEligbleConnect: PropTypes.func,
    userGeoData: PropTypes.object,
    stEligibleData: PropTypes.object,
    secureToken: PropTypes.string
};

export default Home;
