import React from "react";
import withAULayout from "../../../components/au.mobile/layout";
import Homepage from "../../../components/au.mobile/home";
import ErrorHandler from "../../../components/shared/with-error-handler";
import HomeMeta from "../../../components/shared/meta-constants";

const Home = () => {
    return (<React.Fragment>
        <HomeMeta/>
        <ErrorHandler>
            <Homepage/>
        </ErrorHandler>
    </React.Fragment>);
};

export default withAULayout(Home, true, true, {
    headerProps: { showEmailIcon: false, showWhiteMenu: true, showBlueMenu: false, showWhiteWishlist: false, showWhiteCall: false, showBlueWishlist: true },
    showBottomNavigation: true,
    hideBottomNavOnScroll: false
});
