import { Helmet } from "react-helmet";
import React from "react";
import { appUrl } from "../../../constants/url-constants";
import PropTypes from "prop-types";

const SearchMeta = ({
    location: {pathname = "", search = ""}
}) => {
    const searchParams = search.replace(/(rf=)|(sf=)/g, "").replace(/\?/g, "") || "search_term_string";
    const isSearchPage = pathname.indexOf("/buy-used-cars");

    const ldDetails = JSON.stringify({
        "@context": "https://schema.org",
        "@type": "WebSite",
        "url": `${appUrl()}/`,
        "potentialAction": {
            "@type": "SearchAction",
            "target": `${appUrl()}/buy-used-cars-australia${searchParams}`,
            "query-input": `required name=${searchParams}`
        }
    });

    return (
        isSearchPage !== -1 ?
            <Helmet  script={[{
                type: "application/ld+json",
                innerHTML: ldDetails
            }]} />
            : null

    );
};
SearchMeta.propTypes = {
    location: PropTypes.object,
    pathname: PropTypes.string,
    search: PropTypes.string
};

export default SearchMeta;
