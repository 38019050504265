import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchCarList as fetchHomeCarList } from "../home/actions";
import { resetCarList, reloadCarList } from "../car-listing/actions";
import RecentlyViewedAndPicks from "./component";
import { updateSelectedOnScreenFilter, clearAllFilters } from "../filters/actions";

const mapStateToProps = ({
    homeCarList: { content = [], totalCars, page, isLoading: loadingHomecarList, config, isSSR },
    user: {
        isLoggedIn
    },
    preApprovalLoan: {
        inHouseFinanceEligibility
    },
    filters: {searchPageResult, loadingSearch}
}) => ({
    content,
    totalCars,
    page,
    loadingHomecarList,
    config,
    isSSR,
    isLoggedIn,
    inHouseFinanceEligibility,
    searchPageResult,
    loadingSearch
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            reloadCarListConnect: reloadCarList,
            resetCarListConnect: resetCarList,
            updateSelectedOnScreenFilterConnect: updateSelectedOnScreenFilter,
            clearAllFiltersConnect: clearAllFilters,
            fetchHomeCarListConnect: fetchHomeCarList
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(RecentlyViewedAndPicks);
