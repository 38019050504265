import React from "react";
import PropTypes from "prop-types";
import styles from  "./styles.css";

import googleIcon from "./images/google-icon.png";
import trustPilotIcon from "./images/trustpilot-icon.png";
import starIcon from "./images/star.svg";

const RatingImage = ({ratingSource}) => {
    let image = "";
    switch (ratingSource) {
    case "google":
        image = googleIcon;
        break;
    case "trustPilot":
        image = trustPilotIcon;
        break;
    }
    return image ? <img src={image} alt="rating icon"/> : null;
};

const RatingPill = ({ratingSource, rating = ""}) => {
    if (!["google", "trustPilot"].includes(ratingSource)) return null;
    return (
        <div styleName={`styles.ratingContainer ${ratingSource === "trustPilot" ? " styles.trustPilot" : ""}`}>
            <RatingImage ratingSource={ratingSource}/>
            <span styleName="styles.rating">{rating}</span>
            <img src={starIcon} alt="star icon" styleName="styles.starIcon"/>
        </div>
    );
};

RatingImage.propTypes = {
    ratingSource: PropTypes.string
};

RatingPill.propTypes = {
    ratingSource: PropTypes.string,
    rating: PropTypes.string || PropTypes.number
};

export default RatingPill;
