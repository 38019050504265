import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import getCarInfo from "../../../utils/helpers/get-car-info";
import { makePriceLabelRound } from "../../../utils/helpers/make-price-label";
import { useHistory } from "react-router";
import { yieldToMain } from "../../../utils/helpers/yield-to-main";
import TrackableCarCardItem from "../trackable-car-card-item";
import locationImg from "./images/location.svg";

// Tracking
import carListingEventDataParser, { parseFilterToDimension } from "../../../tracking/parser/car-listing";
import carListingEventDataParserGA4, { parseFilterToDimensionGA4 } from "../../../tracking/parser/car-listing-ga4";

import { AU_MOBILE_EVENTS } from "../../../tracking/au.mobile-events";
import { GALLERY_TAB_TYPE } from "../car-detail-info/constant";
import { getAbExpirementVariant } from "../../../utils/helpers/get-ab-expirement-variant";
import { EXPERIMENT_TYPE } from "../../../constants/optimize-constants";
import useCarHighlightLocation from "../../../hooks/use-car-highlight-location";

const InventoryCarCardSmall = ({
    item,
    config,
    index,
    allFilters,
    appliedFilters,
    selectedSort,
    listingType,
    impressionSource,
    onClickCallback = () => { },
    setGsGalleryDataConnect,
    webLocationHighlighting,
    showLocationCallback
}) => {

    const { carFullName,
        carImage: imageUrl,
        priceLabel,
        emiPerWeek,
        carAbsoluteURL,
        carRelativeURL
    } = getCarInfo(item, config);
    const history = useHistory();

    const filterDimensions = parseFilterToDimension(allFilters, appliedFilters, selectedSort);
    const filterDimensionsGA4 = parseFilterToDimensionGA4(allFilters, appliedFilters, selectedSort);
    const showWebLocationHighlighting = getAbExpirementVariant(webLocationHighlighting, EXPERIMENT_TYPE.VARIANT_B);

    const {currentCarCityInfo = {}} = useCarHighlightLocation(item.cityCode || "");
    const {cityName: currentCarLocation} = currentCarCityInfo || {};

    const ecommerEventData = () => {
        const data = carListingEventDataParser(item, {
            position: index + 1,
            list: impressionSource,
            filterDimensions
        });
        return data;
    };

    const eventData = () => {
        const data = ecommerEventData();
        return {
            name: AU_MOBILE_EVENTS.CAR_IMPRESSION,
            data: {
                ...data.carsImpression
            }
        };
    };

    const eventDataGA4 = () => {
        const dataGA4 = carListingEventDataParserGA4(item, {
            position: index + 1,
            listingType,
            filterDimensions: filterDimensionsGA4
        });
        return {
            name: AU_MOBILE_EVENTS.VIEW_ITEM_LIST,
            data: {
                ...dataGA4
            }
        };
    };

    const onCardClickHandler = async (e) => {
        e.preventDefault();
        onClickCallback(e, item);
        await yieldToMain();
        if (setGsGalleryDataConnect) {
            setGsGalleryDataConnect({
                isOpen: false,
                activeTab: GALLERY_TAB_TYPE.exterior,
                selectedImageIndex: 0,
                clickedImageType: GALLERY_TAB_TYPE.exterior,
                selectedGlobalImageIndex: 0
            });
        }
        const prevPath = history.location.pathname;
        history.push(carRelativeURL, { prevPath });
    };

    const handleLocationClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        showLocationCallback();
    };

    return (
        <TrackableCarCardItem  event={eventData()} eventGA4={eventDataGA4()} >
            <a styleName={"styles.wrapper"} href={carAbsoluteURL} onClick={onCardClickHandler} key={index}>
                <div styleName={"styles.outer"}>
                    <div styleName={"styles.imageWrapper"}>
                        <img src={imageUrl} />
                        <div styleName={"styles.blurBg"}>&nbsp;</div>
                    </div>
                    <div styleName={"styles.contentWrap"}>
                        <p styleName={"styles.carName"}>{carFullName}</p>
                        <div styleName={"styles.priceEmiWrap"}>
                            <p styleName={"styles.price"}>{priceLabel}</p>
                            <p styleName={"styles.emiPerWeek"}>{makePriceLabelRound(emiPerWeek)}/week</p>
                        </div>
                        {!showWebLocationHighlighting && <p styleName={"styles.exclusiveCharge"}>Excl. Govt. Charges</p>}
                        {showWebLocationHighlighting && currentCarLocation && <p styleName={"styles.location"} onClick={(e) => {handleLocationClick(e);}}><img src={locationImg} />{currentCarLocation} hub</p>}
                    </div>
                </div>
            </a>
        </TrackableCarCardItem>
    );
};

InventoryCarCardSmall.propTypes = {
    item: PropTypes.object,
    config: PropTypes.object,
    onClickCallback: PropTypes.func,
    index: PropTypes.number,
    listingType: PropTypes.string,
    allFilters: PropTypes.object,
    appliedFilters: PropTypes.array,
    selectedSort: PropTypes.object,
    impressionSource: PropTypes.string,
    setGsGalleryDataConnect: PropTypes.func,
    webLocationHighlighting: PropTypes.object,
    showLocationCallback: PropTypes.func
};
export default InventoryCarCardSmall;
