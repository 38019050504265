/* eslint-disable max-statements */
import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import DropdownFilter from "../../au.mobile/filter-revamp/dropdown-filter";
import { FILTER_ENTITIES } from "../../../constants/app-constants";
import getAppliedFilter from "../../../utils/filters-v2/get-applied-filter";
import informationLogo from "./images/information.svg";

const DepositFilter = ({
    allFilters,
    showIntegratedFormLabel = false,
    appliedFilters,
    onOptionSelection
}) => {

    const depositFilter = allFilters[FILTER_ENTITIES.DEPOSIT];
    const infoIcon = {
        logo: informationLogo,
        label: "Select an option closest to your choice, you can edit later to make it precise.",
        isDisabled: true
    };
    const updatedOptions = [...depositFilter?.options, infoIcon];

    const { key = "" } = depositFilter || {};
    const selectedAppliedFilter = getAppliedFilter({ filterKey: key }, appliedFilters);
    return (

        <div>
            <div styleName={"styles.firstBoxWrapper"} style={{ height: "auto", marginBottom: "0" }}>
                <p styleName={showIntegratedFormLabel ? "styles.label" : "styles.separateLabel"}>
                    How much will you deposit upfront?
                </p>
                <DropdownFilter filter={depositFilter}
                    filterKey={FILTER_ENTITIES.DEPOSIT}
                    options={updatedOptions}
                    defaultInputValue={selectedAppliedFilter?.optionKey || ""}
                    onOptionSelection={onOptionSelection}
                    customDropDownText="Choose the one closest to your choice, you can edit later to make it precise. "/>

            </div>
        </div>
    );
};

DepositFilter.propTypes = {
    allFilters: PropTypes.object,
    config: PropTypes.object,
    showWeekPlaceholderAnimation: PropTypes.bool,
    weekPlaceholderAnimationDetials: PropTypes.object,
    showWeekLeftAnimation: PropTypes.bool,
    showIntegratedFormLabel: PropTypes.bool,
    showCustomBudgetRange: PropTypes.bool,
    defaultVehicleTypeOpen: PropTypes.bool,
    appliedFilters: PropTypes.array,
    onOptionSelection: PropTypes.func
};

export default DepositFilter;
