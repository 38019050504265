import React from "react";
import { connect } from "react-redux";
import {withRouter} from "react-router-dom";
import { bindActionCreators } from "redux";
import HomePageBannerRevamp from "./component";
import {fetchFilters, updateSSRStatus as updateFilterSSRStatus, clearAllFilters,
    updateSelectedOnScreenFilter, updateAllSubFilterOptions, updateAppliedFilters,
    updateAllFiltersVisibility,
    fetchListingCount,
    updateUrlOptions,
    fetchSearchResult
} from "../filters/actions";
import { reloadCarList, resetCarList } from "../car-listing/actions";
import { fetchBookedTasksList } from "../booked-tasks/actions";
import { fetchConfig } from "../home/actions";

const mapStateToProps = ({
    filters: {
        showAllFilters,
        isSSR: isFilterSSR,
        allFilters,
        selectedFilters,
        config,
        listingCount,
        totalListedCar
    },

    cities: {
        selectedCity
    },
    user: {
        isLoggedIn,
        firstName
    },
    homeCarList: {
        isSSR
    },
    config: {
        saleConfig
    },
    tradeIn: {
        userTradeInData,
        tradeInToggleSelected
    },
    config: {
        ratingsConfig: {
            ratingAndReviews
        }
    },
    abExperiment: {
        eventBanners
    }

}) => ({
    showAllFilters,
    isFilterSSR,
    selectedCity,
    selectedFilters,
    allFilters,
    config,
    listingCount,
    isLoggedIn,
    firstName,
    totalListedCar,
    saleConfig,
    userTradeInData,
    tradeInToggleSelected,
    isSSR,
    ratingAndReviews,
    eventBanners
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchFiltersConnect: fetchFilters,
    updateFilterSSRStatusConnect: updateFilterSSRStatus,
    clearAllFiltersConnect: clearAllFilters,
    updateSelectedOnScreenFilterConnect: updateSelectedOnScreenFilter,
    reloadCarListConnect: reloadCarList,
    updateAllSubFilterOptionsConnect: updateAllSubFilterOptions,
    resetCarListConnect: resetCarList,
    updateAppliedFiltersConnect: updateAppliedFilters,
    updateAllFiltersVisibilityConnect: updateAllFiltersVisibility,
    fetchListingCountConnect: fetchListingCount,
    updateUrlOptionsConnect: updateUrlOptions,
    fetchSearchResultConnect: fetchSearchResult,
    fetchBookedTasksListConnect: fetchBookedTasksList,
    fetchConfigConnect: fetchConfig
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomePageBannerRevamp));

