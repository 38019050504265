import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import FastTrack from "./images/logo.webp";
import Arrow from "./images/arrow.svg";
import ROUTES_CONSTANTS from "../../../constants/routes-constant";
import { trackMobileCustomEventsAU } from "../../../tracking";
import { GA_EVENT_NAMES } from "../../shared/discover-dream-car-forms/tracking";
import { CAR_LIST_EVENT_NAMES, PAGE_SOURCE } from "../../../constants/app-constants";
import FinanceCentricIcon from "./images/finance-centric-card-logo.svg";
import { yieldToMain } from "../../../utils/helpers/yield-to-main";

const CarLoanEligibiltyCard = ({ setPreApprovedOriginConnect, source, isFinanceCentricModal = false, onClickCB }) => {
    const history = useHistory();

    const financeCardClickedSource = () => {
        if (isFinanceCentricModal) {
            return "FFD_pre_approval_modal";
        } else if (source === PAGE_SOURCE.CLP) {
            return "FFD_pre_approval_CLP";
        } else if (source === PAGE_SOURCE.HOME_PAGE) {
            return "FFD_pre_approval_HP";
        }
        return "";
    };

    const getPreApprovalOrigin = () => {
        if (source === PAGE_SOURCE.CLP) {return CAR_LIST_EVENT_NAMES.LISTING;} else if (source === PAGE_SOURCE.CLP_LOAN_CALCULATOR) {return  CAR_LIST_EVENT_NAMES.CLP_LOAN_CALCULATOR;} else {return CAR_LIST_EVENT_NAMES.HOME_PAGE;}
    };

    const handlePreapprovalRedirect = async () => {
        await yieldToMain();
        const preApproveOrigin = getPreApprovalOrigin();
        setPreApprovedOriginConnect(preApproveOrigin);

        trackMobileCustomEventsAU(GA_EVENT_NAMES.FINANCE_CARD_CLICKED, {
            eventAction: "Finance_card_clicked",
            eventLabel: financeCardClickedSource()
        });
        if (onClickCB) onClickCB();
        history.push(ROUTES_CONSTANTS.get_pre_approval);
    };

    if (isFinanceCentricModal) {
        return (
            <div styleName={"styles.outer styles.financeCentricWrapper"}>
                <img styleName={"styles.financeCentricLogo"} src={FinanceCentricIcon} alt="12x faster loans"/>
                <div>
                    <div styleName={"styles.heading"}>Car loan eligibility in <p>2 min</p></div>
                    <p styleName={"styles.noImpact"}>No impact on credit score</p>
                    <button  styleName={"styles.preApproval"} onClick={handlePreapprovalRedirect}>Get pre-approval</button>
                </div>

            </div>
        );
    } else {
        return (
            <React.Fragment>
                <div styleName={"styles.outer"}>
                    <div styleName={"styles.topSection"}>
                        <p styleName={"styles.personalized"}>Personalized rates on all cars</p>
                        <img src={FastTrack} alt="Fast track logo" />
                    </div>
                    <p styleName={"styles.heading"}>Car loan eligibility in <span>2 min</span></p>
                    <div styleName={"styles.bottomSection"}>
                        <p styleName={"styles.noImpact"}>No impact on credit score</p>
                        <button styleName={"styles.preApproval"} onClick={handlePreapprovalRedirect}>Get pre-approval<img src={Arrow} alt="Back arrow image" /></button>
                    </div>
                </div>
            </React.Fragment>
        );
    }
};

CarLoanEligibiltyCard.propTypes = {
    setPreApprovedOriginConnect: PropTypes.func.isRequired,
    source: PropTypes.string,
    isFinanceCentricModal: PropTypes.bool,
    preload: PropTypes.bool,
    onClickCB: PropTypes.func
};

export default CarLoanEligibiltyCard;
