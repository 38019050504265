export const AMPLITUDE_EVENT_NAMES = {
    FFD_FLOW_ENTERED: "ffd flow entered",
    FFD_VALUES_ENTERED: "ffd values entered",
    FFD_VIEW_CARS_CLICKED: "ffd view cars clicked",
    FFD_EDIT_PARAMETERS_CLICKED: "ffd edit parameters clicked",
    FFD_CLEAR_PARAMETERS_CLICKED: "ffd clear parameters clicked",
    CARD_VIEWED: "Card_viewed",
    FINANCE_CARD_CLICKED: "Finance_card_clicked",
    FFD_SECTION_SEEN: "ffd section seen"
};

export const GA_EVENT_NAMES = {
    repayment_selected_hp: {
        event: "custom_event",
        eventCategory: "finance_first_discovery",
        eventAction: "repayment_selected_hp"
    },
    repayment_selected_modal: {
        event: "custom_event",
        eventCategory: "finance_first_discovery",
        eventAction: "repayment_selected_modal"
    },
    budget_selected_hp: {
        event: "custom_event",
        eventCategory: "finance_first_discovery",
        eventAction: "budget_selected_hp"
    },
    budget_selected_modal: {
        event: "custom_event",
        eventCategory: "finance_first_discovery",
        eventAction: "budget_selected_modal"
    },
    budget_selected_modal_custom: {
        event: "custom_event",
        eventCategory: "finance_first_discovery",
        eventAction: "budget_selected_modal_custom"
    },
    deposit_selected_hp: {
        event: "custom_event",
        eventCategory: "finance_first_discovery",
        eventAction: "deposit_selected_hp"
    },
    deposit_selected_modal: {
        event: "custom_event",
        eventCategory: "finance_first_discovery",
        eventAction: "deposit_selected_modal"
    },
    bodytype_selected_hp: {
        event: "custom_event",
        eventCategory: "finance_first_discovery",
        eventAction: "bodytype_selected_hp"
    },
    bodytype_selected_modal: {
        event: "custom_event",
        eventCategory: "finance_first_discovery",
        eventAction: "bodytype_selected_modal"
    },
    view_cars_clicked_hp: {
        event: "custom_event",
        eventCategory: "finance_first_discovery",
        eventAction: "view_cars_clicked_hp"
    },
    view_cars_clicked_modal: {
        event: "custom_event",
        eventCategory: "finance_first_discovery",
        eventAction: "view_cars_clicked_modal"
    },
    flow_entered: {
        event: "custom_event",
        eventCategory: "finance_first_discovery",
        eventAction: "flow_entered"
    },
    clear_filter_used: {
        event: "custom_event",
        eventCategory: "finance_first_discovery",
        eventAction: "clear_filter_used"
    },
    edit_filter_used: {
        event: "custom_event",
        eventCategory: "finance_first_discovery",
        eventAction: "edit_filter_used"
    },
    entry_point_seen: {
        event: "custom_event",
        eventCategory: "finance_first_discovery",
        eventAction: "entry_point_seen"
    },
    Card_viewed: {
        event: "custom_event",
        eventCategory: "finance_first_discovery",
        eventAction: "Card_viewed"
    },
    Finance_card_clicked: {
        event: "custom_event",
        eventCategory: "finance_first_discovery",
        eventAction: "Finance_card_clicked"
    }
};
