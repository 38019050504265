/* eslint-disable complexity */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import styles from "./styles.css";
import SearchMeta from "../../shared/meta-constants/search-meta";
import Button from "../../shared/button";
import { useHistory, useLocation } from "react-router";
import InputFilterSearch from "../input-filter-search";
import Filters from "../../../utils/filters-v2";
import { DEFAULT_CITY, NUMBER, PAGE_SOURCE } from "../../../constants/app-constants";
import { appUrl } from "../../../constants/url-constants";
import BookedTasks from "../booked-tasks";
import { trackHomePageEvent } from "../../../handlers/mobile/event-tracker";
import { trackMobileCustomEventsAU } from "../../../tracking";

import useScrollPosition from "../../../hooks/use-scroll-position";
import {yieldToMain} from "../../../utils/helpers/yield-to-main";
import { AU_MOBILE_EVENTS } from "../../../tracking/au.mobile-events";
import capitalize from "../../../utils/helpers/capitalize";
import RatingPill from "../../../au-c2b/shared/components/rating/ratings-pill";

import { DEFAULT_PATHNAME } from "../../../utils/filters-v2/constants";
import FestivalBanner from "./festival-banner";
import SaleBanner from "./sale-banner";
import { getAbExpirementVariant } from "../../../utils/helpers/get-ab-expirement-variant";
import { EXPERIMENT_TYPE } from "../../../constants/optimize-constants";

// eslint-disable-next-line max-statements
const HomePageBannerRevamp = ({
    selectedCity,
    allFilters,
    resetCarListConnect,
    reloadCarListConnect,
    updateAllFiltersVisibilityConnect,
    fetchSearchResultConnect,
    updateSelectedOnScreenFilterConnect,
    updateAppliedFiltersConnect,
    fetchFiltersConnect,
    isLoggedIn,
    firstName,
    clearAllFiltersConnect,
    secureToken,
    fetchBookedTasksListConnect,
    totalListedCar,
    updateUrlOptionsConnect,
    saleConfig,
    userTradeInData,
    tradeInToggleSelected,
    fetchConfigConnect,
    isSSR,
    ratingAndReviews,
    eventBanners
}) => {
    const history = useHistory();
    const location = useLocation();
    const sellCarURL = `${appUrl()}/sell-your-car?utm_source=home-banner&utm_medium=Msite&utm_campaign=Homepage-banner-redirection&utm_id=Main-CTA-Redirect`;
    const [viewCarsButtonClicked, setViewCarsButtonClicked] = useState(false);
    const { tradeIn = false } = userTradeInData || {};
    const filters = new Filters({ cityCode: selectedCity.code || DEFAULT_CITY.AU.code });
    const showFestiveBannerVariant = getAbExpirementVariant(eventBanners, EXPERIMENT_TYPE.VARIANT_B);
    const showWebTrustVariant = true;
    const { saleLive = false } = saleConfig?.data || saleConfig || {};
    const scrollPosition = useScrollPosition();

    const getStickySearchThreshold = () => {
        if (saleLive) {
            return NUMBER.TWO_HUNDRED_THIRTY;
        }
        if (isLoggedIn) {
            if (showWebTrustVariant) {
                return NUMBER.ONE_HUNDRED_EIGHTY;
            } else {
                return NUMBER.ONE_HUNDRED_THIRTY_FOUR;
            }
        } else if (showWebTrustVariant) {
            return NUMBER.TWO_HUNDRED_THIRTY;
        } else {
            return NUMBER.ONE_HUNDRED_SEVENTY_FOUR;
        }
    };
    const stickySearchThreshold = getStickySearchThreshold();
    useEffect(() => {
        updateUrlOptionsConnect({});
        fetchFiltersConnect();
        if (!isSSR) {
            fetchConfigConnect();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchFiltersConnect]);

    useEffect(() => {
        const payload = tradeIn && tradeInToggleSelected ? `&tradeIn=true` : "";
        fetchSearchResultConnect({}, `homePageVariant=B${payload}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn]);

    useEffect(() => {
        if (secureToken) {
            fetchBookedTasksListConnect(secureToken);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [secureToken]);

    const onClickShowCars = async (e) => {
        e.preventDefault();
        setViewCarsButtonClicked(true);
        await yieldToMain();
        resetCarListConnect();
        clearAllFiltersConnect();
        const { relativeURL } = filters.getListingPayload({ selectedFilters: [], filters: allFilters });
        reloadCarListConnect(true);
        updateAllFiltersVisibilityConnect(false);
        updateSelectedOnScreenFilterConnect("");
        updateAppliedFiltersConnect();
        trackHomePageEvent("view_all_cars");

        // using setTimeout to help with INP
        window.setTimeout(() => {
            history.push(relativeURL);
        }, 0);
    };

    const handleSearchClickCallback = async () => {
        await yieldToMain();

        trackMobileCustomEventsAU(AU_MOBILE_EVENTS.SEARCH_BAR_CLICKED, {
            eventLabel: capitalize(PAGE_SOURCE.HOME_PAGE),
            eventCategory: "Cars24_home_page"
        });
        trackHomePageEvent("search");
    };

    const handleSellCar = async() => {
        await yieldToMain();
        trackHomePageEvent("c2b_click");
    };
    const showBanner = saleLive || showFestiveBannerVariant;
    return (
        <React.Fragment>
            <div styleName={`${showBanner ? "" : "styles.bannerOuter"}`}>
                {showBanner ?
                    <React.Fragment>
                        {showFestiveBannerVariant && <FestivalBanner onClickShowCars={onClickShowCars} isLoggedIn={isLoggedIn} totalListedCar={totalListedCar} scrollPosition={scrollPosition} viewCarsButtonClicked={viewCarsButtonClicked} stickySearchThreshold={stickySearchThreshold} />}
                        {saleLive && <SaleBanner onClickShowCars={onClickShowCars} isLoggedIn={isLoggedIn} totalListedCar={totalListedCar} scrollPosition={scrollPosition} viewCarsButtonClicked={viewCarsButtonClicked} stickySearchThreshold={stickySearchThreshold} />}
                    </React.Fragment>

                    :
                    <React.Fragment>
                        {isLoggedIn ?
                            <h1 styleName={"styles.loggedInCase"}>Hi, {firstName || ""}</h1> :
                            <h1 styleName={"styles.bannerHeading"}>Love it or return it<br />in 7 days. 100% money<br />back guarantee</h1>
                        }
                        {isLoggedIn && <p styleName={"styles.resumeJourney"}>Resume your car buying journey</p>}
                    </React.Fragment>
                }
                {showWebTrustVariant && <div styleName={`styles.trustIconsWrap ${showBanner ? "styles.hideIconsWrapper" : ""}`}>
                    <RatingPill ratingSource={"google"} rating={ratingAndReviews?.google?.rating}/>
                    <RatingPill ratingSource={"trustPilot"} rating={ratingAndReviews?.trustPilot?.rating}/>
                </div>}
                {!showBanner &&
                <React.Fragment>
                    {scrollPosition >= stickySearchThreshold && <div styleName={`${showBanner ? "styles.saleSearchPlaceholder" : "styles.searchPlaceholder"}`} />}
                    <div styleName={` ${scrollPosition >= stickySearchThreshold ? "styles.searchContainerFixed" : "styles.searchContainer" } ${showBanner && scrollPosition < stickySearchThreshold ? "styles.saleContainer" : ""}`}>
                        <SearchMeta location={location} />
                        <InputFilterSearch isStickyHomeSearch={scrollPosition > stickySearchThreshold} handleSearchClickCallback={handleSearchClickCallback} showPlaceholderAnimate={true} souce={PAGE_SOURCE.HOME_PAGE} />
                    </div>
                    <a href={`${appUrl()}${DEFAULT_PATHNAME}`} styleName={"styles.anchorStyling"}>
                        <Button
                            isLoading={viewCarsButtonClicked}
                            styleName={"styles.listingButton"} text={`VIEW ALL ${totalListedCar} CARS`}
                            onClick={onClickShowCars} />
                    </a>
                    <p styleName={"styles.notLookingToBuy"}>Not looking to buy? <a href={sellCarURL} onClick={handleSellCar}>Click here to sell your car</a></p>
                </React.Fragment>}
            </div>
            {!!isLoggedIn &&
                <div styleName="styles.taskWrappers">
                    <BookedTasks />
                </div>
            }
        </React.Fragment>
    );
};

HomePageBannerRevamp.propTypes = {
    fetchSearchResultConnect: PropTypes.func,
    updateSelectedOnScreenFilterConnect: PropTypes.func,
    updateAppliedFiltersConnect: PropTypes.func,
    selectedCity: PropTypes.object,
    selectedFilters: PropTypes.array,
    allFilters: PropTypes.object,
    resetCarListConnect: PropTypes.func,
    reloadCarListConnect: PropTypes.func,
    updateAllFiltersVisibilityConnect: PropTypes.func,
    totalCars: PropTypes.number,
    fetchFiltersConnect: PropTypes.func,
    isLoggedIn: PropTypes.bool,
    firstName: PropTypes.string,
    clearAllFiltersConnect: PropTypes.func,
    fetchBookedTasksListConnect: PropTypes.func,
    secureToken: PropTypes.string,
    totalListedCar: PropTypes.number,
    updateUrlOptionsConnect: PropTypes.func,
    saleConfig: PropTypes.object,
    userTradeInData: PropTypes.bool,
    tradeInToggleSelected: PropTypes.bool,
    fetchConfigConnect: PropTypes.func,
    isSSR: PropTypes.bool,
    ratingAndReviews: PropTypes.object,
    eventBanners: PropTypes.object
};

export default HomePageBannerRevamp;
