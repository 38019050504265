import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setUserPersonalDetails } from "../checkout-info/actions";
import {dowloadContract, updateDeliveryContractData} from "../order-task-wrapper/actions";
import SignContract from "./component";

const mapStateToProps = ({
    carDetails: {
        content
    },
    checkout: {
        signContractData,
        personalDetails,
        //signContractPollCompleted,
        order
    },
    user: { secureToken }
}) => ({
    content,
    personalDetails,
    signContractData,
    //signContractPollCompleted,
    order,
    secureToken
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    dowloadContractConnect: dowloadContract,
    setUserPersonalDetailsConnect: setUserPersonalDetails,
    updateDeliveryContractConnect: updateDeliveryContractData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SignContract);
