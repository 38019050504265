/* eslint-disable max-statements */
/* eslint-disable complexity */
import React, { useState } from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import useCountDown from "../../../hooks/use-countdown";
import Button from "../../shared/button";
import NotificationBottom from "../notification-bottom";
import NeedMoreTimeImg from "./images/need-more-time.svg";
import ZeroDpFormOnTime from "../zero-dp-form-on-time";
import Modal from "../../shared/modal";
import StatusIcon from "./images/action-required.svg";
import { NUMBER } from "../../../constants/app-constants";
import { trackMobileCustomEventsAU } from "../../../tracking";
import { AU_MOBILE_EVENTS } from "../../../tracking/au.mobile-events";

const ZeroDpTimerText = ({
    timeoutDetails,
    showNeedMoreTime,
    financeData,
    origin
}) => {
    const { user } = financeData || {};
    const { mobile } = user || {};
    const { expiryTime } = timeoutDetails || {};
    const [showNeedMoreTimeForm, setShowNeedMoreTimeForm] = useState(false);
    const [showTimeIncreasedSuccess, setShowTimeIncreasedSuccess] = useState(false);
    const { extensionDurationLabel, displayExtensionBeforeTimeoutInMins } = timeoutDetails || {};
    // eslint-disable-next-line no-unused-vars
    const [days, hours, minutes, seconds, fullHours, timeCompletePercentage, lessMinsLeft, timeExpired] = useCountDown(expiryTime, expiryTime, displayExtensionBeforeTimeoutInMins);

    const handleNeedMoreTime = () => {
        setShowNeedMoreTimeForm(true);
        trackMobileCustomEventsAU(AU_MOBILE_EVENTS.ZERO_DP_STAGE2, {
            eventAction: "Timer runout",
            eventLabel: "Need_more_time"
        });
    };

    const handleNeedMoreTimeBack = () => {
        setShowNeedMoreTimeForm(false);
    };

    const handleNeedMoreTimeSubmit = () => {
        handleNeedMoreTimeBack();
        trackMobileCustomEventsAU(AU_MOBILE_EVENTS.ZERO_DP_STAGE2, {
            eventAction: "Timer runout",
            eventLabel: "Reason_submit"
        });
        setShowTimeIncreasedSuccess(true);
        window.setTimeout(() => {
            setShowTimeIncreasedSuccess(false);
        }, NUMBER.FIVE_THOUSAND);
    };

    const handleNeedMoreTimeSuccessClose = () => {
        setShowTimeIncreasedSuccess(false);
    };

    if (origin === "homepage") {
        return (
            <React.Fragment>
                {(lessMinsLeft || timeExpired) ?
                    <p styleName={"styles.homepageText"}>You are <span>running out of time</span> to complete these tasks. We’ll not be able to hold your reservation</p>
                    :
                    <p styleName={"styles.homepageText"}>Complete these tasks on time for us to hold your reservation</p>
                }
            </React.Fragment>
        );
    } else if (origin === "myBookings") {
        return (
            <React.Fragment>
                {(lessMinsLeft || timeExpired) ?
                    <div styleName={"styles.actionRequired styles.error"}><img src={StatusIcon} />
                        <p styleName={"styles.text"}>Running out of time to complete tasks. We’ll not be able to hold your reservation </p>
                    </div>
                    :
                    <div styleName={"styles.actionRequired styles.warning"}><img src={StatusIcon} />
                        <p styleName={"styles.text"}>Complete tasks on time for us to hold your reservation</p>
                    </div>
                }
            </React.Fragment>
        );
    } else {
        return (
            <div>
                {(lessMinsLeft || timeExpired) ?
                    <p styleName={"styles.outOfTime"}>You are <span>running out of time</span> to complete these tasks. We’ll not be able to hold your reservation</p>
                    :
                    <p styleName={"styles.outOfTime"}>Complete these tasks on time for us to hold your reservation</p>
                }
                {showNeedMoreTime && <div styleName={"styles.needButton"}><Button text={"Need More Time?"} onClick={handleNeedMoreTime} /></div>}
                {(showTimeIncreasedSuccess) && <NotificationBottom
                    img={NeedMoreTimeImg}
                    heading={`Increased time by ${extensionDurationLabel}`}
                    subHeader={<span>We will call on <span styleName={"styles.numberHighlight"}>{mobile}</span> shortly</span>}
                    onClickSubheader={handleNeedMoreTime}
                    onClose={handleNeedMoreTimeSuccessClose}
                />}
                {showNeedMoreTimeForm &&
              <Modal isOpen={true} close={handleNeedMoreTimeBack}>
                  <ZeroDpFormOnTime
                      onSubmit={handleNeedMoreTimeSubmit}
                      onBack={handleNeedMoreTimeBack}
                  />
              </Modal>
                }
            </div>
        );
    }
};

ZeroDpTimerText.propTypes = {
    timeoutDetails: PropTypes.object,
    financeData: PropTypes.object,
    origin: PropTypes.string,
    updateZeroDpExpiryTimeConnect: PropTypes.func,
    showNeedMoreTime: PropTypes.bool
};

export default ZeroDpTimerText;
