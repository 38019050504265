/* eslint-disable complexity */
/* eslint-disable max-statements */
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import { NUMBER, LOCAL_STORAGE_KEYS, PAGE_SOURCE } from "../../../constants/app-constants";
import { EXPERIMENT_TYPE } from "../../../constants/optimize-constants";
import { trackMobileCustomEventsAU } from "../../../tracking";
import { AU_MOBILE_EVENTS } from "../../../tracking/au.mobile-events";
import { setItem, getItem } from "../../../utils/helpers/storage-crud";
import SEARCH from "./images/search-icon.svg";
import SEARCH_ICON_REVAMP from "./images/search-icon-revamp.svg";
import { yieldToMain } from "../../../utils/helpers/yield-to-main";
import useCustomHistoryOperations from "../../../hooks/use-custom-history-operation";

const SEARCH_ANIMATED_PLACEHOLDER = ["Search Your Dream Car", "Search “Toyota Corolla”", "Search “Hyundai i30”", "Search Your Dream Car"];

const InputFilterSearch = React.memo(({
    urlOptions,
    userTradeInData,
    isLoggedIn,
    getTradeInToggleVisibleConnect,
    isLoading,
    isViewCarsButtonTriggered,
    setViewCarsButtonTriggeredConnect,
    showSearchFreeText,
    handleSearchClickCallback = () => {},
    showPlaceholderAnimate = false,
    isStickyHomeSearch = false,
    isFinanceDiscoveryLayout,
    isClpRevampVariant,
    source
}) => {

    const { tradeIn = false } =  userTradeInData || {};
    const [isShowTradeInToggleGuide, setIsShowTradeInToggleGuide] = useState(true);
    const { customPush } = useCustomHistoryOperations();

    const {data: showSearchFreeTextData} = showSearchFreeText || {};
    const showFreeTextVariant = showSearchFreeTextData === EXPERIMENT_TYPE.VARIANT_B;

    useEffect(() => {
        if (showSearchFreeTextData === EXPERIMENT_TYPE.VARIANT_A) {
            trackMobileCustomEventsAU(AU_MOBILE_EVENTS.FREE_SEARCH_VARIANT, {eventLabel: "VARIANT_A"});
        } else if (showSearchFreeTextData === EXPERIMENT_TYPE.VARIANT_B) {
            trackMobileCustomEventsAU(AU_MOBILE_EVENTS.FREE_SEARCH_VARIANT, {eventLabel: "VARIANT_B"});
        }
    }, [showSearchFreeTextData]);

    useEffect(() => {
        if (!isViewCarsButtonTriggered && isLoggedIn) {
            getTradeInToggleVisibleConnect();
        } else {
            setViewCarsButtonTriggeredConnect(false);
        }
    }, [isLoggedIn, getTradeInToggleVisibleConnect, isViewCarsButtonTriggered, setViewCarsButtonTriggeredConnect]);

    useEffect(() => {
        if (tradeIn) {
            const isPresent = getItem(LOCAL_STORAGE_KEYS.tradeInToggleGuide);
            if (isPresent) {
                setIsShowTradeInToggleGuide(false);
            }
        }
    }, [tradeIn]);

    const tradeInToggleGuideHandler = async () => {
        setIsShowTradeInToggleGuide(false);
        await yieldToMain();
        setItem("true", LOCAL_STORAGE_KEYS.tradeInToggleGuide);
    };

    useEffect(() => {
        if (isShowTradeInToggleGuide && tradeIn && !isLoading) {
            window.setTimeout(() => {
                tradeInToggleGuideHandler();
            }, NUMBER.FIVE_THOUSAND);
        }
    }, [isLoading, isShowTradeInToggleGuide, tradeIn]);

    const handleOnKeyDown = async () => {
        customPush("/search");
        await yieldToMain();
        handleSearchClickCallback();
    };

    return (
        <React.Fragment>
            <div styleName={`styles.filterWrap ${isClpRevampVariant ? "filterWrapBgTransparent" : ""}`}>
                {
                    <div styleName={`styles.searchWrap ${showFreeTextVariant ? "" : "styles.newSearchWrap"} ${source === PAGE_SOURCE.CLP ? "styles.newSearchWrapRevamp" : ""} ${isStickyHomeSearch ? "searchWrapGradient" : ""} ${isFinanceDiscoveryLayout ? "styles.financeCentricSearchWrap" : ""}`}>
                        <img styleName={`${source === PAGE_SOURCE.CLP ? "styles.searchRevamp" : "styles.search"} ${isFinanceDiscoveryLayout ? "styles.financeCentricSearchRevamp" : ""}`} src={source === PAGE_SOURCE.CLP ? SEARCH_ICON_REVAMP : SEARCH} />
                        <div styleName={`${source === PAGE_SOURCE.CLP ? "styles.inputWrapperRevamp" : "styles.inputWrapper"}`} onClick={handleOnKeyDown}>
                            <input type="text"
                                value={urlOptions.search || ""}
                                // placeholder="Search by brand or model"
                            />
                            {showPlaceholderAnimate && !urlOptions.search &&
                                <ul>
                                    {SEARCH_ANIMATED_PLACEHOLDER.map((item, idx) => <li key={`${idx}_${item}`}>{item}</li>)}
                                </ul>
                            }
                        </div>
                    </div>
                }
            </div>
        </React.Fragment>
    );
}, (prevProps, nextProps) => {
    return prevProps.urlOptions.search === nextProps.urlOptions.search;
});

InputFilterSearch.propTypes = {
    urlOptions: PropTypes.object,
    history: PropTypes.object,
    userTradeInData: PropTypes.object,
    isLoggedIn: PropTypes.bool,
    isLoading: PropTypes.bool,
    getTradeInToggleVisibleConnect: PropTypes.func,
    isViewCarsButtonTriggered: PropTypes.bool,
    setViewCarsButtonTriggeredConnect: PropTypes.func,
    showSearchFreeText: PropTypes.object,
    handleSearchClickCallback: PropTypes.func,
    showPlaceholderAnimate: PropTypes.bool,
    isStickyHomeSearch: PropTypes.bool,
    isFinanceDiscoveryLayout: PropTypes.bool,
    isClpRevampVariant: PropTypes.bool,
    source: PropTypes.string
};

export default InputFilterSearch;
