/* eslint-disable max-statements */
import React, { useEffect, useState } from "react";
import styles from "./styles.css";
import { NUMBER, PICKS_FOR_YOU, RECENTLY_VIEWED, CAR_LIST_EVENT_NAMES } from "../../../constants/app-constants";
import PropTypes from "prop-types";
import TrackImpression from "../../shared/track-impression";
import H2 from "../../shared/h2/component";
import { trackHomePageEvent } from "../../../handlers/mobile/event-tracker";

import InventoryCarCardSmall from "../inventory-car-card-small";
import InventoryCarCardSmallSkeleton from "../inventory-car-card-small/skeleton";
import { trackMobileCustomEventsAU } from "../../../tracking";
import OverflowContainer from "../../shared/container-overflow";
import { AU_MOBILE_EVENTS } from "../../../tracking/au.mobile-events";
import { yieldToMain } from "../../../utils/helpers/yield-to-main";
import { AMPLITUDE_EVENTS_CATEGORY } from "../../../tracking/amplitude-events";
import OurLocationModal from "../our-location-modal";

// eslint-disable-next-line complexity
const RecentlyViewedAndPicks = ({
    content = [],
    fetchHomeCarListConnect,
    isLoggedIn,
    searchPageResult = {},
    loadingHomecarList,
    loadingSearch
}) => {
    const CAR_LIST = [{ key: RECENTLY_VIEWED, label: "Recently viewed" }, { key: PICKS_FOR_YOU, label: "Picks for you" }];
    const [activeTab, setActiveTab] = useState(RECENTLY_VIEWED);
    const [showLocationModal, setShowLocationModal] = useState(false);
    const eventAction = activeTab === PICKS_FOR_YOU ? "picks_for_you" : "recently_viewed";
    const { config = {}, recentlyViewed = {} } = searchPageResult || {};
    const recentlyViewedResult = recentlyViewed.result || [];
    const showViewdCars = recentlyViewedResult.length > 0;
    const carListData = {
        [RECENTLY_VIEWED]: recentlyViewedResult,
        [PICKS_FOR_YOU]: content
    };
    const handleActiveTab = async(tab) => {
        await yieldToMain();
        setActiveTab(tab);
    };

    useEffect(() => {
        if (isLoggedIn) {
            fetchHomeCarListConnect();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn]);

    useEffect(() => {
        if (showViewdCars) {
            setActiveTab(RECENTLY_VIEWED);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showViewdCars]);

    const onCardClick = async(item) => {
        const {appointmentId = ""} = item;
        await yieldToMain();
        if (activeTab === RECENTLY_VIEWED) {

            trackMobileCustomEventsAU(AU_MOBILE_EVENTS.RECENTLY_VIEWED_CAR_CLICK, {
                eventLabel: appointmentId
            });
        } else {

            trackMobileCustomEventsAU(AU_MOBILE_EVENTS.PICKS_FOR_YOU_CAR_CLICK, {
                eventLabel: appointmentId
            });
        }
    };
    const renderList = () => {
        const result = carListData[activeTab] || {};
        return (
            result.slice(0, NUMBER.SIX).map((item, index) => {
                return (
                    <InventoryCarCardSmall
                        key={`${activeTab}_${index}`}
                        item={item}
                        index={index}
                        config={config}
                        gtmFrom="home_page"
                        onClickCallback={onCardClick}
                        impressionSource={CAR_LIST_EVENT_NAMES.HOME_PAGE}
                        showLocationCallback={() => setShowLocationModal(true)}
                    />
                );
            })
        );
    };

    const handleImpressionView = () => {
        trackHomePageEvent(`${eventAction}_impression`);
    };

    const result = carListData[activeTab] || {};
    return (
        <TrackImpression event={{ name: activeTab === RECENTLY_VIEWED ? AMPLITUDE_EVENTS_CATEGORY.RECENTLY_VIEWED_CARD_SEEN : AMPLITUDE_EVENTS_CATEGORY.PICKS_FOR_YOU_CARD_SEEN }} handleImpressionView={handleImpressionView}>
            <div styleName={"styles.wrapper"}>
                {showViewdCars && <ul styleName={"styles.tabNav"}>
                    {CAR_LIST.map(({ key, label }) =>
                        (<li
                            key={key}
                            onClick={handleActiveTab.bind(null, key)}
                            styleName={`${activeTab === key ? "styles.active" : ""}`}
                        >
                            {label}
                        </li>
                        ))}
                </ul>
                }
                {(result || []).length > 0 &&
                    <div styleName={"styles.sliderWrap"}>
                        {!showViewdCars && <H2 text="Picks For You" />}
                        <OverflowContainer>
                            {renderList()}
                        </OverflowContainer>
                    </div>
                }
                {(loadingSearch || loadingHomecarList) && (result || []).length === 0 && <div styleName={"styles.sliderWrap"}><OverflowContainer>
                    {Array(...new Array(NUMBER.FIVE)).map((i, index) => {
                        return (
                            <InventoryCarCardSmallSkeleton key={index} />
                        );
                    })}
                </OverflowContainer></div>}

            </div>
            {
                showLocationModal && <OurLocationModal handleModalClose={() => setShowLocationModal(false)}/>
            }
        </TrackImpression>
    );
};

RecentlyViewedAndPicks.propTypes = {
    content: PropTypes.array,
    config: PropTypes.object,
    isLoggedIn: PropTypes.bool,
    fetchHomeCarListConnect: PropTypes.func,
    searchPageResult: PropTypes.object,
    loadingSearch: PropTypes.bool,
    loadingHomecarList: PropTypes.bool
};

export default RecentlyViewedAndPicks;
