/* eslint-disable complexity */
import React from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";
import styles from "./styles.css";
import doubleArrow from "./images/arrow.svg";
import { trackMobileCustomEventsAU } from "../../../tracking";
import { AU_MOBILE_EVENTS } from "../../../tracking/au.mobile-events";

import { useHistory } from "react-router-dom";
import Button from "../../shared/button";
import animationData from "./fast-track-finance.json";
const Lottie = loadable(() => import("react-lottie"), {ssr: false});
import { yieldToMain } from "../../../utils/helpers/yield-to-main";
import { Helmet } from "react-helmet";
import bgImage from "./images/bg.webp";

const GetPreApprovalAndZeroDepositBanner = ({bannerImage, heading, subText, linkText, handleClick, addInfo, showFinanceFirstExperimentVariant, showStAnimation = false, preApproved}) => {
    const history = useHistory();

    const defaultLottieOptions = {
        loop: true,
        animationData
    };

    const handleKnowMore = async() => {
        await yieldToMain();
        if (showFinanceFirstExperimentVariant) {
            trackMobileCustomEventsAU(AU_MOBILE_EVENTS.FF_VOLUME_EXPERIMENT, {
                eventAction: "Finance_card_clicked",
                eventLabel: "Know_more_Home_page"
            });

        } else {
            trackMobileCustomEventsAU(AU_MOBILE_EVENTS.NUDGES_FINANCE_KNOW_MORE_FINANCE, {
                eventLabel: "Home_page"
            });

        }
        history.push("/cars24-finance");
    };

    if (showFinanceFirstExperimentVariant) {
        return (
            <React.Fragment>
                <Helmet>
                    <link rel="preload" href={bgImage} as={"image"} type="image/png" />
                </Helmet>
                <div styleName={"styles.stickerOuter"}>
                    <img src={bgImage} alt="Background" styleName={`styles.bgImageWrapper`} />
                    <div styleName={`styles.imageWrap styles.imageWrapVariant ${showStAnimation ? "styles.stStyle" : ""}`}>
                        <img src={bannerImage} />
                        {showStAnimation && (<div styleName={"styles.lottieCont"}><Lottie options={defaultLottieOptions} /></div>)}
                        {!addInfo && <p styleName={"styles.knowMore styles.knowMoreVariant"} onClick={handleKnowMore}>Know more</p>}
                    </div>
                    <p styleName={"styles.headingVariant"}>{heading}</p>
                    {linkText && <Button text={linkText} onClick={handleClick} />}
                    <div styleName={"styles.addInfoStyle"}>{addInfo}</div>
                </div>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <Helmet>
                <link rel="preload" href={bgImage} as={"image"} type="image/png" />
            </Helmet>
            <div styleName={"styles.stickerOuter"}>
                <img src={bgImage} alt="Background" styleName={`styles.bgImageWrapper`} />
                <div styleName={`styles.imageWrap ${showStAnimation ? "styles.stStyle" : ""}`}>
                    <img src={bannerImage} />
                    {showStAnimation && (<div styleName={"styles.lottieCont"}><Lottie options={defaultLottieOptions} /></div>)}
                    {!addInfo && <p styleName={"styles.knowMore"} onClick={handleKnowMore}>Know more</p>}
                </div>
                <p styleName={`styles.heading ${(showStAnimation || preApproved) ? "styles.lineHeightStyle" : ""}`}>{heading}</p>
                {subText && <p styleName={"styles.subTxt"}>{subText}</p>}
                {linkText && <p styleName={"styles.linkText"} onClick={handleClick}>{linkText}<span><img src={doubleArrow} /></span></p>}
                <div styleName={"styles.addInfoStyle"}>{addInfo}</div>
            </div>
        </React.Fragment>
    );
};

GetPreApprovalAndZeroDepositBanner.propTypes = {
    bannerImage: PropTypes.string,
    heading: PropTypes.string,
    subText: PropTypes.string,
    showFinanceFirstExperimentVariant: PropTypes.object,
    linkText: PropTypes.string,
    handleClick: PropTypes.func,
    addInfo: PropTypes.string,
    showStAnimation: PropTypes.bool,
    preApproved: PropTypes.bool
};

export default GetPreApprovalAndZeroDepositBanner;
