import React from "react";
import styles from "./styles.css";
import Modal from "../../shared/modal";
import ArrowIcon from "../../shared/arrow";
import PropTypes from "prop-types";

const FilePreviewModal = ({
    fileName,
    fileSrc,
    onClickBack = () => {}
}) => {
    const regex = /\.pdf$/i;
    const isPdf = regex.test(fileName);
    return (
        <Modal isOpen={true}>
            <div styleName={"styles.wrapper"}>
                <div styleName={"styles.header"}>
                    <ArrowIcon grey={"BlackArrow"} rotateBy={-90} onClick={onClickBack} />
                    <h4 styleName={"styles.heading"}>{fileName}</h4>
                </div>
                {isPdf ?
                    <iframe src={fileSrc} styleName={"styles.iframeStyle"}/>
                    : <div styleName={"styles.outer"}>
                        <img src={fileSrc} />
                    </div>}
            </div>
        </Modal>
    );
};

FilePreviewModal.propTypes = {
    fileName: PropTypes.string,
    onClickBack: PropTypes.func,
    fileSrc: PropTypes.string
};

export default FilePreviewModal;
