/* eslint-disable complexity */
import React, {useState, useEffect} from "react";
import styles from "./styles.css";
import SignContractImage from "./images/sign-contract-image.svg";
import { useHistory } from "react-router";
import { getMyBookingCarDetailsURL } from "../../../utils/helpers/get-detail-page-url";
// import LoanImage from "./images/loan-image.svg";
import GreenCheck from "./images/green-check.svg";
// import ContractLock from "./images/contract-lock.svg";
import { tasksRoutes } from "../../../constants/au.mobile/tasks-routes";
import PropTypes from "prop-types";
import { PURPOSE_DETAILS_TYPE, PURPOSE_TYPE, SIGN_CONTRACT_STATUS } from "../../../constants/checkout-constants";
//import TaskCardShimmer from "../task-card-shimmer";
import { trackMobileCustomEventsAU } from "../../../tracking";
import { AU_MOBILE_EVENTS } from "../../../tracking/au.mobile-events";
import { EXPERIMENT_TYPE } from "../../../constants/optimize-constants";
import Button from "../../shared/button";
import ListTab from "../../shared/list-tab";
import InputText from "../../shared/input-text";
import Checkbox from "../../shared/checkbox";
import { AU_STATES_CODE } from "../../../constants/app-constants";
import { postPaymentRoutes } from "../../../constants/au.mobile/post-payment-routes";
// import { postPaymentRoutes } from "../../../constants/au.mobile/post-payment-routes";
// eslint-disable-next-line max-statements
const SignContract = ({
    content,
    secureToken,
    personalDetails,
    signContractData = [],
    updateDeliveryContractConnect,
    showVariant = "VARIANT_A",
    dowloadContractConnect,
    setUserPersonalDetailsConnect,
    order,
    customSignClick
}) => {

    const { userPurpose } = personalDetails;
    const {taxInvoice, deliveryAddress, orderId, appointmentId} = order;
    const history = useHistory();
    const { UNSIGNED, RESUME, SIGNED } = SIGN_CONTRACT_STATUS;
    const [isRegAddAvailable, setRegAddAvailable] = useState(false);
    const [selectedKey, setSelectedKey] = useState(userPurpose && userPurpose.purpose);
    // const [businessData, setBusinessData] = useState(true);
    // const [isPersonal, setPersonal] = useState(selectedKey === "Personal");
    const [selectedACN, setSelectedACN] = useState("ABN");
    const [isAcn, setIsAcn] = useState(true);
    const { documentStatus } = signContractData[0] || {};
    const getOrderPayload = (orderResponse) => {
        const orderPayload = {
            ...orderResponse,
            userPurpose: {
                ...userPurpose
            }
        };
        return orderPayload;
    };
    const handleSignNowClick = async (showAddressVariant = false) => {
        if (documentStatus === UNSIGNED) {
            trackMobileCustomEventsAU(AU_MOBILE_EVENTS.MY_TASKS_SUMMARY, {
                eventAction: "Contract_started",
                eventLabel: "{{Nudge_click}}"
            });
        }
        if (showAddressVariant) {
            const { relativeURL } = getMyBookingCarDetailsURL(appointmentId, postPaymentRoutes.signContract.route, null, EXPERIMENT_TYPE.VARIANT_B);
            history.replace(relativeURL);
        } else if (customSignClick) {
            customSignClick();
        } else {
            const orderPayload = getOrderPayload(order);
            await  updateDeliveryContractConnect(orderPayload, orderId, secureToken);
            const { relativeURL } = getMyBookingCarDetailsURL(content.appointmentId, tasksRoutes.signContract.route);
            history.push(relativeURL);
        }
    };
    useEffect(() => {
        // setBusinessData(false);
        if (orderId && userPurpose) {
            setSelectedKey(userPurpose.purpose);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userPurpose && userPurpose.purpose, orderId]);

    const abnValidator = (num) => {
        const inputNumber = num || "";
        const MOBILE_REGEX_AU = new RegExp(/^\d{11}$/);
        return MOBILE_REGEX_AU.test(parseInt(inputNumber));
    };

    const getABNNumber = deliveryAddress && (deliveryAddress.state !== AU_STATES_CODE.VIC ? true : false);

    const isNextButtonDisabled = () => {
        if (getABNNumber && selectedKey === "Business") {
            return (
                !(userPurpose.acnNumber || (userPurpose.abnNumber && abnValidator(userPurpose.abnNumber)))
                || !userPurpose.crnNumber ||
                !userPurpose.companyName ||
                //!(userPurpose.acnNumber && userPurpose.abnNumber) ||
                !(userPurpose.registeredAddress || isRegAddAvailable)
            );
        } else if (selectedKey === "Personal") {
            return !(userPurpose.registeredAddress || isRegAddAvailable);
        }
        return (
            !userPurpose.acnNumber
        || !userPurpose.crnNumber ||
        !userPurpose.companyName ||
        //!(userPurpose.acnNumber && userPurpose.abnNumber) ||
        !(userPurpose.registeredAddress || isRegAddAvailable));

    };
    const renderSignContractStatus = () => {
        switch (documentStatus) {
        case UNSIGNED:
            return (
                <React.Fragment>
                    {showVariant === EXPERIMENT_TYPE.VARIANT_A && <span styleName={"styles.cardCta"} onClick={() => handleSignNowClick(true)}>SIGN NOW<span styleName={"styles.tooltipContainet"}>
                            Your delivery will be delayed if the contract is not signed
                    </span></span>}
                    {showVariant === EXPERIMENT_TYPE.VARIANT_B &&
                            <div styleName="styles.buttonWrapper">
                                <Button disabled={isNextButtonDisabled()} text={"SIGN CONTRACT"} onClick={() => handleSignNowClick(false)} />
                            </div>}
                </React.Fragment>

            );
        case RESUME:
            return (
                <span styleName={"styles.cardCta"} onClick={handleSignNowClick}>RESUME</span>
            );
        case SIGNED:
            return (
                <span styleName={"styles.cardCta styles.approvedGreen"}>
                    <img src={GreenCheck} alt="Approved" />
                        Signed
                </span>
            );
        default:
            return (
                <React.Fragment>
                    {showVariant === EXPERIMENT_TYPE.VARIANT_A && <span styleName={"styles.cardCta"} onClick={() => handleSignNowClick(true)}>SIGN NOW<span styleName={"styles.tooltipContainet"}>
                            Your delivery will be delayed if the contract is not signed
                    </span></span>}
                    {showVariant === EXPERIMENT_TYPE.VARIANT_B &&
                            <div styleName="styles.buttonWrapper">
                                <Button disabled={isNextButtonDisabled()} text={"SIGN CONTRACT"} onClick={() => handleSignNowClick(false)} />
                            </div>}
                </React.Fragment>
            );
        }
    };

    const handleDownloadContract = async () => {
        const contract = await dowloadContractConnect();
        window.open(contract.url || contract);
    };

    const isUnsigned = documentStatus === UNSIGNED;
    const isInvoiceAvailable = taxInvoice && documentStatus === SIGNED;

    // if (!signContractPollCompleted) {
    //     return <TaskCardShimmer />;
    // }
    const handleTabClick = (e) => {
        // setPersonal(!isPersonal);
        setSelectedKey(e);
        setUserPersonalDetailsConnect({ ...personalDetails, ["userPurpose"]: { ...userPurpose, purpose: e } });
    };
    const handleTabNumberClick = (e) => {
        setIsAcn(!isAcn);
        setSelectedACN(e);
        // setUserPersonalDetailsConnect({ ...personalDetails, ["userPurpose"]: { ...userPurpose, selectedACN: e } })
    };
    const handleChange = (e) => {
        setUserPersonalDetailsConnect({ ...personalDetails, ["userPurpose"]: { ...userPurpose, [e.target.id]: e.target.value }});
    };

    const handleRegisteredAddress = () => {
        setRegAddAvailable(!isRegAddAvailable);
        setUserPersonalDetailsConnect({ ...personalDetails, ["userPurpose"]: { ...userPurpose, registeredAddress: deliveryAddress ? deliveryAddress.formattedAddress : "" }});

    };

    return (
        <React.Fragment>
            {showVariant === EXPERIMENT_TYPE.VARIANT_A && <div styleName={`styles.taskCard ${isUnsigned ? "styles.taskCardHeight" : ""}`}>
                <h2>Sign contract{isUnsigned && <span styleName={"styles.dueNow"}>Due now</span>}</h2>
                {documentStatus !== SIGNED && <p styleName={"styles.contractText"}>Sign the contract for your car and have it delivered to your door</p>}
                {renderSignContractStatus()}
                <img styleName={isUnsigned ? "styles.cardImage" : "styles.signedCardImage"} src={SignContractImage} alt="payment due" />
                {documentStatus === SIGNED &&
                    <div styleName="styles.download">
                        <a href={"#"} onClick={handleDownloadContract}>Download Contract</a>
                        {isInvoiceAvailable && <a href={taxInvoice} download>Download tax invoice</a>}
                    </div>
                }
            </div>
            }
            {showVariant === EXPERIMENT_TYPE.VARIANT_B &&
                <div styleName={"styles.taskCard styles.taskCardVariantB "}>
                    <div styleName="styles.headerWrapp">
                        <p styleName={"styles.signInHeading"}>
                            Sign your contract
                        </p>
                        {documentStatus === UNSIGNED && <p styleName={"styles.dueNow"}>Due now</p>}
                    </div>
                    <p styleName={"styles.contractText styles.subHeading"}>We need some details for your contract </p>
                    <div styleName={"styles.tabInput"}>
                        <p styleName={"styles.purpose"}>Purpose the car will be used for?</p>
                        <div styleName={"styles.tabContainer"}>
                            {PURPOSE_TYPE.map((option) => (
                                <ListTab
                                    key={option.value}
                                    data={option}
                                    dataKey={"value"}
                                    labelKey={"label"}
                                    selectedKey={selectedKey ? selectedKey : PURPOSE_TYPE[0].value }
                                    onClick={() => handleTabClick(option.value)}
                                />
                            ))}
                        </div>
                    </div>
                    {selectedKey === "Personal" ? null : <><div styleName={"styles.inputFields"}>
                        <p styleName={"styles.purpose"}>What’s your company’s name?</p>
                        <InputText
                            id="companyName"
                            text="Full company name"
                            value={ userPurpose && (userPurpose.companyName ? userPurpose.companyName : "")}
                            onChange={handleChange}
                        />
                    </div><div styleName={"styles.inputFields"}>
                        <p styleName={"styles.purpose"}>Enter CRN number</p>
                        <InputText
                            id="crnNumber"
                            text="Enter CRN number"
                            value={ userPurpose && (userPurpose.crnNumber ? userPurpose.crnNumber : "")}
                            onChange={handleChange}
                            //errorMessage="Please enter a valid CRN number"
                            // onBlurCallback={() => trackInput("last_name")}
                            // onFocusCallback={scrollParent}
                        />
                    </div><div styleName={"styles.tabInput2"}>
                        <p styleName={"styles.purpose"}> {getABNNumber ?  "ACN/ABN number" : "ACN Number"}</p>
                        {getABNNumber ? <div styleName={"styles.tabContainer"}>
                            {PURPOSE_DETAILS_TYPE.map((option) => (
                                <ListTab
                                    key={option.value}
                                    data={option}
                                    dataKey={"value"}
                                    labelKey={"label"}
                                    selectedKey={selectedACN ? selectedACN : PURPOSE_DETAILS_TYPE[0].value }
                                    onClick={() => handleTabNumberClick(option.value)}
                                />
                            ))}
                        </div> : null}
                    </div><div styleName={"styles.tabInputcheckABN"}>
                        {selectedACN === "ABN" && getABNNumber ? <InputText
                            id={"abnNumber"}
                            text={"ABN Number"}
                            value={ userPurpose && (userPurpose.abnNumber ? userPurpose.abnNumber : "")}
                            onChange={handleChange}
                            validator={num => abnValidator(num)}
                            errorMessage="Please enter a valid ABN number"
                        /> : <InputText
                            id={"acnNumber"}
                            text={"ACN Number"}
                            value={ userPurpose && (userPurpose.acnNumber ? userPurpose.acnNumber : "")}
                            onChange={handleChange}
                        /> }
                    </div><div styleName={"styles.tabInput"}><a href="https://abr.business.gov.au/" target="_blank" styleName={"styles.checkAbnStatus"}>
                                Check ABN Details
                    </a>
                    </div></>
                    }
                     <><div styleName={"styles.inputFields"}>
                         <p styleName={"styles.purpose"}>Registered Address</p>
                         <InputText
                             id="registeredAddress"
                             value={isRegAddAvailable && deliveryAddress ? deliveryAddress.formattedAddress : (userPurpose && (userPurpose.registeredAddress ? userPurpose.registeredAddress : "")) }
                             text = "Enter registered address"
                             onChange={handleChange}
                         />
                     </div><div styleName={"styles.checkBoxContainer"}>
                         <Checkbox id="registeredAddressCheckbox" checked={isRegAddAvailable} onClickCallback={handleRegisteredAddress}/>
                         <p styleName={"styles.sameAddress"}>
                                Same as delivery address</p>
                     </div></>
                     {renderSignContractStatus()}
                </div>}
        </React.Fragment>
    );
};

SignContract.propTypes = {
    content: PropTypes.object,
    secureToken: PropTypes.string,
    personalDetails: PropTypes.object,
    signContractData: PropTypes.array,
    signContractPollCompleted: PropTypes.bool,
    showVariant: PropTypes.string,
    dowloadContractConnect: PropTypes.func,
    order: PropTypes.object,
    setUserPersonalDetailsConnect: PropTypes.func,
    updateDeliveryContractConnect: PropTypes.func,
    customSignClick: PropTypes.func
};

export default SignContract;

