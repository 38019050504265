import React, {useState} from "react";
import { EXPERIMENT_TYPE } from "../../../../constants/optimize-constants";
import styles from "./styles.css";

import SignContract from "../../sign-contract";
import ContractSaleNote from "../../contract-sale-note";

const VerifyUserLicencey = () => {
    const [currentStep, setCurrentStep] = useState("SALE_CONTRACT");

    const handleClick = () => {
        setCurrentStep("SALE_CONTRACT_NOTE");
    };

    const handleBackClick = () => {
        setCurrentStep("SALE_CONTRACT");
    };

    return (
        <div>
            {currentStep === "SALE_CONTRACT" &&
                <div styleName="styles.wrapper">
                    <SignContract showVariant={EXPERIMENT_TYPE.VARIANT_B} customSignClick={handleClick}/>
                </div>
            // <div className="col-lg-7" styleName="styles.wrapper">
            //     <p styleName={"styles.header"}>Sign contract of sale</p>
            //     <div styleName="styles.subHeader">
            //         <p>Only 1 step left to complete your order</p>
            //     </div>
            //     <hr/>
            // </div>
            }
            {currentStep === "SALE_CONTRACT_NOTE" &&
            <div styleName="styles.wrapper">
                <ContractSaleNote showVariant={EXPERIMENT_TYPE.VARIANT_B} customSignClick={handleBackClick} />
            </div>
            }
        </div>
    );
};

export default VerifyUserLicencey;
