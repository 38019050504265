/* eslint-disable max-statements */
/* eslint-disable complexity */

import React, { useEffect } from "react";
import loadable from "@loadable/component";
import PropTypes from "prop-types";
// import { useInView } from "react-intersection-observer";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import styles from "./styles.css";
import { useHistory } from "react-router";
import HomePageBannerRevamp from "../home-page-banner-revamp";
import GetPreApprovalAndZeroDepositBanner from "../get-pre-approval-and-zero-deposit-banner/component";
const PopularCategoryRevamp = loadable(() => import("../popular-category-revamp"), { ssr: true });
const HowItWorks = loadable(() => import("../how-it-works-video/components"), { ssr: true });
const UsedCarsByCategoryRevamp = loadable(() => import("../used-cars-by-category-revamp"), { ssr: true });
const NudgeCards = loadable(() => import("../nudge-cards"), { ssr: true });
const BuyUsedCarLanding = loadable(() => import("../buy-used-car-landing"), { ssr: true });
const CustomerTestimonialsRevamp = loadable(() => import("../customer-testimonials-revamp/index"), { ssr: true });
const StayInTouch = loadable(() => import("../stay-in-touch-revamp"));
// const TradeInYourCarCard =  loadable(() => import("../trade-in-your-car-card"));
const RecentlyAddedCars = loadable(() => import("../recently-added-cars"), { ssr: true });

const WhatOurCustomerSaysRevamp = loadable(() => import("../what-our-customer-says-revamp/component"));
const BuyWithConfidenceRevamp = loadable(() => import("../buy-with-confidence-revamp"));
const FilterByPriceSlider = loadable(() => import("../filter-by-price-slider"));
const FilterByBodytypeSlider = loadable(() => import("../filter-by-bodytype-slider"));
const WebToAppNudgeCardModal = loadable(() => import("../web-to-app-nudge-card-modal"), { ssr: true });
const PopularBrandsSection = loadable(() => import("../popular-brand-section"), { ssr: true });
const RWCMainLandingBanner = loadable(() => import("../rwc-main-landing-banner/component"), { ssr: true});

import ZeroDeposit from "./images/zero-deposit.png";
import Preapproval from "./images/pre-approval-new.webp";
import BoltIcon from "./images/bolt.svg";
import CarImg from "./images/car-img.webp";

import { trackMobileCustomEventsAU } from "../../../tracking";
import {
    AMPLITUDE_EVENTS_CATEGORY,
    VISUAL_CDP_EVENTS,
    FINANCE_FIRST_EXPERIMENT_AMPLITUDE_EVENTS
} from "../../../tracking/amplitude-events";
import WebToAppBanner from "../web-to-app-banner/component";

import { CAR_LIST_EVENT_NAMES, LOCAL_STORAGE_KEYS, NUMBER, PAGE_SOURCE, WEBSITE_SECTIONS } from "../../../constants/app-constants";
import { CMS_SLUG_CONSTANTS, EXPERIMENT_TYPE } from "../../../constants/optimize-constants";
import { AU_MOBILE_EVENTS } from "../../../tracking/au.mobile-events";
import TrackImpression from "../../shared/track-impression";
import RecentlyViewedAndPicks from "../recently-viewed-and-picks";
import getCookie from "../../../utils/helpers/get-cookie";
import { AU_RECENTLY_VIEWD_KEY } from "../../../constants/app-constants";
import { capitalize } from "lodash";

import SellOrTradeInBannerRevamp from "../sell-or-trade-in-banner-revamp";
import { yieldToMain } from "../../../utils/helpers/yield-to-main";
import { GA_EVENTS, HOME_PAGE_SELL_BANNER } from "./tracking";
import DiscoverYourDreamCar from "../discover-your-dream-car";
import CarLoanEligibiltyCard from "../car-loan-eligibility-card/component";
import { AMPLITUDE_EVENT_NAMES, GA_EVENT_NAMES } from "../../shared/discover-dream-car-forms/tracking";
import { PRE_BI_ASSISTANCE_CONFIG, PRE_BI_ASSISTANCE_FLOW_DROPPED_DELAY, PRE_BI_ASSISTANCE_NUDGE_FIRE_DELAY } from "../../../config/au.mobile/pre-bi-assistance-config";
import usePreBiAssistance from "../../../hooks/use-pre-bi-assistance";
import { useFireNudge } from "../../../hooks/use-fire-nudge";
import { getItem, setItem } from "../../../utils/helpers/storage-crud";
import useOnloadIdle from "../../../hooks/use-onload-idle";

const DOWNLOAD_APP_HOMEPAGE_BANNER = {
    "VARIANT_A": "https://cars24aus.app.link/Axf2e7nVbDb",
    "VARIANT_B": "https://cars24aus.app.link/Axf2e7nVbDb",
    "VARIANT_C": "https://cars24aus.app.link/Axf2e7nVbDb"
};
const PREAPPROVAL_CARD = {
    zeroDeposit: {
        heading: "$0 deposit loans",
        subText: "Pre-approval in 2 mins",
        bannerImage: ZeroDeposit,
        linkText: "Get pre-approval"
    },
    preapproval: {
        heading: "Get pre-approval",
        subText: "for car loan in 2 mins",
        bannerImage: Preapproval,
        linkText: "Apply now"
    },
    financeFirstExperiment: {
        heading: (
            <React.Fragment>
                Car loan eligibility <br />
                in <span>2 mins</span>
            </React.Fragment>
        ),
        bannerImage: Preapproval,
        linkText: "Get Pre-approval >>"
    },
    preApproved: {
        heading: (
            <p styleName={"styles.preApprovedStyle"}>
                You have been <br />
                <span>pre-approved</span> <br />
                for finance
            </p>
        ),
        bannerImage: Preapproval
    },
    stEligble: {
        heading: (
            <p styleName={"styles.preApprovedStyle"}>
                You have been <br />
                <span>pre-approved</span> <br />
                for finance
            </p>
        ),
        bannerImage: CarImg,
        addInfo: (
            <div styleName={"styles.stBranding"}>
                <img src={BoltIcon} alt="boltIcon" />
                <p>
                    <span>12X faster</span> loans
                </p>
            </div>
        )
    }
};

// eslint-disable-next-line max-statements
const Home = ({
    isUserZeroDpVariant,
    searchPageResult,
    preApproved,
    config = {},
    fetchHomeCarListConnect,
    isSSR,
    buyWithConfidence,
    stEligibleData,
    setPreApprovedOriginConnect,
    updateSupportWidgetConfigConnect,
    updateNudgeCountsConnect,
    updateNotificationCountsConnect,
    categoryNudgeCounts,
    setNudgeTimerIdConnect,
    nudgeCountSynced,
    cmsConfig,
    fetchCmsConfigConnect
}) => {
    const history = useHistory();
    const { recentlyViewed = {}, recentlyAdded = {} } = searchPageResult || {};
    // finance-first-experiment variant scaled to B
    const showFinanceFirstExperimentVariant = true;
    const recentlyViewedResult = recentlyViewed.result || [];
    const webToAppVariant = "VARIANT_B";
    const recentlyAddedResult = recentlyAdded.result || [];
    const showWebTrustVariant = true;
    const { isStEligible: stEligible } = stEligibleData;
    const isStEligible = stEligible && preApproved;
    const firstRecentlyViewedCar = (recentlyViewedResult || []).length && recentlyViewedResult[0];
    const {make, model} = firstRecentlyViewedCar || {};
    const {isFirstUserSession} = usePreBiAssistance();
    const isIdleLoaded = useOnloadIdle();
    const {fireNudge} = useFireNudge({
        updateSupportWidgetConfig: updateSupportWidgetConfigConnect,
        updateNudgeCounts: updateNudgeCountsConnect,
        updateNotificationCounts: updateNotificationCountsConnect,
        setNudgeTimerId: setNudgeTimerIdConnect,
        make,
        model
    });
    const handleApprovalCardClick = async () => {
        await yieldToMain();
        trackMobileCustomEventsAU(AU_MOBILE_EVENTS.FF_VOLUME_EXPERIMENT, {
            eventAction: "Finance_card_clicked",
            eventLabel: "Get_pre_approval_Home_page"
        });

        history.push("/financing/get-pre-approval");
    };
    let appointmentIds = JSON.stringify([]);
    if (!isSSR) {
        appointmentIds = getCookie(AU_RECENTLY_VIEWD_KEY);
    }
    useEffect(() => {
        if (!isSSR && appointmentIds && JSON.parse(appointmentIds).length > 0) {
            fetchHomeCarListConnect();
        }
        if (!isSSR) {
            fetchCmsConfigConnect({slug: CMS_SLUG_CONSTANTS.HOME_MSITE});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSSR]);

    useEffect(() => {
        trackMobileCustomEventsAU(GA_EVENTS.webPriceConfidence, {
            eventLabel: EXPERIMENT_TYPE.VARIANT_B,
            eventCategory: "Cars24_au_all_pages",
            eventAction: GA_EVENTS.webPriceConfidence,
            event: "custom_event"
        });
    }, []);

    const showTestDriveLocationVariant = true;
    // eslint-disable-next-line no-nested-ternary
    const preApprovalVariantProps = showFinanceFirstExperimentVariant
        ? preApproved
            ? PREAPPROVAL_CARD.preApproved
            : PREAPPROVAL_CARD.financeFirstExperiment
        : preApproved
            ? PREAPPROVAL_CARD.preApproved
            : PREAPPROVAL_CARD.preapproval;
    // eslint-disable-next-line no-nested-ternary
    const bannerProps = isStEligible
        ? PREAPPROVAL_CARD.stEligble
        : isUserZeroDpVariant
            ? PREAPPROVAL_CARD.zeroDeposit
            : preApprovalVariantProps;

    const handleNudgeFire = (nudgeConfig) => {
        if (!categoryNudgeCounts[nudgeConfig.category]) {
            fireNudge(nudgeConfig);
        }
    };
    const handleSectionImpression = () => {
        if (isFirstUserSession) {
            handleNudgeFire(PRE_BI_ASSISTANCE_CONFIG.newUser.HOMEPAGE.HOME_PAST_SECOND_FOLD);
        }
    };
    useEffect(() => {
        let timeoutId;

        const handleNudgeWithDelay = (currentNudgeConfig, delay) => {
            if (!categoryNudgeCounts[currentNudgeConfig.category]) {
                timeoutId = window.setTimeout(() => {
                    handleNudgeFire(currentNudgeConfig);
                }, delay || PRE_BI_ASSISTANCE_NUDGE_FIRE_DELAY);
            }
        };
        const sectionUsedTimestamps = getItem(LOCAL_STORAGE_KEYS.sectionUsedTimestamp) || {};
        if (isFirstUserSession && nudgeCountSynced && sectionUsedTimestamps[WEBSITE_SECTIONS.FINANCE_FIRST_FLOW]) {
            handleNudgeWithDelay(PRE_BI_ASSISTANCE_CONFIG.newUser.HOMEPAGE.HOME_LAND_FROM_PRE_APPROVAL_FLOW, PRE_BI_ASSISTANCE_FLOW_DROPPED_DELAY);
        } else if (!isFirstUserSession && nudgeCountSynced) {
            const hasRecentlyViewed = (recentlyViewedResult || []).length > 0;
            const isSectionUsedPreviously = sectionUsedTimestamps[WEBSITE_SECTIONS.FINANCE_FIRST_DISCOVERY] || sectionUsedTimestamps[WEBSITE_SECTIONS.FINANCE_FIRST_FLOW];
            if (isSectionUsedPreviously) {
                handleNudgeWithDelay(PRE_BI_ASSISTANCE_CONFIG.repeatUser.HOMEPAGE.HOME_PRE_APPROVED_USED_PREVIOUSLY);
                window.setTimeout(() => {
                    delete sectionUsedTimestamps[WEBSITE_SECTIONS.FINANCE_FIRST_DISCOVERY];
                    delete sectionUsedTimestamps[WEBSITE_SECTIONS.FINANCE_FIRST_FLOW];
                    setItem(sectionUsedTimestamps, LOCAL_STORAGE_KEYS.sectionUsedTimestamp);
                }, NUMBER.TEN_THOUSAND);
            } else if (!hasRecentlyViewed) {
                handleNudgeWithDelay(PRE_BI_ASSISTANCE_CONFIG.repeatUser.HOMEPAGE.HOME_SECOND_SESSION_NO_RV_CARS);
            } else if (hasRecentlyViewed) {
                handleNudgeWithDelay(PRE_BI_ASSISTANCE_CONFIG.repeatUser.HOMEPAGE.HOME_SECOND_SESSION_WITH_RV_CARS);
            }
        }

        // Cleanup function
        return () => {
            if (timeoutId) {
                window.clearTimeout(timeoutId);
            }
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nudgeCountSynced, (recentlyViewedResult || []).length]);
    return (
        <React.Fragment>
            <div styleName={"styles.wrapper"}>
                <div styleName={"styles.firstSection"}>
                    <HomePageBannerRevamp />
                </div>
                {((appointmentIds && JSON.parse(appointmentIds).length > 0) || recentlyViewedResult.length > 0) && (
                    <div styleName={"styles.recentlyViwedContainer"}>
                        <RecentlyViewedAndPicks />
                    </div>
                )}
                {!preApproved ? (
                    <div>
                        <TrackImpression event={{
                            gaName: GA_EVENT_NAMES.entry_point_seen,
                            data: {
                                eventLabel: PAGE_SOURCE.HOME_PAGE,
                                eventAction: GA_EVENT_NAMES.entry_point_seen
                            },
                            amplitudeName: AMPLITUDE_EVENT_NAMES.FFD_SECTION_SEEN,
                            properties: { "source": PAGE_SOURCE.HOME_PAGE}
                        }}>
                            <div styleName={"styles.discoverWrapper"}>
                                <DiscoverYourDreamCar />
                            </div>
                        </TrackImpression>

                        <TrackImpression event={{
                            gaName: GA_EVENT_NAMES.Card_viewed,
                            data: {
                                eventLabel: "FFD_pre_approval_HP",
                                eventAction: "Card_viewed",
                                event: "custom_event"
                            },
                            amplitudeName: AMPLITUDE_EVENT_NAMES.CARD_VIEWED,
                            properties: { "Card_viewed": "FFD_pre_approval_HP"}
                        }}>
                            <div styleName={"styles.financeBanner"}>
                                <CarLoanEligibiltyCard
                                    setPreApprovedOriginConnect={setPreApprovedOriginConnect}
                                    source={PAGE_SOURCE.HOME_PAGE}
                                />
                            </div>
                        </TrackImpression>

                    </div>
                ) : <div styleName={"styles.secondSection"}>
                    <TrackImpression
                        event={{
                            gaName: AU_MOBILE_EVENTS.FF_VOLUME_EXPERIMENT,
                            data: { eventLabel: "Finance_Home_page", eventAction: "Card_viewed" },
                            amplitudeName: FINANCE_FIRST_EXPERIMENT_AMPLITUDE_EVENTS.CARD_VIEWED,
                            properties: { Card_viewed: "Finance_Home_page" }
                        }}
                    >
                        <GetPreApprovalAndZeroDepositBanner
                            {...bannerProps}
                            handleClick={handleApprovalCardClick}
                            showFinanceFirstExperimentVariant={showFinanceFirstExperimentVariant}
                            showStAnimation={isStEligible}
                            preApproved={preApproved}
                        />
                    </TrackImpression>
                </div>}

                <div styleName={"styles.popularBrandsContainer"}>
                    <LazyLoadComponent>
                        <PopularBrandsSection />
                    </LazyLoadComponent>
                </div>
                <div styleName={"styles.thirdSection"}>
                    <LazyLoadComponent>
                        <PopularCategoryRevamp />
                    </LazyLoadComponent>
                </div>
                {showWebTrustVariant && (buyWithConfidence || []).length > 0 && (
                    <TrackImpression
                        event={{
                            amplitudeName: VISUAL_CDP_EVENTS.VALUE_PROPS_SEEN,
                            properties: { Source: capitalize(PAGE_SOURCE.HOME_PAGE) }
                        }}
                    >
                        <div styleName={"styles.buyWithConfidenceWrapper"}>
                            <LazyLoadComponent>
                                <BuyWithConfidenceRevamp
                                    source={capitalize(PAGE_SOURCE.HOME_PAGE)}
                                    showHeader={true}
                                    config={config}
                                    buyWithConfidence={buyWithConfidence || []}
                                    isStEligible={isStEligible}
                                />
                            </LazyLoadComponent>
                        </div>
                    </TrackImpression>
                )}

                <TrackImpression event={{ amplitudeName: AMPLITUDE_EVENTS_CATEGORY.MOBILE_CARS_BY_PRICE_SEEN }} handleImpressionView={() => handleSectionImpression("price")}>
                    <div styleName={"styles.filterPriceContainer"}>
                        <LazyLoadComponent>
                            <FilterByPriceSlider />
                        </LazyLoadComponent>
                    </div>
                </TrackImpression>

                {cmsConfig?.pageSections?.length > 0 && <div styleName={"styles.rwcContainer"}>
                    <LazyLoadComponent>
                        <div styleName={"styles.rwcBannerWrapper"}>
                            <RWCMainLandingBanner pageSource={PAGE_SOURCE.HOME_PAGE} cmsConfig={cmsConfig} />
                        </div>
                    </LazyLoadComponent>
                </div>}

                <TrackImpression
                    event={{
                        amplitudeName: HOME_PAGE_SELL_BANNER.HOME_SELL_TRADE_IN_BANNER_VIEWED,
                        eventProperty: "plateform type msite",
                        data: {
                            eventLabel: "sell_trade_banner",
                            eventAction: "viewed",
                            eventCategory: "Cars24_home_page",
                            event: "custom_event"
                        }
                    }}
                >
                    <div styleName={"styles.tradeInContainer"}>
                        <LazyLoadComponent>
                            <SellOrTradeInBannerRevamp screenName={CAR_LIST_EVENT_NAMES.PAGE_HOME} />
                        </LazyLoadComponent>
                    </div>
                </TrackImpression>

                <TrackImpression event={{ amplitudeName: AMPLITUDE_EVENTS_CATEGORY.MOBILE_CARS_BY_BODY_TYPE_SEEN }}>
                    <div styleName={"styles.bodyTypeContainer"}>
                        <LazyLoadComponent>
                            <FilterByBodytypeSlider />
                        </LazyLoadComponent>
                    </div>
                </TrackImpression>
                <LazyLoadComponent>
                    <div styleName={"styles.howItWorksContainer"}>
                        <HowItWorks showTestDriveLocationVariant={showTestDriveLocationVariant} />
                    </div>
                </LazyLoadComponent>
                {recentlyAddedResult.length > 0 && (
                    <div styleName={"styles.recentlyAddedContainer"}>
                        <LazyLoadComponent>
                            <RecentlyAddedCars />
                        </LazyLoadComponent>
                    </div>
                )}
                <div styleName={"styles.seventhSection"}>
                    <LazyLoadComponent>
                        <UsedCarsByCategoryRevamp />
                    </LazyLoadComponent>
                </div>
                <div styleName={"styles.seventhSection styles.webToAppContainer"}>
                    <LazyLoadComponent>
                        <WebToAppBanner webToAppUrl={DOWNLOAD_APP_HOMEPAGE_BANNER[webToAppVariant]} />
                    </LazyLoadComponent>
                </div>
                {!showWebTrustVariant && (
                    <div styleName={"styles.usedCarContainer"}>
                        <LazyLoadComponent>
                            <BuyUsedCarLanding />
                        </LazyLoadComponent>
                    </div>
                )}
                {showWebTrustVariant ? (
                    <div styleName={"styles.customerSaysContainer"}>
                        <LazyLoadComponent>
                            <WhatOurCustomerSaysRevamp />
                        </LazyLoadComponent>
                    </div>
                ) : (
                    <div styleName={"styles.testimonialContainer"}>
                        <LazyLoadComponent>
                            <CustomerTestimonialsRevamp />
                        </LazyLoadComponent>
                    </div>
                )}
                <div styleName={"styles.stayInTouchContainer"}>
                    <LazyLoadComponent>
                        <StayInTouch />
                    </LazyLoadComponent>
                </div>
                { isIdleLoaded &&
                    <React.Fragment>
                        <NudgeCards />
                        <WebToAppNudgeCardModal />
                    </React.Fragment>
                }
            </div>
        </React.Fragment>
    );
};

Home.propTypes = {
    isLoggedIn: PropTypes.bool,
    preApproved: PropTypes.bool,
    isUserZeroDpVariant: PropTypes.bool,
    searchPageResult: PropTypes.object,
    secureToken: PropTypes.string,
    closedNudgesId: PropTypes.array,
    nudgeList: PropTypes.array,
    nudgeListLoading: PropTypes.bool,
    config: PropTypes.object,
    fetchHomeCarListConnect: PropTypes.func,
    isSSR: PropTypes.bool,
    buyWithConfidence: PropTypes.array,
    stEligibleData: PropTypes.object,
    setPreApprovedOriginConnect: PropTypes.func,
    updateSupportWidgetConfigConnect: PropTypes.func,
    updateNudgeCountsConnect: PropTypes.func,
    updateNotificationCountsConnect: PropTypes.func,
    categoryNudgeCounts: PropTypes.object,
    setNudgeTimerIdConnect: PropTypes.func,
    nudgeCountSynced: PropTypes.bool,
    showFinanceCentricListing: PropTypes.bool,
    cmsConfig: PropTypes.object,
    fetchCmsConfigConnect: PropTypes.func
};

export default Home;
