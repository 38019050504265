import React from "react";
import PropTypes from "prop-types";
// import FormLayoutV1 from "../form-layout-v1";
import styles from "./styles.css";
import DynamicForm from "../../dynamic-form";
import FormLayoutV1 from "../../form-layout-v1";
import InputText from "../../../shared/input-text";
import Search from "../../../shared/search";
import { verifyDrivingLicenceConfig } from "../../../../config/au.mobile/post-bc-configs/verify-licence-form-config";
import FormFooter from "../../form-footer";
import { VERIFY_LICENCE_SCREEN_TYPES } from "../../../../constants/app-constants";
import VerifyLicenceUploadFileContainer from "../../verify-licence-upload-file-container";

const getFormConfig = (formConfig, formData) => {
    const config = formConfig && formConfig({
        components: {
            inputText: InputText,
            uploadFile: VerifyLicenceUploadFileContainer,
            select: Search
        },
        formData
    });
    return config;
};

const VerifyDrivingLicenceForm = ({
    // getLicenceUploadStatusByOrderIdConnect,
    saveDrivingLicenceDetailsConnect,
    setLicenceUploadScreenTypeConnect
}) => {
    const formData = {};
    const config = getFormConfig(verifyDrivingLicenceConfig, {});
    const progressSteps = config.stages;
    const handleNext = async ({ values }) => {
        const { drivingLicenseDetails } = values;
        const { licenceState: state, licenceType: type, licenceNumber: number } = drivingLicenseDetails;
        const payload = {
            state,
            type,
            number
        };
        await saveDrivingLicenceDetailsConnect(payload);
        setLicenceUploadScreenTypeConnect(VERIFY_LICENCE_SCREEN_TYPES.UPLOAD_CONFIRMATION);
    };

    const handleBack = () => {
        setLicenceUploadScreenTypeConnect(VERIFY_LICENCE_SCREEN_TYPES.INTRO);
    };
    return (
        <div styleName={"styles.outer"}>
            <p styleName={"styles.heading"}>Driver's licence</p>
            <p styleName={"styles.subHeading"}>Only select yes if your business has an active ABN </p>
            <FormLayoutV1
                outerContainer={"container"}
                formConfig={config}
                handleOnNext={handleNext}
                handleOnBack={handleBack}
                formData={formData}
                // updateFormData={updateFormData}
                // isNextLoading={isPostLoanApplicationLoading || isPatchLoanApplicationLoading}
                // screenType={screenType}
                steps={progressSteps}
                middleComponent={DynamicForm}
                middleComponentProps={
                    {
                        middleComponentStyles: styles.middleComponentStyles
                    }
                }
            // {...documentUploadConfigData}
            >
                {({onClickBack, onClickNext, isNextLoading, currentFormData}) => {
                    return (

                        <FormFooter
                            onClickBack={onClickBack}
                            onClickNext={onClickNext}
                            isNextLoading={isNextLoading}
                            currentFormData={currentFormData}
                        />
                    );
                }}
            </FormLayoutV1>
        </div>);
};

VerifyDrivingLicenceForm.propTypes = {
    setScreenType: PropTypes.func,
    getLicenceUploadStatusByOrderIdConnect: PropTypes.func,
    saveDrivingLicenceDetailsConnect: PropTypes.func,
    setLicenceUploadScreenTypeConnect: PropTypes.func
};

export default VerifyDrivingLicenceForm;
