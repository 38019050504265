import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {registerVerificationStatus, setLicenceUploadScreenType} from "../checkout-info/actions";
import GreenIdCard from "./component";

const mapStateToProps = ({
    checkout: {
        verificationData,
        verifyIdCompleted,
        verificationDataError,
        order: {
            appointmentId = "",
            drivingLicenceStatus
        }

    }
}) => ({
    verificationData,
    verifyIdCompleted,
    verificationDataError,
    appointmentId,
    drivingLicenceStatus
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    registerVerificationStatusConnect: registerVerificationStatus,
    setLicenceUploadScreenTypeConnect: setLicenceUploadScreenType
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(GreenIdCard);

