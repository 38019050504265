import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import DiscoverYourDreamCarSharedForms from "./component";
import { fetchListingCount, updateCustomBudgetDropdownOption, updateFilterOptionData, updateSelectedSuggestions } from "../../au.mobile/filters/actions";
import { reloadCarList, updateFinanceCentricListing } from "../../au.mobile/car-listing/actions";
import { updateFinanceCentricAnimation } from "../../au.mobile/home/actions";

const mapStateToProps = ({
    filters: {
        allFilters,
        config,
        selectedFilters,
        appliedFilters,
        listingCount,
        isListingCountLoading,
        isCustomBudgetSelected,
        urlOptions,
        totalListedCar
    },
    carListing: {
        metaContent
    },
    cities: {
        selectedCity: {
            code: selectedCityCode,
            name: selectedCityName
        } = {}
    }
}) => ({
    allFilters,
    config,
    selectedCityCode,
    selectedCityName,
    selectedFilters,
    metaContent,
    appliedFilters,
    listingCount,
    isListingCountLoading,
    isCustomBudgetSelected,
    urlOptions,
    totalListedCar
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchListingCountConnect: fetchListingCount,
    updateFilterOptionDataConnect: updateFilterOptionData,
    reloadCarListConnect: reloadCarList,
    updateSelectedSuggestionsConnect: updateSelectedSuggestions,
    updateFinanceCentricAnimationConnect: updateFinanceCentricAnimation,
    updateCustomBudgetDropdownOptionConnect: updateCustomBudgetDropdownOption,
    updateFinanceCentricListingConnect: updateFinanceCentricListing
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DiscoverYourDreamCarSharedForms);
